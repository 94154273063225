import { css, styled } from 'styled-components'

import { MarketStat } from '@packages/interfaces'

import { SparklineChart } from 'src/shared/components'

import { getPriceColor } from '../utils'

interface Props {
  marketStat: MarketStat
  height?: number
  className?: string
}

export function RunePriceSparklineChart({ marketStat, height, className }: Props) {
  const intervalInfo = marketStat.oneDay
  const percentPriceChange = intervalInfo.percentPriceChange

  const color = getPriceColor(percentPriceChange)

  return (
    <SparklineChartWrapper
      dataPoints={intervalInfo.sparklineData}
      height={height}
      className={className}
      color={color}
    />
  )
}

const SparklineChartWrapper = styled(SparklineChart)<{ color: string }>`
  svg {
    path {
      ${(props) => css`
        stroke: ${props.color};
      `}
    }
  }
`
