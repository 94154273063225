import { styled } from 'styled-components'

import { DrawerCard } from 'src/shared/components'
import {
  API_URL,
  DEPLOYED_BITCOIN_NETWORK,
  DISABLE_ETCHING,
  DISABLE_WRITES,
  ENABLE_EXTRA_WALLETS,
  ENVIRONMENT,
  GIT_COMMIT_SHA,
  SERVICE_FEE_ADDRESS,
  SERVICE_FEE_BPS,
  SERVICE_FEE_MIN_SATS,
} from 'src/settings'

import { PageContainer } from './PageContainer'

export function SettingsPage() {
  return (
    <PageContainer>
      <Container>
        <DrawerCardWrapper>
          <Row>
            <Column>Environment</Column>
            <Column>{ENVIRONMENT}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Git Commit</Column>
            <Column>{GIT_COMMIT_SHA}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>API URL</Column>
            <Column>{API_URL}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Deployed Network</Column>
            <Column>{DEPLOYED_BITCOIN_NETWORK}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Disable Etching</Column>
            <Column>{DISABLE_ETCHING ? 'true' : 'false'}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Disable Writes</Column>
            <Column>{DISABLE_WRITES ? 'true' : 'false'}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Enable Extra Wallets</Column>
            <Column>{ENABLE_EXTRA_WALLETS ? 'true' : 'false'}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Service Fee Address</Column>
            <Column>{SERVICE_FEE_ADDRESS}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Service Fee Amount Mint Bps</Column>
            <Column>{SERVICE_FEE_BPS.mint}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Service Fee Amount Mint Minimum</Column>
            <Column>{SERVICE_FEE_MIN_SATS.mint.toString()}</Column>
          </Row>
        </DrawerCardWrapper>
      </Container>
    </PageContainer>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const DrawerCardWrapper = styled(DrawerCard)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Row = styled.div`
  display: flex;
`

const Column = styled.div`
  flex: 1;
  text-align: center;
`
