import { useWallet as _useWallet, useWallets } from '@wallet-standard/react'
import { useEffect, useState } from 'react'
import { BitcoinProvider } from '@sats-connect/core'

import { getSatsProvider, isSatsConnectCompatibleWallet } from '../utils'

// This will find the available provider that implements the WalletStandard that matches the passed in walletName else it returns undefined
// Wallet standard wallets: Magic Eden
export function useWalletStandardProvider(walletName: string | undefined) {
  const [wallet, setWallet] = useState<BitcoinProvider>()
  const { wallets } = useWallets()

  const { setWallet: setWalletStandardWallet } = _useWallet()

  useEffect(() => {
    if (walletName) {
      const matchingWallet = wallets
        .filter(isSatsConnectCompatibleWallet)
        .find((wallet) => (wallet.name as any) === walletName)

      if (matchingWallet) {
        setWalletStandardWallet(matchingWallet)
        setWallet(getSatsProvider(matchingWallet))
      }
    }
  }, [walletName, wallets, setWalletStandardWallet])

  return wallet
}
