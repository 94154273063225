import { css, styled } from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

import { COLORS, FONTS } from '../../constants'
import { hexToRgb } from '../../utils'

interface Props {
  onClick?: () => void
  isSubmitButton?: boolean
  disabled?: boolean
  loading?: boolean
  children?: React.ReactNode
  className?: string
}

export function Button({
  onClick,
  disabled = false,
  loading = false,
  isSubmitButton,
  children,
  className,
}: Props) {
  return (
    <Container
      onClick={onClick}
      className={className}
      disabled={disabled}
      $loading={loading}
      type={isSubmitButton ? 'submit' : 'button'}
    >
      {loading && <LoadingIcon />}
      {children}
    </Container>
  )
}

const Container = styled.button<{ $loading: boolean }>`
  font-family: ${FONTS.text};
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  padding: 5px 15px;
  gap: 5px;
  text-transform: uppercase;

  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.7)};
  }

  ${(props) =>
    props.$loading &&
    css`
      pointer-events: none;
      cursor: none;
      color: ${hexToRgb(COLORS.white, 0.7)};
      background-color: ${COLORS.background.container};
    `}
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: none;
      color: ${hexToRgb(COLORS.white, 0.7)};
    `}
`

const LoadingIcon = styled(CircularProgress)`
  color: ${COLORS.white};
  width: 20px !important;
  height: 20px !important;
`
