/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddressPurpose, BitcoinProvider, Params, Requests, RpcResponse } from '@sats-connect/core'

import { hexToBase64 } from 'src/shared/utils'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { CustomBitcoinProvider } from './CustomBitcoinProvider'
import {
  decodeSendBitcoinRequest,
  decodeSignMessageRequest,
  decodeSignTransactionRequest,
} from '../utils'

interface ConnectResponse {
  address: string
  publicKey: string
}

export class OkxBitcoinProvider implements BitcoinProvider, CustomBitcoinProvider {
  private okx: any

  public isAvailable() {
    this.setLib()

    return !!this.okx
  }

  public setLib() {
    if (!this.okx && typeof (window as any).okxwallet !== 'undefined') {
      if (IS_TESTNET_DEPLOYMENT) {
        this.okx = (window as any).okxwallet?.bitcoinTestnet
      } else {
        this.okx = (window as any).okxwallet?.bitcoin
      }
    }
  }

  public async connect(_request: string) {
    const response: ConnectResponse = await this.okx.connect()

    const formattedAddresses = []
    // okx uses the same address for ordinals and payments
    formattedAddresses.push({
      address: response.address,
      publicKey: response.publicKey,
      purpose: AddressPurpose.Payment,
    })
    formattedAddresses.push({
      address: response.address,
      publicKey: response.publicKey,
      purpose: AddressPurpose.Ordinals,
    })

    return { addresses: formattedAddresses }
  }

  public async disconnect() {}

  public async signMessage(request: string) {
    return await this.okx.signMessage(decodeSignMessageRequest(request), 'ecdsa')
  }

  // https://www.okx.com/web3/build/docs/sdks/chains/bitcoin/provider#signpsbt
  public async signTransaction(request: string) {
    const { psbtHex, broadcast } = decodeSignTransactionRequest(request)
    const resultHex = await this.okx.signPsbt(psbtHex, {
      autoFinalized: broadcast,
    })
    let transactionHash
    if (broadcast && !IS_TESTNET_DEPLOYMENT) {
      // broadcasting doesn't work for OKX on testnet - getting the error `sequence too long`
      // i think this is due to okx not updating their keys for testnet in the wallet - only offline signing works
      transactionHash = await this.okx.pushPsbt(resultHex)
    }

    return {
      psbtBase64: hexToBase64(resultHex),
      txId: transactionHash,
    }
  }

  public async sendBtcTransaction(request: string) {
    const { amount, address } = decodeSendBitcoinRequest(request)
    const transactionHash = this.okx.sendBitcoin(address, amount)
    return transactionHash
  }

  public async call(_request: string) {
    throw new Error('Not implemented')
    return {}
  }

  public async createInscription(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async createRepeatInscriptions(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async signMultipleTransactions(_request: string) {
    throw new Error('Not implemented')
    return []
  }

  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined,
  ): Promise<RpcResponse<Method>> {
    return this.okx.request(method, options, providerId)
  }
}
