import { TextField } from '@mui/material'
import { styled } from 'styled-components'

import { hexToRgb } from 'src/shared/utils'

import { COLORS, FONTS } from '../../constants'

interface Props {
  value: number
  onChange: (val: number) => void
  onBlur?: () => void
  disabled?: boolean
  error?: boolean
  className?: string
  helperText?: string
}

export function NumberInput({
  value,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  helperText,
  className,
}: Props) {
  function handleChange(event: any) {
    const strValue = event.target.value
    if (isNaN(Number(strValue))) return
    onChange(Number(strValue))
  }

  return (
    <TextFieldWrapper
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      error={error}
      helperText={helperText}
      className={className}
    />
  )
}

const TextFieldWrapper = styled(TextField)`
  p {
    line-height: 15px;
    padding: 10px 0px;
    margin: 0px;
  }

  border: none;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.white};
    border-radius: 8px;

    &:hover {
      border-color: ${hexToRgb(COLORS.white, 0.5)};
    }
  }

  .MuiInputLabel-root {
    border-radius: 8px;
    color: ${COLORS.black};
  }

  .MuiInputBase-input {
    border-radius: 8px;
    color: ${COLORS.black};
    background-color: ${COLORS.white};
    text-align: center;
    height: 11px;
    width: 45px;
  }

  .MuiFormHelperText-root {
    font-family: ${FONTS.text};
    font-size: 15px;
  }
`
