import { BitcoinNetworkType } from '@sats-connect/core'

console.log('import.meta.env', import.meta.env)

export const ENVIRONMENT = import.meta.env.VITE_NODE_ENV as string
export const GIT_COMMIT_SHA = import.meta.env.VITE_GIT_SHA as string

export const IS_TESTNET_DEPLOYMENT = import.meta.env.VITE_IS_TESTNET_DEPLOYMENT === 'true'
export const DEPLOYED_BITCOIN_NETWORK: BitcoinNetworkType = IS_TESTNET_DEPLOYMENT
  ? BitcoinNetworkType.Testnet
  : BitcoinNetworkType.Mainnet
export const SATOSHI_DUST_THRESHOLD = 546n

export const SERVICE_FEE_ADDRESS = import.meta.env.VITE_SERVICE_FEE_ADDRESS as string
export const SERVICE_FEE_BPS = {
  mint: import.meta.env.VITE_SERVICE_FEE_BPS_MINT
    ? parseFloat(import.meta.env.VITE_SERVICE_FEE_BPS_MINT)
    : 0,
}
export const SERVICE_FEE_MIN_SATS = {
  mint: import.meta.env.VITE_SERVICE_FEE_MIN_SATS_MINT
    ? BigInt(import.meta.env.VITE_SERVICE_FEE_MIN_SATS_MINT)
    : 546n,
}

export const DISABLE_ETCHING = import.meta.env.VITE_DISABLE_ETCHING === 'true'
export const DISABLE_WRITES = import.meta.env.VITE_DISABLE_WRITES === 'true'
export const ENABLE_EXTRA_WALLETS = import.meta.env.VITE_ENABLE_EXTRA_WALLETS === 'true'

export const BANNER_MESSAGE = import.meta.env.VITE_BANNER_MESSAGE as string

export const API_URL = import.meta.env.VITE_API_URL as string

const requiredEnvVars = [
  API_URL,
  SERVICE_FEE_BPS.mint,
  GIT_COMMIT_SHA,
  SERVICE_FEE_ADDRESS,
  ENVIRONMENT,
]

requiredEnvVars.forEach((env, i) => {
  if (!env) {
    console.log(i, env)
    throw new Error('Required env variable not set')
  }
})

export const LINKS = {
  twitter: 'https://twitter.com/lfg',
  telegram: 'https://t.me/LFGBRC20',
  discord: 'https://discord.com/invite/lfgmystic',
  // github: '',
}

// TODO: fill out
export const TRX_VIRTUAL_SIZE_BYTES = {
  mint: 150,
  etch: 196,
  buy: 196,
  transfer: 196,
}

export const SENTRY = {
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
    ? Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
    : 0.0001,
  replaysSessionSampleRate: import.meta.env.VITE_REPLAYS_SESSIONS_SAMPLE_RATE
    ? Number(import.meta.env.VITE_REPLAYS_SESSIONS_SAMPLE_RATE)
    : 0.0001,
  replaysOnErrorSampleRate: import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE
    ? Number(import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE)
    : 1,
}

console.debug('Sentry', SENTRY)
