import { Decimal } from 'decimal.js-light'
import { formatUnits, parseUnits } from 'ethers'

import { BTC_DECIMALS, NUM_SATS_IN_BTC } from '@packages/constants'

Decimal.set({ toExpNeg: -12, toExpPos: 12 })

export function btcToSats(btcAmount: bigint | string | number) {
  const btc = new Decimal(`${btcAmount}`)
  return BigInt(btc.mul(NUM_SATS_IN_BTC).toString())
}

export function satsToBtc(satsAmount: bigint | string | number) {
  const sats = new Decimal(`${satsAmount}`)
  return sats.div(NUM_SATS_IN_BTC).toNumber()
}

export function floatBtcToBigInt(btcAmount: number) {
  return BigInt(parseUnits(btcAmount.toString(), BTC_DECIMALS))
}

export function formatBtc(btc: bigint) {
  return formatUnits(btc, BTC_DECIMALS)
}

const minimumBtc = '0.0001'

const SMALLEST_BTC_SHOWN = new Decimal(minimumBtc)

const SHORT_DECIMALS = 4

export function shortFormatBtc(btcNum: number | string) {
  const btc = new Decimal(btcNum)
  if (btc > SMALLEST_BTC_SHOWN) {
    return btc.toFixed(SHORT_DECIMALS)
  } else {
    return `<${minimumBtc}`
  }
}
