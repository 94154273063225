import { styled } from 'styled-components'
import { useState } from 'react'

import { RuneDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { HeaderText, LargeButtonGroup, LoadingSkeleton } from 'src/shared/components'
import { replaceUrlParams } from 'src/shared/utils'
import { RuneNameHeader } from 'src/runes'
import { OrderHistoryTable, OpenSellOrdersTable, RuneSwapper } from 'src/orders'
import { useApi } from 'src/api'

const RUNE_TABS = {
  openOrders: 'Open Orders',
  orderHistory: 'Order History',
}

const tabOptions = Object.values(RUNE_TABS).map((value) => ({
  value: value,
  label: value,
}))

interface Props {
  runeName: string
}

export function MarketDetailsPageContent({ runeName }: Props) {
  const { data: runeDetails } = useApi<RuneDetails>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.details, {
      runeName: runeName.toUpperCase(),
    }),
  })
  const [selectedTab, setSelectedTab] = useState(RUNE_TABS.openOrders)

  function handleTabOnChange(value: string) {
    setSelectedTab(value)
  }

  if (!runeDetails) {
    return (
      <Container>
        <RuneName>{runeName}</RuneName>
        <LargeButtonGroupWrapper
          options={tabOptions}
          value={selectedTab}
          onChange={handleTabOnChange}
          exclusive
          updateSearchParams
        />
        <TabbedContentContainer>
          <LoadingSkeletonWrapper height={600} />
        </TabbedContentContainer>
      </Container>
    )
  }

  let tabbedContent = <OpenSellOrdersTable runeDetails={runeDetails} />
  if (selectedTab === RUNE_TABS.orderHistory) {
    tabbedContent = <OrderHistoryTable runeDetails={runeDetails} />
  }

  return (
    <Container>
      <RuneNameHeaderWrapper runeDetails={runeDetails} />
      <RuneSwapper runeDetails={runeDetails} />
      <LargeButtonGroupWrapper
        options={tabOptions}
        value={selectedTab}
        onChange={handleTabOnChange}
        exclusive
        updateSearchParams
      />
      <TabbedContentContainer>{tabbedContent}</TabbedContentContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TabbedContentContainer = styled.div`
  padding-top: 50px;
`

const RuneName = styled(HeaderText)`
  padding-bottom: 40px;
`

const RuneNameHeaderWrapper = styled(RuneNameHeader)`
  padding-bottom: 40px;
`

const LargeButtonGroupWrapper = styled(LargeButtonGroup)``

const LoadingSkeletonWrapper = styled(LoadingSkeleton)`
  transform: none;
`
