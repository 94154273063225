import { styled } from 'styled-components'
import { useMemo, useState } from 'react'
import Decimal from 'decimal.js-light'

import { Order, RuneBalance } from '@packages/interfaces'
import { formatUsd, satsToBtc, bigIntToDecimal } from '@packages/utils'

import { Button, Drawer, DrawerCard, ErrorMessage, BigIntInput } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { formatRuneName, formatRunesAmount } from 'src/runes'
import { useWalletContext } from 'src/wallet'

interface Props {
  isOpen: boolean
  onClose: () => void

  runeBalance: RuneBalance
  currentOrder?: Order // passed in when an order is edited
}

const BIG_INT_ZERO = BigInt(0)

export function SellRunesDrawer({ isOpen, onClose, currentOrder, runeBalance }: Props) {
  const { btcPrice } = useWalletContext()
  const [satsAmount, setSatAmount] = useState(currentOrder?.satsAmount ?? BIG_INT_ZERO)
  const [runesAmount, setRunesAmount] = useState(currentOrder?.runesAmount ?? BIG_INT_ZERO)
  const [error, setError] = useState<string | null>(null)

  async function onConfirmClick() {
    try {
      setError(null)
      console.log(currentOrder)
    } catch (error: any) {
      setError(error.message)
    }
  }

  const { btcValue, usdValue, priceSats } = useMemo(() => {
    const satsValue = satsAmount
    const btcValue = satsToBtc(satsValue)
    return {
      btcValue,
      satsValue,
      usdValue: new Decimal(btcValue).mul(btcPrice?.btcPriceUsd ?? 0).toNumber(),
      priceSats: bigIntToDecimal(satsValue).div(runesAmount.toString()).toString(),
    }
  }, [btcPrice, runesAmount, satsAmount])

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>Sell {runeBalance.runeName}</Title>
        <DrawerCardWrapper>
          <Row>
            <Column>Balance</Column>
            <Column>
              {formatRunesAmount({ rune: runeBalance, runesAmount: runeBalance.runesAmount })}{' '}
              {formatRuneName(runeBalance)}
            </Column>
          </Row>
        </DrawerCardWrapper>

        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Runes Amount</BoldColumn>
          </Row>
          <Row>
            <BigIntInput
              value={runesAmount}
              onChange={setRunesAmount}
              decimals={runeBalance.runeDecimals}
            />
          </Row>
          <Row>
            <BoldColumn>Sats Amount</BoldColumn>
          </Row>
          <Row>
            <BigIntInput value={satsAmount} onChange={setSatAmount} decimals={1} />
          </Row>
          <Row>
            <BoldColumn>Price</BoldColumn>
            <Column>
              {priceSats} SATS / {formatRuneName(runeBalance)}
            </Column>
          </Row>
          <Row>
            <BoldColumn>BTC Total</BoldColumn> <Column>{btcValue}</Column>
          </Row>
          <Row>
            <BoldColumn>USD Total</BoldColumn> <Column>{formatUsd(usdValue)}</Column>
          </Row>
        </DrawerCardWrapper>
        {error && <ErrorMessage message={error} />}
        <Buttons>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <ConfirmButton onClick={onConfirmClick}>Confirm Sell</ConfirmButton>
        </Buttons>
      </Container>
    </Drawer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Title = styled.h2`
  font-weight: 500;
`

const DrawerCardWrapper = styled(DrawerCard)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`

const CancelButton = styled(Button)`
  color: ${COLORS.buttons.primary};
  background-color: transparent;
  border: 2px solid ${COLORS.buttons.primary};
`

const ConfirmButton = styled(Button)`
  color: ${COLORS.black};
  background-color: ${COLORS.buttons.primary};
`

const Row = styled.div`
  display: flex;
`

const BoldColumn = styled.div`
  font-weight: 500;
  flex: 1;
`

const Column = styled.div`
  flex: 1;
`
