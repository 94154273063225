import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { CopyText, LargeButtonGroup, HeaderText, HeaderContainer } from 'src/shared/components'
// import { OpenOrdersByAddress } from 'src/orders'
import { formatShortAddress, useWalletContext } from 'src/wallet'
import { RuneBalancesTable } from 'src/runes'
import { EtchRevealTable, EtchedRunesTable } from 'src/etching'
import { useIsMobile } from 'src/shared/hooks'
import { BatchMintsTable } from 'src/minting'

import { AccountHistoryTable } from './AccountHistoryTable'

export const ACCOUNT_DETAILS_TABS = {
  balances: 'Balances',
  // openOrders: 'Open Orders',
  mints: 'Mints',
  commitedEtches: 'Commited Etches',
  revealedEtches: 'Revealed Etches',
  history: 'History',
}

const tabOptions = [
  { label: ACCOUNT_DETAILS_TABS.balances, value: ACCOUNT_DETAILS_TABS.balances },
  { label: ACCOUNT_DETAILS_TABS.history, value: ACCOUNT_DETAILS_TABS.history },
]

export function AccountDetailsPageContent() {
  const isMobile = useIsMobile()
  const { address } = useParams() // this should be their ordinals / runes address
  const { runesAddress, paymentAddress, btcBalances } = useWalletContext()
  const [selectedTab, setSorting] = useState(ACCOUNT_DETAILS_TABS.balances)

  function handleSortingOnChange(value: string) {
    setSorting(value)
  }

  const isConnectedAddress = runesAddress?.addrString === address

  useEffect(() => {
    if (isConnectedAddress && tabOptions.length === 2) {
      // tabOptions.push({
      //   label: ACCOUNT_DETAILS_TABS.commitedEtches,
      //   value: ACCOUNT_DETAILS_TABS.commitedEtches,
      // })
      // tabOptions.push({
      //   label: ACCOUNT_DETAILS_TABS.revealedEtches,
      //   value: ACCOUNT_DETAILS_TABS.revealedEtches,
      // })
      tabOptions.push({
        label: ACCOUNT_DETAILS_TABS.mints,
        value: ACCOUNT_DETAILS_TABS.mints,
      })
    }
  }, [runesAddress])

  let tabbedContent
  if (address) {
    if (selectedTab === ACCOUNT_DETAILS_TABS.balances) {
      tabbedContent = <RuneBalancesTable address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.commitedEtches) {
      tabbedContent = <EtchRevealTable />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.revealedEtches) {
      tabbedContent = <EtchedRunesTable />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.history) {
      tabbedContent = <AccountHistoryTable address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.mints && paymentAddress) {
      tabbedContent = <BatchMintsTable paymentAddress={paymentAddress.addrString} />
    }
    // } else if (selectedTab === ACCOUNT_DETAILS_TABS.openOrders) {
    //   tabbedContent = <OpenOrdersByAddress address={address} />
    // }
  }

  const singleAddress = paymentAddress?.addrString === address

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Account Details</HeaderText>{' '}
        {isConnectedAddress && <ConnectedLabel>Connected</ConnectedLabel>}
      </HeaderContainer>
      {!singleAddress && (
        <CopyText textToCopy={address ?? ''}>
          <>
            <AddressPurpose>Runes Address</AddressPurpose>{' '}
            {isMobile && address ? formatShortAddress(address) : address}{' '}
          </>
        </CopyText>
      )}
      {isConnectedAddress && (
        <PaymentAddress>
          <CopyText textToCopy={paymentAddress?.addrString ?? ''}>
            <AddressPurpose>
              {singleAddress ? 'Payment and Runes Address' : 'Payment Address'}
            </AddressPurpose>{' '}
            {isMobile && paymentAddress?.addrString
              ? formatShortAddress(paymentAddress?.addrString)
              : paymentAddress?.addrString}
          </CopyText>
          <BtcBalance>Balance: {btcBalances?.chainBalanceBtc} BTC</BtcBalance>
        </PaymentAddress>
      )}
      <LargeButtonGroupWrapper
        options={tabOptions}
        value={selectedTab}
        onChange={handleSortingOnChange}
        exclusive
        updateSearchParams
      />
      {tabbedContent}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const ConnectedLabel = styled.div`
  background-color: ${COLORS.positive};
  width: fit-content;
  padding: 3px 5px;
  border-radius: 5px;
  max-height: 32px;
  color: ${COLORS.white};
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AddressPurpose = styled.div`
  font-size: 15px;
`

const PaymentAddress = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`

const BtcBalance = styled.div``

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  padding-top: 40px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 10px;
  }
`
