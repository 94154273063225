import { styled } from 'styled-components'
import { Alert } from '@mui/material'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'

import { COLORS } from 'src/shared/constants'

import { ToastContainer } from './styles'

export interface ErrorToastProps {
  key: string
  variant: 'error'
  errorMessage: string
  autoHideDuration?: number
  className?: string
}

export const ErrorToast = forwardRef<HTMLDivElement, ErrorToastProps>(
  ({ key, className, errorMessage }, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(key)
    }, [key, closeSnackbar])

    return (
      <ToastWrapper ref={ref} className={className} key={key}>
        <Alert onClose={handleDismiss} icon={false} severity='error'>
          {errorMessage}
        </Alert>
      </ToastWrapper>
    )
  },
)

ErrorToast.displayName = 'ErrorToast'

const ToastWrapper = styled(ToastContainer)`
  .MuiAlert-standard {
    background-color: ${COLORS.negative};
    font-weight: 500;
  }
`
