import { styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

interface Props {
  header: string
  subHeader: string
}

export function TieredTableCell({ header, subHeader }: Props) {
  return (
    <Container>
      <Header>{header}</Header>
      <SubHeader>{subHeader}</SubHeader>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  font-weight: 700;
  font-size: 15px;
`

const SubHeader = styled.div`
  font-weight: 400;
  font-size: 14px !important;
  color: ${hexToRgb(COLORS.white, 0.7)} !important;
`
