import { styled } from 'styled-components'

import { RuneBalance, RuneDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { COLORS } from 'src/shared/constants'
import { useApi } from 'src/api'
// import { MarketDetailsLink } from 'src/markets'
import { MintingInfo } from 'src/minting'

import { formatRunesAmount } from '../utils'

interface Props {
  runeDetails: RuneDetails
  walletRunesAddress?: string
}

export function RuneDetailsBalanceCard({ runeDetails, walletRunesAddress }: Props) {
  const { data: runeBalance } = useApi<RuneBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.runeBalanceForAccount, {
      address: walletRunesAddress ?? '',
      runeId: runeDetails.runeId,
    }),
    disable: !walletRunesAddress,
  })

  return (
    <Container>
      <Balance>
        Balance
        <Amount>
          {runeBalance
            ? formatRunesAmount({ rune: runeBalance, runesAmount: runeBalance.runesAmount })
            : 0}
        </Amount>
      </Balance>
      <MintingInfo runeDetails={runeDetails} />
      {/* <MarketDetailsLink runeName={runeDetails.runeName} /> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const Balance = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${hexToRgb(COLORS.white, 0.7)};
`

const Amount = styled.div`
  color: ${COLORS.white};
`
