import { Alert } from '@mui/material'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'

import { OKX_URLS } from '@packages/utils'

import { A, ToastContainer } from 'src/shared/components'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { mempoolUrls } from '../utils'

export interface SuccessfulTransactionToastProps {
  key: string
  variant: 'successfulTransaction'
  message?: string
  transactionId: string
  autoHideDuration?: number
  className?: string
}

export const SuccessfulTransactionToast = forwardRef<
  HTMLDivElement,
  SuccessfulTransactionToastProps
>(({ key, className, transactionId, message }, ref) => {
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(key)
  }, [key, closeSnackbar])

  return (
    <ToastContainer ref={ref} className={className} key={key}>
      <Alert onClose={handleDismiss} icon={false}>
        {message ?? 'Transaction successful!'}{' '}
        <A
          href={IS_TESTNET_DEPLOYMENT ? mempoolUrls.tx(transactionId) : OKX_URLS.tx(transactionId)}
        >
          View on Explorer
        </A>
      </Alert>
    </ToastContainer>
  )
})

SuccessfulTransactionToast.displayName = 'SuccessfulTransactionToast'
