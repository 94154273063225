import { styled, css } from 'styled-components'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import Paper from '@mui/material/Paper'

import { BREAKPOINTS, COLORS, FONTS, Z_INDEX } from '../../constants'
import { hexToRgb } from '../../utils'
import { DotsLoader } from '../DotsLoader'

export const ROW_PADDING = 14
export const MOBILE_ROW_PADDING = 10
export const HEADER_HEIGHT = 100

export const TablePaper = styled(Paper)<{
  $minHeight: number
  $rowHeightWithPadding: number
}>`
  box-shadow: none;
  border-radius: 10px;
  background: transparent;
  transition: none;
  .MuiPaper-root {
    width: 100%;
    overflow-x: clip;
    min-height: ${(props) => props.$minHeight}px !important;
  }
  div {
    border-radius: 15px;
    font-size: 15px;
    box-shadow: none;
    transition: none;
    color: ${COLORS.white};
    background: transparent;
    thead {
      background: transparent;
    }
    tr {
      background: transparent;
    }
    th {
      font-family: ${FONTS.text};
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;

      color: ${hexToRgb(COLORS.white, 0.9)};
      border-bottom: 1px solid ${COLORS.border};
      padding: ${ROW_PADDING}px;
      @media (max-width: ${BREAKPOINTS.medium}) {
        padding: ${MOBILE_ROW_PADDING}px;
        font-size: 14px;
      }
    }
    td {
      color: ${COLORS.white};
      border: 0;
      line-height: 18px;
      height: ${(props) => props.$rowHeightWithPadding}px;
      padding: ${ROW_PADDING}px 15px;
      @media (max-width: ${BREAKPOINTS.medium}) {
        padding: ${MOBILE_ROW_PADDING}px;
        font-size: 13px;
        div {
          font-size: 13px;
        }
      }
    }
    tfoot {
      position: absolute !important;
      width: 100%;
      z-index: ${Z_INDEX.content};
      padding-bottom: 50px;
    }
  }

  .MuiTableSortLabel-root:hover {
    color: ${hexToRgb(COLORS.white, 0.5)};
    .MuiTableSortLabel-icon {
      color: ${hexToRgb(COLORS.white, 0.5)};
    }
  }

  .MuiTablePagination-actions {
    button {
      color: ${COLORS.white};
    }
  }

  .Mui-active {
    color: ${COLORS.buttons.accent};
    .MuiTableSortLabel-icon {
      color: ${COLORS.buttons.accent};
    }
  }
`

export const TableLabel = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: inherit;
  align-items: center;
`

export const TableCellWrapper = styled(TableCell)<{
  $hideOnMobile: boolean
  $hideOnDesktop: boolean
  width?: number
}>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}%;
    `};

  ${(props) =>
    props.$hideOnDesktop &&
    css`
      display: none;
      @media (max-width: ${BREAKPOINTS.medium}) {
        display: table-cell;
      }
    `};

  ${(props) =>
    props.$hideOnMobile &&
    css`
      @media (max-width: ${BREAKPOINTS.medium}) {
        display: none;
      }
    `};
`

export const TableFooterWrapper = styled(TableFooter)`
  text-align: center;
  padding: 15px;
`

export const NoDataCellWrapper = styled(TableCellWrapper)`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  padding: 0px 15px !important;
`

export const DotsLoaderWrapper = styled(DotsLoader)`
  div {
    background-color: ${COLORS.hover};
  }
`
