import { styled } from 'styled-components'

import { formatBigIntUsd, satsToBtc } from '@packages/utils'
import { API_ENDPOINTS } from '@packages/constants'
import { Order, OrderStatus } from '@packages/interfaces'

import { Button, PaginatedTable } from 'src/shared/components'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { RuneDetailsLink } from 'src/runes'
import { usePaginationApi } from 'src/api'
import { COLORS } from 'src/shared/constants'

interface Props {
  address: string
}

export function OpenOrdersByAddress({ address }: Props) {
  const status: OrderStatus = 'Placed'
  const {
    paginatedData: openOrders,
    loading,
    fetchPage,
    hasNextPage,
  } = usePaginationApi<Order>({
    endpoint: `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byAccount, {
      address,
    })}?status=${status}`,
  })

  // TODO: add edit + cancel modals

  return (
    <Container>
      <PaginatedTable
        columns={[
          {
            dataKey: 'id',
            label: 'ID',
          },
          {
            dataKey: 'runeName',
            label: 'Name',

            formatter: ({ data: order }) => <RuneDetailsLink rune={order} />,
          },
          {
            dataKey: 'priceUsd',
            label: 'Price',
          },
          {
            dataKey: 'valueSats',
            label: 'BTC Total Value',
            formatter: ({ data: order }) => satsToBtc(order.valueSats),
          },
          {
            dataKey: 'usdValue',
            label: 'USD Total Value',
            formatter: ({ data: order }) => formatBigIntUsd(order.valueUsd),
          },
          {
            dataKey: 'date',
            label: 'Date',
            formatter: ({ data: order }) =>
              unixTimestampToDateString(order.placedAtTimestamp, true),
          },
          {
            dataKey: 'edit',
            label: '',
            formatter: () => <EditButton>Edit</EditButton>,
          },
          {
            dataKey: 'cancel',
            label: '',
            formatter: () => <CancelButton>Cancel</CancelButton>,
          },
        ]}
        paginatedData={openOrders}
        fetchPage={fetchPage}
        loading={loading}
        hasNextPage={hasNextPage}
      />
    </Container>
  )
}

const Container = styled.div``

const EditButton = styled(Button)`
  width: 100%;
  max-width: 150px;
`
const CancelButton = styled(Button)`
  background-color: ${COLORS.negative};
`
