import TextField from '@mui/material/TextField'
import { styled } from 'styled-components'

import { COLORS, FONTS } from '../../constants'
import { applyOnlyBorderGlow } from '../../styles'

interface Props {
  label?: string
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
  defaultValue?: string
  required?: boolean
  error?: boolean
  disabled?: boolean
  helperText?: string
  className?: string
}

export function TextInput({
  label,
  value,
  onChange,
  onBlur,
  defaultValue,
  required,
  error,
  helperText,
  className,
  disabled,
}: Props) {
  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  return (
    <TextFieldWrapper
      className={className}
      variant='outlined'
      required={required}
      label={label}
      defaultValue={defaultValue}
      value={value}
      onChange={handleOnChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  )
}

const TextFieldWrapper = styled(TextField)`
  width: 100%;
  display: flex;

  .MuiInputLabel-shrink {
    text-shadow:
      -1px 0 10px black,
      0 1px 10px black,
      1px 0 10px black,
      0 -1px 10px black,
      -1px -1px 10px black,
      1px 1px 10px black,
      -1px 1px 10px black,
      1px -1px 10px black;
  }

  p {
    line-height: 15px;
    padding: 10px 0px;
    margin: 0px;
  }

  .MuiInputLabel-root {
    border-radius: 8px;
    color: ${COLORS.white};
    width: 100%;
  }

  .MuiOutlinedInput-root {
    border: 1px solid ${COLORS.white};
    border-radius: 8px;
    &:hover {
      border: 1px solid ${COLORS.hover};
      border-radius: 8px;
      input {
        border: none;
      }
    }
  }

  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.hover};
      }
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.hover};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    // ${applyOnlyBorderGlow({ hoverColor: COLORS.hover })}
    border: 1px solid ${COLORS.white};
    border-radius: 8px;
  }

  .MuiInputBase-input {
    border-radius: 8px;
    color: ${COLORS.white};
    background: transparent;
    width: 100%;
  }

  .MuiFormHelperText-root {
    font-family: ${FONTS.text};
    font-size: 15px;
  }
`
