import { useEffect, useState } from 'react'
import { styled } from 'styled-components'

import { MarketStat, MarketStats, MarketStatsIntervalInfo } from '@packages/interfaces'
import { formatBigIntUsd } from '@packages/utils'

import {
  HelpTooltip,
  LargeButtonGroup,
  SmallButtonGroup,
  PaginatedTable,
} from 'src/shared/components'
import { useIsMobile } from 'src/shared/hooks'
import {
  dummyTopMoversMarketStats,
  dummyMarketCapMarketStats,
  dummyVolumeMarketStats,
  dummyRecentlyAddedMarketStats,
  dummyActivityMarketStats,
} from 'src/testData'
import { RuneDetailsLink, formatRuneName } from 'src/runes'

import { MARKET_STATS_OPTION_LABELS, TIMEFRAME_LABELS } from '../constants'
import { RunePriceSparklineChart } from './RunePriceSparklineChart'

function getIntervalInfo(
  selectedTimeframe: string,
  marketStat: MarketStat
): MarketStatsIntervalInfo {
  switch (selectedTimeframe) {
    case TIMEFRAME_LABELS.ONE_WEEK:
      return marketStat.oneWeek
    case TIMEFRAME_LABELS.THREE_MONTHS:
      return marketStat.threeMonths
    case TIMEFRAME_LABELS.ONE_YEAR:
      return marketStat.oneYear
    case TIMEFRAME_LABELS.ONE_DAY:
    default:
      return marketStat.oneDay
  }
}

function getMarketStats(marketStatType: string): MarketStats {
  switch (marketStatType) {
    case MARKET_STATS_OPTION_LABELS.activity:
      return dummyActivityMarketStats

    case MARKET_STATS_OPTION_LABELS.recentlyAdded:
      return dummyRecentlyAddedMarketStats

    case MARKET_STATS_OPTION_LABELS.marketCap:
      return dummyMarketCapMarketStats

    case MARKET_STATS_OPTION_LABELS.volumeUSD:
      return dummyVolumeMarketStats

    case MARKET_STATS_OPTION_LABELS.topMovers:
    default:
      return dummyTopMoversMarketStats
  }
}

interface Props {
  limit?: number
}

export function RuneMarketStatsTable({ limit = 10 }: Props) {
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedMarketStat, setSelectedMarketStat] = useState(MARKET_STATS_OPTION_LABELS.topMovers)
  const [selectedTimeframe, setSelectedTimeframe] = useState(TIMEFRAME_LABELS.ONE_DAY)
  const [marketStats, setMarketStats] = useState(dummyTopMoversMarketStats)

  useEffect(() => {})

  function handleTimeframeOnChange(value: string) {
    setSelectedTimeframe(value)
  }
  const selectedTimeframeOptions = Object.values(TIMEFRAME_LABELS).map((value) => ({
    value: value,
    label: value,
  }))

  function handleSelectedMarketStatOnChange(value: string) {
    setSelectedMarketStat(value)
    setMarketStats(getMarketStats(value))
  }

  async function fetchPage() {
    setIsLoading(true)
    // TODO: load data
  }

  const marketStatsOptions = Object.values(MARKET_STATS_OPTION_LABELS).map((value) => ({
    value: value,
    label: value,
  }))

  return (
    <>
      <Buttons>
        <LargeButtonGroup
          options={marketStatsOptions}
          value={selectedMarketStat}
          onChange={handleSelectedMarketStatOnChange}
          exclusive
        />
        <SmallButtonGroup
          options={selectedTimeframeOptions}
          value={selectedTimeframe}
          onChange={handleTimeframeOnChange}
          exclusive
        />
      </Buttons>
      <PaginatedTable
        columns={[
          {
            dataKey: 'rank',
            label: 'Rank',
            hideOnMobile: true,
            formatter: ({ index }) => <Rank>#{index + 1}</Rank>,
          },
          {
            dataKey: 'symbol',
            label: 'Token',
            formatter: ({ data: runeMarket, index }) => (
              <RuneDetailsLink
                rune={runeMarket}
                displayName={
                  isMobile
                    ? `#${index + 1} ${formatRuneName(runeMarket)}`
                    : formatRuneName(runeMarket)
                }
              />
            ),
          },
          {
            dataKey: 'priceUsd',
            label: 'Price',
            content: (
              <PriceHeader>
                Price
                <HelpTooltip content='Price of cheapest available runes listing' />
              </PriceHeader>
            ),
            formatter: ({ data: runeMarket }) => (
              <Price>{formatBigIntUsd(runeMarket.priceUsd)}</Price>
            ),
          },
          {
            dataKey: 'volumeUSD',
            label: 'Volume',
            content: (
              <PriceHeader>
                Volume
                <HelpTooltip
                  content={`A measure of how much of a token was traded in the last ${selectedTimeframe}`}
                />
              </PriceHeader>
            ),
            formatter: ({ data: runeMarket }) => (
              <Price>
                {formatBigIntUsd(getIntervalInfo(selectedTimeframe, runeMarket).volumeUsd)}
              </Price>
            ),
          },
          {
            dataKey: 'marketCapUSD',
            label: 'Market Cap',
            content: (
              <PriceHeader>
                Market Cap
                <HelpTooltip content='Total market value of a runes circulating supply' />
              </PriceHeader>
            ),
            formatter: ({ data: runeMarket }) => (
              <Price>{formatBigIntUsd(runeMarket.marketCapUsd)}</Price>
            ),
          },
          {
            dataKey: 'chart',
            label: '',
            hideOnMobile: true,
            formatter: ({ data: runeMarket }) => {
              return <RunePriceSparklineChart marketStat={runeMarket} height={35} />
            },
          },
        ]}
        paginatedData={[marketStats.marketStats]}
        fetchPage={fetchPage}
        loading={isLoading}
      />
    </>
  )
}

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const Rank = styled.div`
  font-weight: 700;
`

const PriceHeader = styled.div`
  display: flex;
  gap: 5px;
`

const Price = styled.div`
  font-weight: 700;
`
