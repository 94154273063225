import { base64 } from '@scure/base'

import * as btc from '@packages/scure-btc-signer'

import {
  BuildBulkMintResult,
  BuildBulkMintSerializedResult,
  SignBulkMintResult,
  SignBulkMintSerializedResult,
} from './btcSigner'

addEventListener('message', async (e: MessageEvent) => {
  const { action, bulk } = e.data as { action: string; bulk: BuildBulkMintSerializedResult }
  if (action === 'signBulkMintTxs') {
    const deserializedBulk: BuildBulkMintResult = {
      ...bulk,
      childTxs: bulk.childTxsBase64.map((tx) => btc.Transaction.fromPSBT(base64.decode(tx))),
      parentTx: btc.Transaction.fromPSBT(base64.decode(bulk.parentTxSignedBase64), {
        allowUnknownOutputs: true,
      }),
    }

    const result = await signBulkMintTxs(deserializedBulk)
    postMessage(result)
  }
})

async function signBulkMintTxs(bulk: BuildBulkMintResult): Promise<SignBulkMintSerializedResult> {
  bulk.parentTx.finalize()
  const res: SignBulkMintResult = { parentTx: bulk.parentTx, childTxs: [], tempKey: bulk.tempKey }
  for (let i = 0; i < bulk.childTxs.length; i++) {
    const tx = bulk.childTxs[i]
    const input = tx.getInput(0)
    tx.updateInput(0, { ...input, txid: bulk.parentTx.id })
    tx.signIdx(bulk.tempKey, 0, [btc.SigHash.DEFAULT], new Uint8Array(32))
    tx.finalize()
    res.childTxs.push(tx)
  }
  const serializedResult: SignBulkMintSerializedResult = {
    ...res,
    parentTxBase64: base64.encode(res.parentTx.toPSBT()),
    childTxsBase64: res.childTxs.map((tx) => base64.encode(tx.toPSBT())),
  }
  return serializedResult
}
