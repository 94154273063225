import { styled } from 'styled-components'

import { Rune, RuneBalance } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { useMarketSearch } from 'src/markets'
import { Button, DotsLoader, Modal, TextInput } from 'src/shared/components'
import { COLORS, FONTS } from 'src/shared/constants'
import { formatRuneName, formatRunesAmount } from 'src/runes'
import { hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'
import { usePaginationApi } from 'src/api'

interface Props {
  isOpen: boolean
  onSelectRune: (rune: Rune) => void
  onClose: () => void
}

export function SelectRuneModal({ isOpen, onClose, onSelectRune }: Props) {
  const { runesAddress } = useWalletContext()
  const { handleOnChange, loading, hasSearched, searchResults, searchQuery } = useMarketSearch({
    orderBy: 'mintCount',
  })
  const { paginatedData: runeBalances } = usePaginationApi<RuneBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byAccount, {
      address: runesAddress?.addrString ?? '',
    }),
  })

  function handleOnSelectRune(rune: Rune) {
    handleOnChange('')
    onSelectRune(rune)
    onClose()
  }

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title='Select a Rune'>
      <ModalContent>
        <SearchInput value={searchQuery} onChange={handleOnChange} label='Search Rune Name' />
        <ModalLinkBreak />
        <BottomSectionHeader>
          {searchQuery.length === 0 ? 'Your Runes' : 'Search Results'}
        </BottomSectionHeader>
        {searchQuery.length === 0 &&
          runeBalances.flat().map((rune) => {
            return (
              <RuneButton
                key={rune.runeId}
                onClick={() => {
                  handleOnSelectRune(rune)
                }}
              >
                <RuneNameButtonContent>
                  {rune.runeSymbolChar && rune.runeSymbolChar + ' '}
                  {formatRuneName(rune)}
                </RuneNameButtonContent>
                {formatRunesAmount({
                  rune,
                  runesAmount: rune.runesAmount,
                  displayShortFormat: true,
                })}
              </RuneButton>
            )
          })}
        {loading && <DotsLoader />}
        {hasSearched && searchQuery.length !== 0 && (
          <ResultsSection>
            {searchResults.map((rune) => {
              return (
                <RuneButton
                  key={rune.runeId}
                  onClick={() => {
                    handleOnSelectRune(rune)
                  }}
                >
                  {rune.runeSymbolChar && rune.runeSymbolChar + ' '}
                  {formatRuneName(rune)}
                </RuneButton>
              )
            })}
          </ResultsSection>
        )}
      </ModalContent>
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)``

const ModalContent = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  height: 100%;
`

const SearchInput = styled(TextInput)`
  width: 100%;
  max-width: 500px;
  text-transform: uppercase;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const ResultsSection = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow: scroll;
`

const RuneButton = styled(Button)`
  background-color: transparent;
  color: ${COLORS.white};
  border-radius: 5px;
  justify-content: space-between;
  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.3)};
  }
`

const RuneNameButtonContent = styled.div``

const ModalLinkBreak = styled.div`
  border-bottom: 1px solid white;
  width: 100%;
  padding: 10px 0px;
`

const BottomSectionHeader = styled.h3`
  font-family: ${FONTS.headers};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  text-align: left;
  color: ${hexToRgb(COLORS.white, 0.75)};
`
