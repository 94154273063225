import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

const DATE_STRING_FORMAT = 'llll'
const SHORT_DATE_STRING_FORMAT = 'M/D/YY h:m A'

export function unixTimestampToDateString(unixTimestampSeconds: bigint, shortFormat = false) {
  return dayjs
    .unix(parseInt(unixTimestampSeconds.toString()))
    .format(shortFormat ? SHORT_DATE_STRING_FORMAT : DATE_STRING_FORMAT)
}

export function unixMsTimestampToDateString(unixTimestampMs: bigint, shortFormat = false) {
  return dayjs(parseInt(unixTimestampMs.toString())).format(
    shortFormat ? SHORT_DATE_STRING_FORMAT : DATE_STRING_FORMAT,
  )
}
