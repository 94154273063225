import styled from 'styled-components'
import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Button } from 'src/shared/components'
import { BREAKPOINTS, FONTS } from 'src/shared/constants'

import { WalletPickerMenu, formatShortAddress, useWalletContext } from '../'
import { WalletMenu } from './WalletMenu'

export function ConnectButton() {
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const [walletMenuAnchorElement, setWalletMenuAnchorElement] = useState<null | HTMLElement>(null)
  const { paymentAddress, isConnected } = useWalletContext()

  const handleWalletClick = (event: React.MouseEvent<HTMLElement>) => {
    setWalletMenuAnchorElement(event.currentTarget)
  }

  function onWalletMenuClose() {
    setWalletMenuAnchorElement(null)
  }

  function openModal() {
    setIsWalletModalOpen(true)
  }

  function closeModal() {
    setIsWalletModalOpen(false)
  }

  return (
    <>
      {isConnected ? (
        <>
          <WalletButton onClick={handleWalletClick as any}>
            {paymentAddress ? formatShortAddress(paymentAddress.addrString) : ''}{' '}
            <KeyboardArrowDownIcon />
          </WalletButton>
        </>
      ) : (
        <Connect onClick={openModal}>Connect</Connect>
      )}
      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={closeModal} />
      <WalletMenu anchorElement={walletMenuAnchorElement} onClose={onWalletMenuClose} />
    </>
  )
}

const Connect = styled(Button)`
  gap: 10px;
  font-weight: 500;
  font-family: ${FONTS.text};
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 30px;
    font-size: 13px;
  }
`

const WalletButton = styled(Button)`
  font-weight: 500;
  font-family: ${FONTS.text};
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 30px;
    font-size: 13px;
  }
`
