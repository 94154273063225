import { hexToRgb } from '../utils'

export const COLORS = {
  buttons: {
    primary: '#e9e8eb',
    accent: '#fcfcfc',
  },
  hover: '#b67ad9',
  gradient: '#6f257b',
  inputs: '#606061',
  positive: '#3CB679',
  negative: '#c7344b',
  warning: '#c7531e',
  text: '#FFFFFF',
  border: '#3a3e3f',
  background: {
    primary: '#191516',
    container: `${hexToRgb('#FFFFFF', 0.05)}`,
    overlay: '#1C1C27',
  },
  link: '#94E6E9',
  white: '#FFFFFF',
  black: '#000000',
}
