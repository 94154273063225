import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'
import { buildMarketDetailsUrl } from 'src/pages'
import { BUTTON_HOVER_STYLES } from 'src/shared/components'

interface Props {
  runeName: string
}

export function MarketDetailsLink({ runeName }: Props) {
  return <LinkWrapper to={buildMarketDetailsUrl(runeName)}>Trade</LinkWrapper>
}

const LinkWrapper = styled(Link)`
  background-color: ${COLORS.buttons.primary};
  color: ${COLORS.black};
  font-family: ${FONTS.text};
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  padding: 5px 0px;
  ${BUTTON_HOVER_STYLES}
`
