import _Modal from '@mui/material/Modal'
import { createGlobalStyle, styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from '../constants'
import { CloseButton } from './buttons'
import { HeaderText } from './Header'
import { hexToRgb } from '../utils'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  width?: number
  title?: string
}

export function Modal({ isOpen, onClose, title, children, width, className }: Props) {
  return (
    <>
      <GlobalModalStyles />
      <ModalWrapper open={isOpen} onClose={onClose} className={className}>
        <Content width={width ?? 450}>
          <Header>
            <Title>{title}</Title>
            <CloseButtonWrapper onClose={onClose} />
          </Header>

          {children}
        </Content>
      </ModalWrapper>
    </>
  )
}

const GlobalModalStyles = createGlobalStyle`
  .MuiModal-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ModalWrapper = styled(_Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const CloseButtonWrapper = styled(CloseButton)`
  align-self: end;
  img {
    height: 30px;
    width: 30px;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    img {
      height: 25px;
    }
  }
`

const Title = styled(HeaderText)`
  font-size: 20px;
  color: ${hexToRgb(COLORS.white, 0.75)};
`

const Content = styled.div<{ width: number }>`
  background-color: ${COLORS.background.primary};
  border: 1px solid ${COLORS.white};
  border-radius: 15px;
  padding: 20px;
  width: ${(props) => props.width}px;
  max-height: 85vh;
  height: fit-content;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: 90%;
  }
`
