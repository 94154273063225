import { styled } from 'styled-components'
import { useState } from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

import { Order, RuneDetails } from '@packages/interfaces'
import { formatBigIntUsd, satsToBtc } from '@packages/utils'

import { Card } from 'src/shared/components'
import { formatRuneName, formatRunesAmount } from 'src/runes'

import { BuyOrdersDrawer } from './BuyOrdersDrawer'

interface Props {
  runeDetails: RuneDetails
  order: Order
  selectedToBuy?: boolean
}

export function OrderCard({ order, selectedToBuy = false, runeDetails }: Props) {
  const [isBuyDrawerOpen, setIsBuyDrawerOpen] = useState(false)

  function openDrawer() {
    setIsBuyDrawerOpen(true)
  }

  function closeDrawer() {
    setIsBuyDrawerOpen(false)
  }

  return (
    <>
      <CardWrapper onClick={openDrawer}>
        <Row>
          <Title>
            <Amount>{`${formatRunesAmount({
              rune: order,
              runesAmount: order.runesAmount,
            })}`}</Amount>
            <RuneName>{formatRuneName(order)}</RuneName>
          </Title>
          {selectedToBuy ? <AddCircleRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
        </Row>
        <Row>
          <LeftColumn>
            <BTCPrice>
              {order.priceSats} SATS / {order.runeName}
            </BTCPrice>
            <USDPrice>{order.priceUsd}</USDPrice>
          </LeftColumn>
          <RightColumn>
            <BTCPrice>{satsToBtc(order.satsAmount)} BTC</BTCPrice>
            <USDPrice>{formatBigIntUsd(order.valueUsd)}</USDPrice>
          </RightColumn>
        </Row>
      </CardWrapper>
      <BuyOrdersDrawer
        isOpen={isBuyDrawerOpen}
        onClose={closeDrawer}
        orders={[order]}
        runeDetails={runeDetails}
      />
    </>
  )
}

const CardWrapper = styled(Card)`
  gap: 15px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div``

const Amount = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
`

const RuneName = styled.div`
  font-weight: 500;
  font-size: 19px;
`

const LeftColumn = styled.div``

const RightColumn = styled.div`
  text-align: right;
`

const BTCPrice = styled.div`
  font-size: 14px;
`

const USDPrice = styled.div`
  font-size: 14px;
`
