import { styled } from 'styled-components'
import { SetStateAction, useEffect, useState } from 'react'
import { base64, hex } from '@scure/base'
import { AddressPurpose } from '@sats-connect/core'

import * as btc from '@packages/scure-btc-signer'
import * as wasm from '@packages/rune-wasm'
import { set_wasm } from '@packages/rune-wasm/set_wasm'

import { TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'

import { PageContainer } from './PageContainer'
import { EtchingCommit, JsEtchingParams, useWalletContext } from '../../wallet'
import { Button } from '../../shared/components'

const testRune = {
  rune_id: '2584333:39',
  rune_name_spaced: 'I*NEED*TEST*RUNES',
}

const testEtchingParams: JsEtchingParams = {
  divisibility: 0,
  premine: 1000n,
  premine_vout: undefined,
  rune: 'WALLABE.WALRUS',
  symbol: 'Ꮗ',
  turbo: false,
  has_terms: true,
  terms_cap: 420n,
  terms_amount: 69n,
  terms_absolute_open_height: undefined,
  terms_absolute_close_height: undefined,
  terms_relative_open_height: undefined,
  terms_relative_close_height: undefined,
}

const fee = 150n

export function DemoWasmPage() {
  const wallet = useWalletContext()

  const [rawTx, setRawTx] = useState('')
  const [psbtData, setPsbtData] = useState('')
  const [artifact, _setArtifact] = useState<wasm.Artifact>()
  const [etchingCommit, setEtchingCommit] = useState<EtchingCommit>()

  useEffect(() => {
    set_wasm()
  }, [])

  // const [runeOutpoints, setRuneOutpoints] = useState<OutpointAmountsByRuneIdForAddress>()

  const setArtifact = (val: SetStateAction<wasm.Artifact | undefined>) => {
    // janky way to avoid mem leak
    // @ts-ignore
    if (artifact !== undefined && artifact.__wbg_ptr !== 0) {
      artifact.free()
    }
    _setArtifact(val)
  }

  useEffect(() => {
    if (artifact === undefined) {
      return
    }
    const runestone = artifact.to_runestone()
    console.log('runestone', runestone)
  }, [artifact])

  return (
    <PageContainer>
      <Title>Demo Wasm</Title>
      <Button onClick={() => console.log(wallet)}>Log Wallet</Button>
      <Button onClick={() => wasm.init()}>Init Wasm</Button>
      <Button
        onClick={async () => {
          for (let i = 0; i < 20; i++) {
            const sigResult = await wallet.signMessage('hi', AddressPurpose.Payment)
            if (sigResult.success) {
              console.log(sigResult.signedMessage)
            }
          }
        }}
      >
        Sign Message
      </Button>
      <Button
        onClick={() =>
          wallet.mintRune({
            runeId: testRune.rune_id,
            runeName: testRune.rune_name_spaced,
            fee,
            recipientAddress: wallet.runesAddress?.addrString ?? '',
          })
        }
      >
        Mint {testRune.rune_name_spaced}
      </Button>
      <Button
        onClick={() =>
          wallet.mintBulkRune({
            runeId: testRune.rune_id,
            runeName: testRune.rune_name_spaced,
            fee,
            recipientAddress: wallet.runesAddress?.addrString ?? '',
            quantity: 25n,
            estimatedTotalNetworkFee: 0n,
            childEstimatedVsize: BigInt(TRX_VIRTUAL_SIZE_BYTES.mint),
            onStatusChange: () => {},
          })
        }
      >
        Mint {testRune.rune_name_spaced} 25x
      </Button>
      <textarea onChange={(e) => setRawTx(e.target.value)} value={rawTx}></textarea>
      <Button
        onClick={() => {
          setArtifact(wasm.Artifact.from_transaction(hex.decode(rawTx)))
        }}
      >
        Decode Raw TX
      </Button>
      <textarea onChange={(e) => setPsbtData(e.target.value)} value={psbtData}></textarea>
      <Button
        onClick={() => {
          const tx = btc.Transaction.fromPSBT(hex.decode(psbtData))
          console.log('tx', tx)
          setArtifact(wasm.Artifact.from_transaction(hex.decode(tx.hex)))
        }}
      >
        Decode PSBT Data
      </Button>

      {/*<Button*/}
      {/*  onClick={async () => {*/}
      {/*    const outpointApiResult = await apiFetch<PaginationResponse<OutpointAmountsByRuneIdForAddress>>(*/}
      {/*      `${API_URL}${replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.runeOutpointsByAccount, {*/}
      {/*          runeId: testRune.rune_id,*/}
      {/*          address: wallet.runesAddress ?? '',*/}
      {/*        },*/}
      {/*      )}`,*/}
      {/*    )*/}
      {/*    console.log(outpointApiResult)*/}
      {/*    setRuneOutpoints(outpointApiResult.data)*/}
      {/*  }}>*/}
      {/*  Fetch Unspent Rune*/}
      {/*</Button>*/}

      {/*<Button onClick={*/}
      {/*  async () => {*/}
      {/*    if (runeOutpoints && runeOutpoints.outpointAmounts.length > 0) {*/}
      {/*      const runeOutpoint = runeOutpoints.outpointAmounts[0]*/}
      {/*      const [outpoint_hash, voutStr] = runeOutpoint.outpointId.split(':')*/}
      {/*      const vout = Number(voutStr)*/}

      {/*      const {*/}
      {/*        bitcoin: { transactions },*/}
      {/*      } = getMempoolApiInstance()*/}

      {/*      const runeTx = await transactions.getTx({ txid: outpoint_hash })*/}

      {/*      const runeUtxo: RuneOutpoint = {*/}
      {/*        runeId: testRune.rune_id,*/}
      {/*        amount: runeOutpoint.amount,*/}
      {/*        utxo: {*/}
      {/*          value: runeTx.vout[vout].value,*/}
      {/*          status: {*/}
      {/*            confirmed: false,*/}
      {/*            block_height: 0,*/}
      {/*            block_hash: '',*/}
      {/*            block_time: 0,*/}
      {/*          },*/}
      {/*          txid: outpoint_hash,*/}
      {/*          vout,*/}
      {/*        },*/}
      {/*      }*/}
      {/*      const psbtResult = await wallet.sellRunePsbt({*/}
      {/*        proceeds: 10n, runeId: testRune.rune_id, runeUtxo,*/}
      {/*      })*/}

      {/*      console.log(psbtResult)*/}
      {/*    } else {*/}
      {/*      alert('fetch unspent runes first')*/}
      {/*    }*/}
      {/*  }*/}
      {/*}>*/}
      {/*  Create Sell PSBT*/}
      {/*</Button>*/}

      <Button
        onClick={async () => {
          const _etchResult = await wallet.commitEtchRune({
            etchParams: testEtchingParams,
            fee: fee,
          })

          if (!_etchResult.commitTxResult.success) {
            console.log(_etchResult.commitTxResult)
            throw new Error('commit tx failed')
          }

          setEtchingCommit({
            commitTx: _etchResult.commitTx,
            revealScript: _etchResult.revealScript,
            txid: _etchResult.txId,
            revealAmount: _etchResult.revealAmount,
            revealKey: _etchResult.revealKey,
          })
        }}
      >
        Commit {testEtchingParams.rune}
      </Button>

      <Button
        onClick={async () => {
          const _revealResult = await wallet.revealEtchRune({
            etchParams: testEtchingParams,
            fee: 20n,
            etchingCommit: etchingCommit!,
          })

          if (_revealResult.success) {
            const tx = btc.Transaction.fromPSBT(base64.decode(_revealResult.signedPsbtBase64))
            console.log(tx)
          }
        }}
      >
        Reveal {testEtchingParams.rune}
      </Button>

      <Button
        onClick={async () => {
          for (let i = 0; i < 20; i++) {
            const sigResult = await wallet.signMessage('hi', AddressPurpose.Payment)
            if (sigResult.success) {
              console.log(sigResult.signedMessage)
            }
          }
        }}
      >
        Sign Message
      </Button>
    </PageContainer>
  )
}

const Title = styled.h1``
