import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

import { Rune, RuneDetails, RuneOutpoint, SellSubmission } from '@packages/interfaces'
import { API_ENDPOINTS, BTC_DECIMALS } from '@packages/constants'

import { BUTTON_HOVER_STYLES, BigIntInput, Button } from 'src/shared/components'
import { BREAKPOINTS, COLORS, Z_INDEX } from 'src/shared/constants'
import { formatRuneName, formatRunesAmount } from 'src/runes'
import { hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { apiFetch, useUpdateOnBlockPaginationApi } from 'src/api'
import { RuneUtxoOutpoint, useWalletContext } from 'src/wallet'
import { mempoolApiInstance } from 'src/web3'

import { SelectRuneModal } from './SelectRuneModal'

interface Props {
  runeDetails?: RuneDetails
}

export function RuneSwapper({ runeDetails }: Props) {
  const { runesAddress, paymentAddress, sellRunePsbt } = useWalletContext()
  const [isSelectRuneModalOpen, setIsSelectRuneModalOpen] = useState(false)
  const [selectedOutpoints, setSelectedOutpoints] = useState<RuneOutpoint[]>([])

  const [selectedRune, setSelectedRune] = useState<Rune | null>(null)
  const [runesAmount, setRunesAmount] = useState<bigint>(0n)
  const [btcAmount, setBtcAmount] = useState<bigint>(0n)
  const [isBuying, setIsBuying] = useState(true)

  const {
    paginatedData: runeOutpoints,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<RuneOutpoint>({
    disabled: !selectedRune || !runesAddress,
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.runesOutpointsForAccount, {
      runeId: selectedRune?.runeId ?? '',
      address: runesAddress?.addrString ?? '',
    }),
  })

  useEffect(() => {
    if (runeDetails) {
      setSelectedRune(runeDetails)
    }
  }, [runeDetails])

  async function handleSwap() {
    if (!selectedRune || !runesAddress || !paymentAddress) {
      return
    }

    if (isBuying) {
      // buy
    } else {
      // sell
      const formattedOutpoints: RuneUtxoOutpoint[] = await Promise.all(
        selectedOutpoints.map(async (outpoint) => {
          const {
            bitcoin: { transactions },
          } = mempoolApiInstance
          const [outpointHash, voutStr] = outpoint.outpointId.split(':')
          const vout = Number(voutStr)
          const runeTx = await transactions.getTx({ txid: outpointHash })

          const runeUtxo: RuneUtxoOutpoint = {
            runeId: selectedRune.runeId,
            amount: outpoint.amount,
            utxo: {
              value: runeTx.vout[vout].value,
              status: {
                confirmed: false,
                block_height: 0,
                block_hash: '',
                block_time: 0,
              },
              txid: outpointHash,
              vout,
            },
          }

          return runeUtxo
        }),
      )

      // TODO: box transaction
      // formattedOutpoints

      const boxedRuneUtxo = formattedOutpoints[0]
      console.log('sellRune', boxedRuneUtxo, btcAmount, selectedRune.runeId)
      const response = await sellRunePsbt({
        runeId: selectedRune.runeId,
        proceeds: btcAmount,
        runeUtxo: boxedRuneUtxo,
      })

      if (response.success) {
        const boxedOutpointId = `${boxedRuneUtxo.utxo.txid}:${boxedRuneUtxo.utxo.vout}`
        const sellSubmission: SellSubmission = {
          runeId: selectedRune.runeId,
          amountRunes: runesAmount,
          amountSats: btcAmount,
          signedByAddress: runesAddress.addrString,
          sellPsbt: {
            dataBase64: response.signedPsbtBase64,
            assertions: {
              boxedOutpointId,
              btcProceedsAddress: paymentAddress.addrString,
            },
          },
        }
        await apiFetch(
          replaceUrlParams(API_ENDPOINTS.POST.runes.orders.sell, {
            runeName: selectedRune.runeName,
          }),
          {},
          { method: 'POST', body: sellSubmission },
        )
      }
    }
  }

  function removeOutpoint(outpoint: RuneOutpoint) {
    setSelectedOutpoints((prev) =>
      prev.filter((selected) => selected.outpointId !== outpoint.outpointId),
    )
  }

  function addOutpoint(outpoint: RuneOutpoint) {
    setSelectedOutpoints((prev) => [...prev, outpoint])
  }

  const isSubmitDisabled =
    !selectedRune || runesAmount === 0n || btcAmount === 0n || !paymentAddress || !runesAddress

  return (
    <Container>
      <SelectRuneModal
        isOpen={isSelectRuneModalOpen}
        onSelectRune={(rune) => setSelectedRune(rune)}
        onClose={() => setIsSelectRuneModalOpen(false)}
      />
      <Inputs isBuying={isBuying}>
        <InputContainer>
          <BTCInput
            value={btcAmount}
            onChange={(amount) => setBtcAmount(amount)}
            decimals={BTC_DECIMALS}
          />
          <BTCButton>BTC</BTCButton>
        </InputContainer>
        <ArrowIconContainer onClick={() => setIsBuying(!isBuying)}>
          <ArrowIcon width={15} />
        </ArrowIconContainer>
        <InputContainer>
          <RuneInput
            value={runesAmount}
            onChange={(amount) => setRunesAmount(amount)}
            decimals={selectedRune?.runeDecimals ?? 0}
          />
          <RuneButton onClick={() => setIsSelectRuneModalOpen(true)}>
            {selectedRune ? formatRuneName(selectedRune) : ''}
            <ArrowDropDownRoundedIcon />
          </RuneButton>
        </InputContainer>
      </Inputs>
      <SubmitButton onClick={handleSwap} disabled={isSubmitDisabled}>
        Submit
      </SubmitButton>

      {selectedRune &&
        runeOutpoints.flat().map((runeOutpoint) => {
          return (
            <OutpointContainer>
              <SelectOutpointButton>
                {selectedOutpoints.find(
                  (selected) => selected.outpointId === runeOutpoint.outpointId,
                ) ? (
                  <AddCircleRoundedIcon onClick={() => removeOutpoint(runeOutpoint)} />
                ) : (
                  <AddCircleOutlineRoundedIcon onClick={() => addOutpoint(runeOutpoint)} />
                )}
              </SelectOutpointButton>
              {runeOutpoint.outpointId}{' '}
              {formatRunesAmount({
                runesAmount: runeOutpoint.amount,
                rune: selectedRune,
              })}
            </OutpointContainer>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const Inputs = styled.div<{ isBuying: boolean }>`
  font-weight: 700;
  background-color: ${COLORS.background.container};
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  gap: 5px;
  display: flex;
  flex-direction: ${(props) => (props.isBuying ? 'column' : 'column-reverse')};
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${COLORS.white};
  border-radius: 15px;
  padding: 10px 15px;
`

const INPUT_WIDTH = 500

const ArrowIconContainer = styled.div`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.buttons.primary};
  outline: solid 2px ${COLORS.background.overlay};
  position: relative;
  top: -6px;
  left: 48%;
  top: -16px;
  margin-bottom: -30px;
  z-index: ${Z_INDEX.priorityContent};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.link};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    left: 50%;
  }
`

const ArrowIcon = styled(ArrowDownwardRoundedIcon)`
  width: 20px;
  color: ${COLORS.black};
`

const BigIntInputWrapper = styled(BigIntInput)`
  color: ${COLORS.white};
  background-color: transparent;
  width: 100%;
  width: ${INPUT_WIDTH}px;
  &:focus {
    border: none;
  }
  input {
    width: 100%;
    &:focus {
      outline: none !important;
      border: none;
    }
    border: none;
    background-color: transparent;
    color: ${COLORS.white};
    font-weight: 700;
    font-size: 20px;
    padding: 10px;
  }
`

const BTCInput = styled(BigIntInputWrapper)``

const RuneInput = styled(BigIntInputWrapper)``

const BTCButton = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${hexToRgb(COLORS.white, 0.3)};
`

const RuneButton = styled(Button)`
  color: ${COLORS.black};
  font-weight: 700;
  padding: 5px 15px;
  padding-left: 23px;
  border-radius: 5px;
  background-color: ${COLORS.buttons.primary};

  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.5)};
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: 200px;
`

const OutpointContainer = styled.div`
  display: flex;
  gap: 5px;
`

const SelectOutpointButton = styled.div`
  ${BUTTON_HOVER_STYLES}
  width: fit-content;
  height: fit-content;
  svg {
    height: 28px;
    width: 28px;
  }
`
