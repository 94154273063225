/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Address,
  AddressPurpose,
  BitcoinProvider,
  Params,
  Requests,
  RpcResponse,
} from '@sats-connect/core'

import { hexToBase64 } from 'src/shared/utils'

import { CustomBitcoinProvider } from './CustomBitcoinProvider'
import {
  decodeSendBitcoinRequest,
  decodeSignMessageRequest,
  decodeSignTransactionRequest,
} from '../utils'

export class UnisatBitcoinProvider implements BitcoinProvider, CustomBitcoinProvider {
  private unisat: any

  public isAvailable() {
    this.setLib()

    return !!this.unisat
  }

  public setLib() {
    if (!this.unisat && typeof (window as any).unisat !== 'undefined') {
      this.unisat = (window as any).unisat
    }
  }

  public async connect(_request: string) {
    const addresses: string[] = await this.unisat.requestAccounts()
    const formattedAddresses: Address[] = []

    // only the public key for the active account
    const publicKey = await this.unisat.getPublicKey()

    addresses.forEach((address: string, index) => {
      // unisat uses the same address for ordinals and payments
      formattedAddresses.push({
        address,
        publicKey: index === 0 ? publicKey : '',
        purpose: AddressPurpose.Payment,
      })
      formattedAddresses.push({
        address,
        publicKey: index === 0 ? publicKey : '',
        purpose: AddressPurpose.Ordinals,
      })
    })

    return { addresses: formattedAddresses }
  }

  public async disconnect() {}

  public async signMessage(request: string) {
    return this.unisat.signMessage(decodeSignMessageRequest(request))
  }

  // https://docs.unisat.io/dev/unisat-developer-service/unisat-wallet#signpsbt
  public async signTransaction(request: string) {
    const { psbtHex, broadcast } = decodeSignTransactionRequest(request)
    const hex = await this.unisat.signPsbt(psbtHex, {
      autoFinalized: broadcast,
    })

    let txId
    if (broadcast) {
      txId = await this.unisat.pushPsbt(hex)
    }

    return {
      psbtBase64: hexToBase64(hex),
      txId,
    }
  }

  public async sendBtcTransaction(request: string) {
    const { amount, address } = decodeSendBitcoinRequest(request)
    const trxId = await this.unisat.sendBitcoin(address, amount)
    return trxId
  }

  public async call(_request: string) {
    throw new Error('Not implemented')
    return {}
  }

  public async createInscription(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async createRepeatInscriptions(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async signMultipleTransactions(_request: string) {
    throw new Error('Not implemented')
    return []
  }

  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined,
  ): Promise<RpcResponse<Method>> {
    return this.unisat.request(method, options, providerId)
  }
}
