import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'

import { HeaderText } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'

import { formatRuneName } from '../utils'

interface Props {
  runeDetails?: RuneDetails
  className?: string
}

export function RuneNameHeader({ runeDetails, className }: Props) {
  return (
    <RuneName className={className}>
      <RuneSymbol>{runeDetails?.runeSymbolChar ? `${runeDetails.runeSymbolChar} ` : ''}</RuneSymbol>
      {runeDetails ? formatRuneName(runeDetails) : ''}
    </RuneName>
  )
}

const RuneName = styled(HeaderText)``

const RuneSymbol = styled.span`
  color: ${COLORS.buttons.primary};
`
