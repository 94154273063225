import { createSearchParams } from 'react-router-dom'

export const ROUTES = {
  home: '/',
  etch: '/etch',
  mint: '/mint',
  marketDetails: '/market/:runeName',
  runeDetails: '/rune/:runeName',
  accountDetails: '/account/:address',
  demoWasm: '/demo-wasm',
  settings: '/settings',
}

export function buildAccountDetailsUrl(address: string, tab?: string) {
  return `${ROUTES.accountDetails.replace(':address', address)}${
    tab ? '?' + createSearchParams([['tab', tab]]) : ''
  }`
}

export function buildRuneDetailsUrl(runeName: string) {
  return ROUTES.runeDetails.replace(':runeName', runeName)
}

export function buildMarketDetailsUrl(runeName: string) {
  return ROUTES.marketDetails.replace(':runeName', runeName)
}
