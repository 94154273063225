import { hex } from '@scure/base'

import { useLocalStorage } from 'src/shared/hooks'

import { AddressDetails } from '../interfaces'
import { getAddressDetails } from '../utils'

export interface StoredAddressDetails {
  addrString: string
  pubKey: string
}

export function useAddressLocalStorage(
  key: string,
  walletName: string,
  initialValue?: AddressDetails,
) {
  const _initialValue =
    initialValue !== undefined
      ? {
          addrString: initialValue.addrString,
          pubKey: hex.encode(initialValue.pubKey),
        }
      : undefined
  const [_storedValue, _setValue] = useLocalStorage<StoredAddressDetails>(key, _initialValue)

  const setValue = (value: AddressDetails | undefined) => {
    _setValue(
      value !== undefined
        ? {
            addrString: value.addrString,
            pubKey: hex.encode(value.pubKey),
          }
        : undefined,
    )
  }

  if (_storedValue == undefined) {
    return [undefined, setValue] as const
  }

  return [
    getAddressDetails({
      walletName,
      addressItem: { address: _storedValue.addrString, publicKey: _storedValue.pubKey },
    }),
    setValue,
  ] as const
}
