import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import {
  AddressPurpose,
  BitcoinNetworkType,
  getAddress,
  getCapabilities,
  sendBtcTransaction,
  signMessage,
  signMultipleTransactions,
  signTransaction,
} from '@sats-connect/core'
import { base64 } from '@scure/base'
import { FeesRecommended } from '@mempool/mempool.js/lib/interfaces/bitcoin/fees'

import * as btc from '@packages/scure-btc-signer'
import * as wasm from '@packages/rune-wasm'
import { set_wasm } from '@packages/rune-wasm/set_wasm'
import { Block, BtcPrice, MintsSubmission, MintsSubmissionResult } from '@packages/interfaces'
import { P2TROut } from '@packages/scure-btc-signer/payment'
import { API_ENDPOINTS } from '@packages/constants'

import { useInterval, useLocalStorage } from 'src/shared/hooks'
import { DEPLOYED_BITCOIN_NETWORK, IS_TESTNET_DEPLOYMENT } from 'src/settings'
import { apiFetch } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

import {
  useUnisatProvider,
  useWalletStandardProvider,
  useAddressLocalStorage,
  useLeatherProvider,
  useOkxProvider,
} from './hooks'
import {
  BuildBulkMintResult,
  BuildBulkMintSerializedResult,
  buildBulkMintTxs,
  buildEtchingCommit,
  buildEtchingReveal,
  buildMintTx,
  buildSellPsbt,
  EtchingCommit,
  getAddressBtcBalances,
  getAddressDetails,
  getBlockTip,
  getBtcPrice,
  getRecommendedNetworkFees,
  getXverseProvider,
  JsEtchingParams,
  SignBulkMintResult,
  SignBulkMintSerializedResult,
} from './utils'
import { isCustomBitcoinProvider } from './providers'
import { AddressDetails, BtcBalances, BtcSignerNetwork, RuneUtxoOutpoint } from './interfaces'
import { WALLET_NAME, WalletName } from './constants'
import BulkMintWebWorker from './utils/bulkMintWebWorker?worker&inline'

import type {
  Address,
  BitcoinProvider,
  Capability,
  Recipient,
  SignMultipleTransactionsPayload,
  SignTransactionPayload,
} from '@sats-connect/core'

const BLOCK_INTERVAL_MS = 1000 * 60 * 1
const NETWORK_FEES_INTERVAL_MS = 1000 * 15
const BTC_PRICE_INTERVAL_MS = 1000 * 60 * 3

interface WalletContextType {
  btcBalances?: BtcBalances
  btcPrice?: BtcPrice
  blockTip?: Block
  recommendedNetworkFees?: FeesRecommended

  paymentAddress?: AddressDetails
  ordinalsAddress?: AddressDetails
  runesAddress?: AddressDetails
  walletName?: WalletName

  network: BitcoinNetworkType
  isConnected: boolean
  capabilities?: Set<Capability>
  capabilityState: 'loading' | 'loaded' | 'missing' | 'cancelled'
  isReady: boolean
  toggleNetwork: () => void
  connectWallet: (provider: BitcoinProvider, walletName: WalletName) => Promise<void>
  disconnectWallet: () => Promise<void>

  signMessage: (message: string, addressPurpose: AddressPurpose) => Promise<SignMessageResult>
  signTransaction: (payload: SignTransactionPayload) => Promise<SignTransactionResult>
  signMultipleTransactions: (
    payload: SignMultipleTransactionsPayload,
  ) => Promise<SignMultipleTransactionsResult>
  sendBitcoin: (recipients: Recipient[]) => Promise<SendBitcoinResult>

  commitEtchRune: (payload: {
    etchParams: JsEtchingParams
    fee: bigint
  }) => Promise<CommitEtchRuneResult>

  revealEtchRune: (payload: {
    etchParams: JsEtchingParams
    etchingCommit: EtchingCommit
    fee: bigint
  }) => Promise<SignTransactionResult>

  mintRune: (payload: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
  }) => Promise<SignTransactionResult>

  mintBulkRune: (payload: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
    estimatedTotalNetworkFee: bigint
    childEstimatedVsize: bigint
    quantity: bigint
    onStatusChange(status: BulkMintSignTransactionStatus): void
  }) => Promise<SignMultipleTransactionsResult>

  estimateMintBulkRuneVsize: (payload: {
    runeId: string
    recipientAddress: string
    fee: bigint
    estimatedTotalNetworkFee: bigint
    childEstimatedVsize: bigint
    quantity: bigint
  }) => Promise<{ child: number; parent: number }>

  sellRunePsbt: (payload: {
    runeId: string
    proceeds: bigint
    runeUtxo: RuneUtxoOutpoint
  }) => Promise<SignTransactionResult>
}
type BulkMintSignTransactionStatus = 'build' | 'wallet-prompt' | 'temp-key-sign' | 'api-submit'

interface WalletActionResult {
  success: boolean
  error?: string
}

interface WalletActionErrorResult extends WalletActionResult {
  success: false
  error: string
}

interface SignMessageSuccessResult extends WalletActionResult {
  success: true
  signedMessage: string
}

type SignMessageResult = SignMessageSuccessResult | WalletActionErrorResult

interface SignTransactionSuccessResult extends WalletActionResult {
  success: true
  signedPsbtBase64: string
  txId?: string
}

export type SignTransactionResult = SignTransactionSuccessResult | WalletActionErrorResult

interface SignMultipleTransactionsSuccessResult extends WalletActionResult {
  success: true
  signedTransactionResults: (SignTransactionSuccessResult | WalletActionErrorResult)[]
}

type SignMultipleTransactionsResult =
  | SignMultipleTransactionsSuccessResult
  | WalletActionErrorResult

interface SendBitcoinSuccessResult extends WalletActionResult {
  success: true
  txId: string
}

type SendBitcoinResult = SendBitcoinSuccessResult | WalletActionErrorResult

const WalletContext = createContext<WalletContextType | undefined>(undefined)

export const useWalletContext = () => {
  const context = useContext(WalletContext)
  if (context === undefined) {
    throw new Error('useWalletContext must be used within a WalletProvider')
  }
  return context
}

export interface CommitEtchRuneResult {
  commitTxResult: SignTransactionResult
  revealAmount: bigint
  txId: string
  revealScript: P2TROut
  revealKey: Uint8Array
  commitTx: btc.Transaction
}

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [wasmInitialized, setWasmInitialized] = useState(false)
  const [walletName, setWalletName] = useLocalStorage<WalletName | undefined>('walletName')

  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    'network',
    DEPLOYED_BITCOIN_NETWORK,
  )
  const [btcSignerNetwork, setBtcSignerNetwork] = useLocalStorage<BtcSignerNetwork>(
    'btcSignerNetwork',
    DEPLOYED_BITCOIN_NETWORK === BitcoinNetworkType.Testnet ? btc.TEST_NETWORK : btc.NETWORK,
  )

  const [paymentAddress, setPaymentAddress] = useAddressLocalStorage(
    'paymentAddress',
    walletName ?? '',
  )
  const [ordinalsAddress, setOrdinalsAddress] = useAddressLocalStorage(
    'ordinalsAddress',
    walletName ?? '',
  )
  const [runesAddress, setRunesAddress] = useAddressLocalStorage('runesAddress', walletName ?? '')

  const [capabilityState, setCapabilityState] = useState<
    'loading' | 'loaded' | 'missing' | 'cancelled'
  >('loading')
  const [capabilities, setCapabilities] = useState<Set<Capability>>()
  const [provider, setProvider] = useState<BitcoinProvider | undefined>()

  const [btcBalances, setBtcBalances] = useLocalStorage<BtcBalances | undefined>('btcBalances')
  const [blockTip, setBlockTip] = useLocalStorage<Block | undefined>('blockTip')
  const [recommendedNetworkFees, setRecommendedNetworkFees] = useLocalStorage<
    FeesRecommended | undefined
  >('recommendedNetworkFees')
  const [btcPrice, setBtcPrice] = useLocalStorage<BtcPrice | undefined>('btcPrice')

  useEffect(() => {
    if (!wasmInitialized) {
      set_wasm()
      wasm.init()
      setWasmInitialized(true)
    }
  }, [])

  useEffect(() => {
    const runCapabilityCheck = async () => {
      let runs = 0
      const MAX_RUNS = 20
      setCapabilityState('loading')

      try {
        await getCapabilities({
          onFinish(response) {
            setCapabilities(new Set(response))
            setCapabilityState('loaded')
          },
          onCancel() {
            setCapabilityState('cancelled')
          },
          payload: {
            network: {
              type: network,
            },
          },
        })
      } catch (e) {
        runs++
        if (runs === MAX_RUNS) {
          setCapabilityState('missing')
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 100))
    }

    runCapabilityCheck()

    setBtcSignerNetwork(IS_TESTNET_DEPLOYMENT ? btc.TEST_NETWORK : btc.NETWORK)
  }, [network])

  // btc balance

  useEffect(() => {
    if (paymentAddress) {
      getAddressBtcBalances({
        setBtcBalances,
        address: paymentAddress.addrString,
        forceUpdate: btcBalances === undefined,
      })
    }
  }, [paymentAddress, blockTip])

  // chain info

  useEffect(() => {
    getBlockTip({ setBlockTip, currentBlockTip: blockTip })
    getRecommendedNetworkFees({ setRecommendedNetworkFees })
    getBtcPrice({ setBtcPrice })
  }, [])

  useInterval(async () => {
    await getBlockTip({ setBlockTip, currentBlockTip: blockTip })
  }, BLOCK_INTERVAL_MS)

  useInterval(async () => {
    await getRecommendedNetworkFees({ setRecommendedNetworkFees })
  }, NETWORK_FEES_INTERVAL_MS)

  useInterval(async () => {
    await getBtcPrice({ setBtcPrice })
  }, BTC_PRICE_INTERVAL_MS)

  // reconnect to wallet provider on refresh

  const walletStandardProvider = useWalletStandardProvider(walletName)
  useEffect(() => {
    // walletStandardProvider will only be set if the walletName matched do not need to check that
    if (walletStandardProvider && !provider) {
      setProvider(walletStandardProvider as any)
    }
  }, [walletStandardProvider, provider])

  const unisatProvider = useUnisatProvider()
  useEffect(() => {
    if (unisatProvider && !provider && walletName === WALLET_NAME.unisat) {
      setProvider(unisatProvider)
    }
  }, [unisatProvider, provider, walletName])

  const leatherProvider = useLeatherProvider()
  useEffect(() => {
    if (leatherProvider && !provider && walletName === WALLET_NAME.leather) {
      setProvider(leatherProvider)
    }
  }, [leatherProvider, provider, walletName])

  const okxProvder = useOkxProvider()
  useEffect(() => {
    if (okxProvder && !provider && walletName === WALLET_NAME.okx) {
      setProvider(okxProvder)
    }
  }, [okxProvder, provider, walletName])

  const xverseProvider = getXverseProvider()
  useEffect(() => {
    if (walletName && !provider) {
      switch (walletName) {
        case WALLET_NAME.magicEden:
          if (window.BitcoinProvider && !(window.BitcoinProvider as any).isMagicEden) {
            setProvider(window.BitcoinProvider)
          }
          break
        case WALLET_NAME.xverse:
        case WALLET_NAME.default:
          if ((window as any).XverseProviders?.BitcoinProvider) {
            setProvider((window as any).XverseProviders?.BitcoinProvider)
          }
          break
      }
    }
  }, [walletName, provider, xverseProvider])

  const isReady =
    paymentAddress !== undefined &&
    ordinalsAddress !== undefined &&
    runesAddress !== undefined &&
    !!walletName
  const isConnected = !!provider && isReady

  const disconnectWallet = async () => {
    if (isCustomBitcoinProvider(provider)) {
      await provider.disconnect()
    }

    setPaymentAddress(undefined)
    setOrdinalsAddress(undefined)
    setRunesAddress(undefined)
    setProvider(undefined)
    setWalletName(undefined)
    setBtcBalances(undefined)
  }

  const toggleNetwork = () => {
    setNetwork(network)
    disconnectWallet()
  }

  const connectWallet = async (_provider: BitcoinProvider, walletName: WalletName) => {
    function addressItemToAddressDetails(addressItem: Address | undefined) {
      return getAddressDetails({ walletName, addressItem })
    }

    await getAddress({
      getProvider: async () => _provider,
      payload: {
        purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
        message: 'Welcome to Mystic - a Rune marketplace.',
        network: {
          type: network,
        },
      },
      onFinish: (response) => {
        const paymentAddressItem = response.addresses.find(
          (address) => address.purpose === AddressPurpose.Payment,
        )
        const _paymentAddress = addressItemToAddressDetails(paymentAddressItem)
        setPaymentAddress(_paymentAddress)

        const ordinalsAddressItem = response.addresses.find(
          (address) => address.purpose === AddressPurpose.Ordinals,
        )
        const _ordinalsAddress = addressItemToAddressDetails(ordinalsAddressItem)
        setOrdinalsAddress(_ordinalsAddress)
        // set rune address to ordinals address for now
        setRunesAddress(_ordinalsAddress)
        setProvider(_provider)
        setWalletName(walletName)
      },
      onCancel: () => alert('Request canceled'),
    })
  }

  const _signMessage = async (
    message: string,
    addressPurpose: AddressPurpose,
  ): Promise<SignMessageResult> => {
    return new Promise((resolve) => {
      const address = addressPurpose === AddressPurpose.Payment ? paymentAddress : ordinalsAddress
      if (!address) {
        resolve({
          success: false,
          error: 'Not connected, address not available',
        })
        return
      }

      signMessage({
        getProvider: async () => provider,
        payload: {
          network: {
            type: network,
          },
          address: address.addrString,
          message,
        },
        onFinish: (signedMessage) => {
          resolve({ success: true, signedMessage })
        },
        onCancel: () => {
          resolve({ success: false, error: 'Signing message canceled' })
        },
      }).catch((error) => {
        resolve({ success: false, error: error.toString() })
      })
    })
  }

  const _signTransaction = async (
    payload: SignTransactionPayload,
  ): Promise<SignTransactionResult> => {
    return new Promise((resolve) => {
      signTransaction({
        getProvider: async () => provider,
        payload,
        onFinish: (response) => {
          resolve({
            success: true,
            signedPsbtBase64: response.psbtBase64,
            txId: response.txId,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((e) => {
        resolve({
          success: false,
          error: e.message,
        })
      })
    })
  }

  const _mintRune = async ({
    runeId,
    runeName,
    recipientAddress,
    fee,
  }: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    const tx = await buildMintTx({
      paymentAddress: paymentAddress,
      recipientAddress,
      runeId,
      network: btcSignerNetwork,
      fee,
    })

    if (!tx) {
      throw new Error('Failed to create transaction')
    }

    const indicesToSign = []
    for (let i = 0; i < tx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to mint ${runeName} Runes`,
      psbtBase64: base64.encode(tx.toPSBT()),
    })

    if (!result.success) {
      throw new Error('Failed to mint Runes')
    }

    return result
  }

  const _estimateMintBulkRuneVsize = async ({
    runeId,
    recipientAddress,
    fee,
    estimatedTotalNetworkFee,
    childEstimatedVsize,
    quantity,
  }: {
    runeId: string
    recipientAddress: string
    fee: bigint
    estimatedTotalNetworkFee: bigint
    childEstimatedVsize: bigint
    quantity: bigint
  }): Promise<{ parent: number; child: number }> => {
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    const bulk = await buildBulkMintTxs({
      paymentAddress: paymentAddress,
      recipientAddress,
      runeId,
      network: btcSignerNetwork,
      estimatedTotalNetworkFee,
      childEstimatedVsize,
      fee,
      quantity,
    })

    return { parent: bulk.parentTx.estimatedVsize, child: bulk.childTxs[0].estimatedP2TRVsize }
  }

  const _mintBulkRune = async ({
    runeId,
    runeName,
    recipientAddress,
    fee,
    estimatedTotalNetworkFee,
    childEstimatedVsize,
    quantity,
    onStatusChange,
  }: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
    estimatedTotalNetworkFee: bigint
    childEstimatedVsize: bigint
    quantity: bigint
    onStatusChange(status: BulkMintSignTransactionStatus): void
  }): Promise<SignMultipleTransactionsResult> => {
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    onStatusChange('build')
    const bulk = await buildBulkMintTxs({
      paymentAddress: paymentAddress,
      recipientAddress,
      runeId,
      network: btcSignerNetwork,
      fee,
      estimatedTotalNetworkFee,
      childEstimatedVsize,
      quantity,
    })

    const indicesToSign = []
    for (let i = 0; i < bulk.parentTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    onStatusChange('wallet-prompt')
    const result = await _signTransaction({
      broadcast: false,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to prepare bulk mint of ${runeName}`,
      psbtBase64: base64.encode(bulk.parentTx.toPSBT()),
    })

    if (!result.success) {
      console.warn('Failed to mint runes', result.error)
      return result
    }

    onStatusChange('temp-key-sign')
    const worker = new BulkMintWebWorker()
    function signBulkMintTxsInWorker(bulk: BuildBulkMintResult): Promise<SignBulkMintResult> {
      return new Promise((resolve, reject) => {
        worker.onmessage = (event: MessageEvent) => {
          const serializedResult: SignBulkMintSerializedResult = event.data
          const response: SignBulkMintResult = {
            ...serializedResult,
            parentTx: btc.Transaction.fromPSBT(base64.decode(serializedResult.parentTxBase64)),
            childTxs: serializedResult.childTxsBase64.map((tx) =>
              btc.Transaction.fromPSBT(base64.decode(tx)),
            ),
          }
          resolve(response)
        }
        worker.onerror = (error: ErrorEvent) => {
          console.error('Worker error:', error)
          reject(new Error(`Worker error: ${error.message}`))
        }

        const bulkSerialized: BuildBulkMintSerializedResult = {
          ...bulk,
          parentTxSignedBase64: (result as SignTransactionSuccessResult).signedPsbtBase64,
          childTxsBase64: bulk.childTxs.map((tx) => base64.encode(tx.toPSBT())),
        }
        worker.postMessage({
          action: 'signBulkMintTxs',
          bulk: bulkSerialized,
        })
      })
    }

    const signed = await signBulkMintTxsInWorker(bulk)

    console.log('final vsizes', signed.parentTx.vsize, signed.childTxs[0].vsize)

    const splitterPsbtBase64 = base64.encode(signed.parentTx.toPSBT())
    const mintsSubmission: MintsSubmission = {
      splitterSignerAddress: paymentAddress.addrString,
      minterSignerAddress: bulk.tempKeyAddress,
      splitterPsbtBase64,
      mintPsbtsBase64: signed.childTxs.map((tx) => base64.encode(tx.toPSBT())),
      runeId,
      quantity,
    }

    onStatusChange('api-submit')
    const response = await apiFetch<MintsSubmissionResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.mints.submitMints, { runeName }),
      undefined,
      {
        body: mintsSubmission,
        method: 'POST',
      },
    )

    if (!!response.error || !response.splitterTxId) {
      console.error('Failed to mint Runes', response.error ?? 'No error', response.splitterTxId)
      return {
        success: false,
        error: response.error ?? 'Failed to mint Runes',
      }
    }

    const results: SignTransactionResult[] = [
      {
        txId: response.splitterTxId,
        success: true,
        signedPsbtBase64: splitterPsbtBase64,
      },
    ]

    for (const txId of response.mintTxIds ?? []) {
      results.push({
        success: true,
        txId,
        signedPsbtBase64: '',
      })
    }
    for (const error of response.mintErrors ?? []) {
      results.push({
        success: false,
        error: error[1],
      })
    }

    return { success: true, signedTransactionResults: results }
  }

  const _sellRunePsbt = async ({
    proceeds,
    runeId,
    runeUtxo,
  }: {
    runeId: string
    proceeds: bigint
    runeUtxo: RuneUtxoOutpoint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Payment or Rune address not available')
    }

    const tx = await buildSellPsbt({
      proceedsAddress: paymentAddress,
      proceedsAmount: proceeds,
      runeAddress: runesAddress,
      runeId,
      runeUtxo,
    })

    if (!tx) {
      throw new Error('Failed to create transaction')
    }

    const result = await _signTransaction({
      broadcast: false,
      inputsToSign: [
        {
          address: runesAddress.addrString,
          signingIndexes: [0],
          sigHash: btc.SigHash.SINGLE_ANYONECANPAY,
        },
      ],
      network: {
        type: network,
      },
      message: 'Sign to sell Runes',
      psbtBase64: base64.encode(tx.toPSBT()),
    })

    if (!result.success) {
      throw new Error('Failed to create Sell Psbt')
    }

    console.log('psbt', result.signedPsbtBase64)
    console.log('txid', result.txId)

    return result
  }

  const _commitEtchRune = async ({
    etchParams,
    fee,
  }: {
    etchParams: JsEtchingParams
    fee: bigint
  }): Promise<CommitEtchRuneResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Payment or Rune address not available')
    }

    const etchingCommit = await buildEtchingCommit({
      paymentAddress: paymentAddress,
      recipientAddress: runesAddress,
      network: btcSignerNetwork,
      fee,
      params: etchParams,
    })

    if (!etchingCommit.commitTx) {
      throw new Error('Failed to create commit transaction')
    }

    const indicesToSign = []
    for (let i = 0; i < etchingCommit.commitTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to commit ${etchParams.rune} Etching`,
      psbtBase64: base64.encode(etchingCommit.commitTx.toPSBT()),
    })

    if (!result.success) {
      throw new Error(`Failed to commit ${etchParams.rune} Etching`)
    }

    console.log('psbt', result.signedPsbtBase64)
    console.log('txid', result.txId)

    const tx = btc.Transaction.fromPSBT(base64.decode(result.signedPsbtBase64))

    return {
      commitTxResult: result,
      revealAmount: etchingCommit.revealAmount,
      txId: result.txId!,
      revealScript: etchingCommit.revealScript,
      revealKey: etchingCommit.revealKey,
      commitTx: tx,
    }
  }

  const _revealEtchRune = async ({
    etchParams,
    etchingCommit,
    fee,
  }: {
    etchParams: JsEtchingParams
    etchingCommit: EtchingCommit
    fee: bigint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Payment or Rune address not available')
    }

    const etchingReveal = await buildEtchingReveal({
      paymentAddress: paymentAddress,
      recipientAddress: runesAddress,
      network: btcSignerNetwork,
      fee,
      params: etchParams,
      etchingCommit: etchingCommit,
    })

    if (!etchingReveal.revealTx) {
      throw new Error('Failed to create reveal transaction')
    }

    const indicesToSign = []
    for (let i = 0; i < etchingReveal.revealTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: runesAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to reveal ${etchParams.rune} Etching`,
      psbtBase64: base64.encode(etchingReveal.revealTx.toPSBT()),
    })

    if (!result.success) {
      throw new Error(`Failed to reveal ${etchParams.rune} Etching`)
    }

    console.log('psbt', result.signedPsbtBase64)
    console.log('txid', result.txId)

    return result
  }

  const _signMultipleTransactions = async (
    payload: SignMultipleTransactionsPayload,
  ): Promise<SignMultipleTransactionsResult> => {
    return new Promise((resolve) => {
      signMultipleTransactions({
        getProvider: async () => provider,
        payload,
        onFinish: (response) => {
          resolve({
            success: true,
            signedTransactionResults: response.map((result) => ({
              success: true,
              signedPsbtBase64: result.psbtBase64,
              txId: result.txId,
            })),
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((e) => {
        resolve({
          success: false,
          error: e.message,
        })
      })
    })
  }

  const sendBitcoin = async (recipients: Recipient[]): Promise<SendBitcoinResult> => {
    return new Promise((resolve) => {
      sendBtcTransaction({
        getProvider: async () => provider,
        payload: {
          network: {
            type: network,
          },
          recipients,
          senderAddress: paymentAddress!.addrString,
        },
        onFinish: (response) => {
          resolve({
            success: true,
            txId: response,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((e) => {
        resolve({
          success: false,
          error: e.message,
        })
      })
    })
  }

  return (
    <WalletContext.Provider
      value={{
        btcBalances,
        btcPrice,
        recommendedNetworkFees,
        blockTip,

        paymentAddress,
        ordinalsAddress,
        runesAddress,
        walletName: walletName,
        isConnected,
        network,
        capabilities,
        capabilityState,
        isReady,
        toggleNetwork,
        connectWallet,
        disconnectWallet,
        signMessage: _signMessage,
        signTransaction: _signTransaction,
        signMultipleTransactions: _signMultipleTransactions,
        sendBitcoin,
        commitEtchRune: _commitEtchRune,
        revealEtchRune: _revealEtchRune,
        mintRune: _mintRune,
        mintBulkRune: _mintBulkRune,
        estimateMintBulkRuneVsize: _estimateMintBulkRuneVsize,
        sellRunePsbt: _sellRunePsbt,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}
