/* eslint-disable @typescript-eslint/quotes */
import { styled } from 'styled-components'
import { useState } from 'react'

import { BREAKPOINTS } from 'src/shared/constants'
// import { RunesRecentOrders } from 'src/orders'
import { MintSearch, PopularMintsTable } from 'src/minting'
import { LargeButtonGroup } from 'src/shared/components'
import { LatestEtchingsTable } from 'src/etching'

import { PageContainer } from './PageContainer'

const HOME_PAGE_TABS = {
  popularMints: 'Popular Mints',
  latest: 'Latest',
}

const tabOptions = Object.values(HOME_PAGE_TABS).map((value) => ({
  value: value,
  label: value,
}))

export function HomePage() {
  const [selectedTab, setSelectedTab] = useState(HOME_PAGE_TABS.popularMints)

  let tabbedContent
  if (selectedTab === HOME_PAGE_TABS.popularMints) {
    tabbedContent = <PopularMintsTableWrapper />
  } else if (selectedTab === HOME_PAGE_TABS.latest) {
    tabbedContent = <LatestEtchingsTable />
  }

  function handleSelectedTabOnChange(value: string) {
    setSelectedTab(value)
  }

  return (
    <PageContainer>
      <MintSearch />

      <LargeButtonGroupWrapper
        options={tabOptions}
        value={selectedTab}
        onChange={handleSelectedTabOnChange}
        exclusive
        updateSearchParams
      />
      {tabbedContent}

      {/* <Header>Recent Orders</Header>
      <Cards>
        <RunesRecentOrders />
      </Cards> */}
    </PageContainer>
  )
}

const SwapContainer = styled.div`
  padding-bottom: 100px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 20px;
  }
`

const SwapContent = styled.div`
  float: right;
  width: fit-content;
  padding-right: 100px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-right: 0px;
    float: initial;
  }
`

const PopularMintsTableWrapper = styled(PopularMintsTable)`
  div:nth-child(1) {
    padding-top: 0px;
  }
`

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 10px;
  }
`
