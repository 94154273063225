import { styled } from 'styled-components'
import { BigNumberInput } from 'big-number-input'
import { FormHelperText } from '@mui/material'

import { bigIntToInteger } from '@packages/utils'

import { COLORS, FONTS } from 'src/shared/constants'

interface Props {
  value: bigint
  onChange: (val: bigint) => void
  decimals: bigint | number
  error?: boolean
  className?: string
  helperText?: string
}

export function BigIntInput({
  value,
  onChange,
  decimals,
  error = false,
  helperText,
  className,
}: Props) {
  function handleChange(strValue: string) {
    onChange(strValue === '' ? BigInt(0) : BigInt(strValue))
  }

  return (
    <Container error={error} className={className}>
      <BigNumberInput
        decimals={typeof decimals === 'bigint' ? bigIntToInteger(decimals) : decimals}
        onChange={handleChange}
        value={value.toString()}
        placeholder='0'
      />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </Container>
  )
}

const Container = styled.div<{ error?: boolean }>`
  width: 100%;
  p {
    line-height: 15px;
    padding: 10px 0px;
    margin: 0px;
  }
  input {
    width: 100%;
    border: 1px solid ${({ error }) => (error ? COLORS.negative : COLORS.white)};
    border-radius: 8px;
    background-color: ${COLORS.inputs};
    font-family: ${FONTS.text};
    color: ${COLORS.white};
    padding: 14px;
    border-radius: 8px;
    width: 100%;
    background-color: ${COLORS.inputs};
  }

  .MuiFormHelperText-root {
    font-family: ${FONTS.text};
    font-size: 15px;
  }
`
