import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import { BatchMint, BatchMintStatus } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { HelpTooltip, VirtualizedTable } from 'src/shared/components'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { RuneDetailsLink } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { TransactionExternalLink } from 'src/web3'
import { COLORS } from 'src/shared/constants'

interface Props {
  paymentAddress: string
}

interface ChildStatusCountsMap {
  [batchId: string]: {
    [batchMintStatus in BatchMintStatus]: number
  }
}

export function BatchMintsTable({ paymentAddress }: Props) {
  const isMobile = useIsMobile()
  const [mintStatusCounts, setMintStatusCounts] = useState<ChildStatusCountsMap>({})

  const {
    paginatedData: batchMints,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<BatchMint>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.mints.batchByAddress, {
      address: paymentAddress,
    }),
    limit: isMobile ? 20 : 40,
  })

  useEffect(() => {
    batchMints.flat().map((batchMint) => {
      const statusCounts: { [status in BatchMintStatus]: number } = {
        minted: 0,
        pending: 0,
        failed: 0,
      }

      batchMint.childTxs.forEach((child) => {
        statusCounts[child.status]++
      })
      setMintStatusCounts((prev) => ({
        ...prev,
        [batchMint.splitterTxId]: statusCounts,
      }))
    })
  }, [batchMints])

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'runeName',
            label: 'Token',
            width: isMobile ? 40 : 30,
            formatter: ({ data: mint }) => <RuneDetailsLink rune={mint} />,
          },
          {
            dataKey: 'quantity',
            label: 'Mints',
            width: isMobile ? 30 : 15,
          },
          {
            dataKey: 'pending',
            label: 'Pending',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => {
              if (!mintStatusCounts[mint.splitterTxId]?.pending) return <ZeroCountStatus />

              return (
                <StatusType color={COLORS.white}>
                  {mintStatusCounts[mint.splitterTxId]?.pending}
                  <LoadingIcon />
                </StatusType>
              )
            },
          },
          {
            dataKey: 'minted',
            label: 'Minted',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => {
              if (!mintStatusCounts[mint.splitterTxId]?.minted) return <ZeroCountStatus />

              return (
                <StatusType color={COLORS.positive}>
                  {mintStatusCounts[mint.splitterTxId]?.minted}
                </StatusType>
              )
            },
          },
          {
            dataKey: 'failed',
            label: 'Failed',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => {
              if (!mintStatusCounts[mint.splitterTxId]?.failed) return <ZeroCountStatus />
              return (
                <StatusType color={COLORS.negative}>
                  {mintStatusCounts[mint.splitterTxId]?.failed}
                </StatusType>
              )
            },
          },
          {
            dataKey: 'status',
            label: 'Status',
            width: 30,
            hideOnDesktop: true,
            formatter: ({ data: mint }) => {
              const statuses = mintStatusCounts[mint.splitterTxId]
              if (statuses?.pending > 0) {
                return (
                  <StatusType color={COLORS.white}>
                    Pending
                    <LoadingIcon />
                  </StatusType>
                )
              }
              if (statuses?.failed > 0) {
                return <StatusType color={COLORS.negative}>Failed</StatusType>
              }
              return <StatusType color={COLORS.positive}>Minted</StatusType>
            },
          },
          {
            dataKey: 'mintedAtTimestamp',
            label: 'Date',
            width: 25,
            hideOnMobile: true,
            formatter: ({ data: mint }) => unixTimestampToDateString(mint.mintedAtTimestamp, true),
          },
          {
            dataKey: 'splitterTxId',
            label: 'Splitter Tx',
            disableSort: true,
            content: (
              <SplitterTxLabel>
                Splitter Tx{' '}
                <HelpTooltip content='This transaction was used to split your BTC in order to fund the minting transactions' />
              </SplitterTxLabel>
            ),
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => (
              <TransactionExternalLink transactionId={mint.splitterTxId} />
            ),
          },
        ]}
        paginatedData={batchMints}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 30 : 35}
        viewableRows={10}
      />
    </Container>
  )
}

const Container = styled.div``

const StatusType = styled.div<{ color: string }>`
  font-weight: 700;
  color: ${({ color }) => color} !important;
  display: flex;
  gap: 5px;
  align-items: center;
`

const SplitterTxLabel = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

function ZeroCountStatus() {
  return <StatusType color={COLORS.white}>-</StatusType>
}

const LoadingIcon = styled(CircularProgress)`
  color: ${COLORS.white};
  width: 13px !important;
  height: 13px !important;
  animation-duration: 2.8s;

  svg {
    circle {
      animation-duration: 2.8s;
    }
  }
`
