import { styled } from 'styled-components'
import { useEffect, useMemo, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import Skeleton from '@mui/material/Skeleton'

import { RuneDetails } from '@packages/interfaces'
import { calculateFeeFromBps, formatBtc } from '@packages/utils'

import {
  Button,
  Drawer,
  DrawerCard,
  ErrorMessage,
  ErrorToastProps,
  NumberInputWithButtons,
  TextInput,
  WarningMessage,
  HeaderText,
  Slider,
  LinkPageToastProps,
  HelpTooltip,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, SuccessfulTransactionToastProps } from 'src/web3'
import { formatRuneName, formatRunesAmount } from 'src/runes'
import { WalletPickerMenu, useWalletContext, SignTransactionResult } from 'src/wallet'
import { SATOSHI_DUST_THRESHOLD, SERVICE_FEE_BPS, TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'
import { hexToRgb } from 'src/shared/utils'
import { buildAccountDetailsUrl } from 'src/pages'
import { ACCOUNT_DETAILS_TABS } from 'src/account'
import { useDebounce } from 'src/shared/hooks'

import { useMintDetails } from '../hooks'

const MAX_MINTS = 2300

function transactionToast(result: SignTransactionResult) {
  if (result?.success) {
    const toastProps: SuccessfulTransactionToastProps = {
      key: result.txId ?? Date.now().toString(),
      message: 'Mint successful!',
      variant: 'successfulTransaction',
      transactionId: result.txId ?? '',
      autoHideDuration: 30 * 1000,
    }
    enqueueSnackbar(toastProps as any)
  } else {
    console.error(result)
    const toastProps: ErrorToastProps = {
      key: result.error + Date.now(),
      errorMessage: result.error,
      variant: 'error',
      autoHideDuration: 30 * 1000,
    }
    enqueueSnackbar(toastProps as any)
  }
}

interface Props {
  isOpen: boolean
  onClose: () => void
  runeDetails: RuneDetails
}

export function MintRunesDrawer({ isOpen, onClose, runeDetails }: Props) {
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const { mintRune, mintBulkRune, estimateMintBulkRuneVsize, isConnected, runesAddress } =
    useWalletContext()

  const mintDetails = useMintDetails({ runeDetails })

  const [numberOfMints, setNumberOfMints] = useState(1)
  const [numberOfMintsError, setNumberOfMintsError] = useState<string | undefined>()
  const [recipientAddress, setRecipientAddress] = useState('')
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [totals, setTotals] = useState<{
    networkFeeTotal: bigint
    serviceFeeTotal: bigint
    mintUtxosTotal: bigint
    satsTotal: bigint
    childEstimatedVsize: bigint
  }>()
  const [error, setError] = useState<string>()
  const [loadingText, setLoadingText] = useState<string>()
  const [loadingTotalForQuantity, setLoadingTotalForQuantity] = useState<number[]>([])

  useEffect(() => {
    if (runesAddress) {
      setRecipientAddress(runesAddress.addrString)
    }
  }, [runesAddress])

  useDebounce(
    async () => {
      if (!selectedNetworkFee || !recipientAddress) return
      try {
        setLoadingTotalForQuantity((prev) => [...prev, numberOfMints])
        const { parent: parentEstimatedVsize, child: childEstimatedVsize } =
          await estimateMintBulkRuneVsize({
            runeId: runeDetails.runeId,
            fee: BigInt(selectedNetworkFee),
            recipientAddress,
            estimatedTotalNetworkFee: totals?.networkFeeTotal ?? 0n,
            childEstimatedVsize: totals?.childEstimatedVsize ?? BigInt(TRX_VIRTUAL_SIZE_BYTES.mint),
            quantity: BigInt(numberOfMints),
          })

        const mintUtxosTotal = BigInt(numberOfMints) * SATOSHI_DUST_THRESHOLD
        const networkFeeTotal =
          BigInt(selectedNetworkFee) * BigInt(parentEstimatedVsize) +
          BigInt(selectedNetworkFee) * BigInt(childEstimatedVsize) * BigInt(numberOfMints)
        const serviceFeeTotal = calculateFeeFromBps(networkFeeTotal, SERVICE_FEE_BPS.mint)

        const satsTotal = BigInt(mintUtxosTotal) + serviceFeeTotal + networkFeeTotal
        setTotals({
          serviceFeeTotal,
          mintUtxosTotal,
          networkFeeTotal,
          satsTotal,
          childEstimatedVsize: BigInt(childEstimatedVsize),
        })
      } catch (error) {
        console.error(error)
        setNumberOfMintsError((error as Error).message)
      } finally {
        setLoadingTotalForQuantity((prev) => prev.filter((q) => q !== numberOfMints))
      }
    },
    [numberOfMints, selectedNetworkFee, recipientAddress],
    250,
  )

  const totalMints = useMemo(() => {
    return (runeDetails.runesAmountPerMint ?? 0n) * BigInt(numberOfMints)
  }, [runeDetails.runesAmountPerMint, numberOfMints])

  function resetForm() {
    setNumberOfMints(1)
    setSelectedNetworkFee(0)
    setError(undefined)
    setNumberOfMintsError(undefined)
  }

  async function onConfirmClick() {
    if (!isConnected) {
      setIsWalletModalOpen(true)
      return
    }

    try {
      setLoadingText('Minting...')
      setError(undefined)

      if (numberOfMints === 1) {
        setLoadingText('See Wallet')
        const result = await mintRune({
          runeId: runeDetails.runeId,
          runeName: runeDetails.runeName,
          fee: BigInt(selectedNetworkFee),
          recipientAddress,
        })
        transactionToast(result)
        resetForm()
        onClose()
      } else {
        const result = await mintBulkRune({
          runeId: runeDetails.runeId,
          runeName: runeDetails.runeName,
          fee: BigInt(selectedNetworkFee),
          estimatedTotalNetworkFee: totals?.networkFeeTotal ?? 0n,
          childEstimatedVsize: totals?.childEstimatedVsize ?? BigInt(TRX_VIRTUAL_SIZE_BYTES.mint),
          recipientAddress,
          quantity: BigInt(numberOfMints),
          onStatusChange: (status) => {
            switch (status) {
              case 'build':
                setLoadingText('Building txs')
                break
              case 'wallet-prompt':
                setLoadingText('See Wallet')
                break
              case 'temp-key-sign':
                setLoadingText('Finalizing txs')
                break
              case 'api-submit':
                setLoadingText('Submitting txs')
                break
            }
          },
        })

        if (result.success) {
          const anyErrors = result.signedTransactionResults.some((r) => !r.success)
          if (anyErrors) {
            const toastProps: LinkPageToastProps = {
              key: `${Date.now()}`,
              variant: 'linkPage',
              message: 'Bulk mint submitted. Some broadcasts failed, but will be retried',
              buttonMessage: 'View status',
              reactRouterPathname: buildAccountDetailsUrl(runesAddress?.addrString ?? ''),
              reactRouterSearch: `?tab=${ACCOUNT_DETAILS_TABS.mints}`,
              autoHideDuration: 30 * 1000,
              styleVariant: 'warning',
            }
            enqueueSnackbar(toastProps as any)
          } else {
            const toastProps: LinkPageToastProps = {
              key: `${Date.now()}`,
              variant: 'linkPage',
              message: 'Bulk mint submitted',
              buttonMessage: 'View status',
              reactRouterPathname: buildAccountDetailsUrl(runesAddress?.addrString ?? ''),
              reactRouterSearch: `?tab=${ACCOUNT_DETAILS_TABS.mints}`,
              autoHideDuration: 30 * 1000,
            }
            enqueueSnackbar(toastProps as any)
          }

          resetForm()
          onClose()
        } else {
          setError('Minting failed: ' + result.error)
        }
      }
    } catch (error: any) {
      console.error(error)
      setError(error.message)
    } finally {
      setLoadingText(undefined)
    }
  }

  function onNumberOfMintsChange(mints: number) {
    setNumberOfMintsError(undefined)
    if (mints + Number(runeDetails.numberOfMints ?? 0n) > Number(runeDetails.maxMints ?? 0n)) {
      setNumberOfMintsError('You are trying to mint more times than the remaining mints.')
    }
    setNumberOfMints(mints)
  }

  function onModalClose() {
    resetForm()
    onClose()
  }

  const buttonText = loadingText ?? 'Confirm Mint'
  const isTotalsLoading = loadingTotalForQuantity.length > 0

  return (
    <Drawer isOpen={isOpen} onClose={onModalClose} canClose={!loadingText}>
      <Container>
        <HeaderText>Minting {formatRuneName(runeDetails)}</HeaderText>

        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Mint Amount</BoldColumn>
            <Column>
              {formatRunesAmount({
                rune: runeDetails,
                runesAmount: runeDetails.runesAmountPerMint ?? 0n,
              })}
            </Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <BoldColumn>
              <Header>Repeat Mints</Header>
              {numberOfMints > 1 ? (
                <SubHeader>
                  You will be prompted to sign 1 transaction that will split your BTC to be used for
                  the minting transactions
                </SubHeader>
              ) : (
                <SubHeader>You will be prompted to sign 1 transaction</SubHeader>
              )}
            </BoldColumn>
            <Column>
              <NumberInputWithButtons
                onChange={onNumberOfMintsChange}
                value={numberOfMints}
                minValue={1}
                maxValue={MAX_MINTS}
              />
            </Column>
          </Row>
          <Row>
            <Slider
              spacing={1}
              value={numberOfMints}
              onChange={onNumberOfMintsChange}
              min={1}
              max={MAX_MINTS}
            />
          </Row>
          <Row> {numberOfMintsError && <ErrorMessageWrapper message={numberOfMintsError} />}</Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Total Mints</BoldColumn>
            <Column>{formatRunesAmount({ rune: runeDetails, runesAmount: totalMints })}</Column>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Recipient Address</BoldColumn>
          </Row>
          <Row>
            <TextInput
              value={recipientAddress}
              onChange={setRecipientAddress}
              error={!recipientAddress}
              helperText={!recipientAddress ? 'Required' : undefined}
            />
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <NetworkFeeSelector
              selectedFee={selectedNetworkFee}
              onChange={(fee) => setSelectedNetworkFee(fee)}
              onlyAllowFastestFee
              addFastestFeeBuffer={numberOfMints > 24}
            />
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <BoldColumnWithTooltip>
              Mint UTXOs{' '}
              <HelpTooltip content='This is the minimum amount required in a UTXO that will hold a runestone multiplied by the selected number of mints' />
            </BoldColumnWithTooltip>
            <TotalColumn>
              {isTotalsLoading ? (
                <SkeletonWrapper />
              ) : (
                `${formatBtc(totals?.mintUtxosTotal ?? 0n)} BTC`
              )}
            </TotalColumn>
          </Row>
          <Row>
            <BoldColumnWithTooltip>
              Total Network Fees{' '}
              <HelpTooltip content='This includes all of the network fees that will be used to broadcast the mint transactions once the splitter tx has been included in a block' />
            </BoldColumnWithTooltip>
            <TotalColumn>
              {isTotalsLoading ? (
                <SkeletonWrapper />
              ) : (
                `≈ ${formatBtc(totals?.networkFeeTotal ?? 0n)} BTC`
              )}
            </TotalColumn>
          </Row>
          <Row>
            <BoldColumn>Service Fee</BoldColumn>
            <TotalColumn>
              {isTotalsLoading ? (
                <SkeletonWrapper />
              ) : (
                `${formatBtc(totals?.serviceFeeTotal ?? 0n)} BTC`
              )}
            </TotalColumn>
          </Row>
          <Row>
            <BoldColumn>Total</BoldColumn>
            <TotalColumn>
              {isTotalsLoading ? <SkeletonWrapper /> : `${formatBtc(totals?.satsTotal ?? 0n)} BTC`}
            </TotalColumn>
          </Row>
        </DrawerCardWrapper>
        {(mintDetails?.mintedPercent ?? 0) >= 85 && (
          <DrawerCardWrapper>
            <Row>
              <Column>
                <WarningMessage message='Warning: this rune is almost minted out and your transaction may fail. If the mint closes before your transaction confirms your BTC will still be lost and spent as network fees.' />
              </Column>
            </Row>
          </DrawerCardWrapper>
        )}
        {/* <DrawerCardWrapper>
          <Row>
            <BoldColumn>Total Cost</BoldColumn>
            <Column>{btcTotal} BTC</Column>
          </Row>
        </DrawerCardWrapper> */}
        {error && <ErrorMessage message={error} />}
        <Buttons>
          {!loadingText && <CancelButton onClick={onClose}>Cancel</CancelButton>}
          <ConfirmButton
            onClick={onConfirmClick}
            loading={!!loadingText}
            disabled={isTotalsLoading || !!numberOfMintsError}
          >
            {buttonText}
          </ConfirmButton>
        </Buttons>
      </Container>
      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
    </Drawer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const DrawerCardWrapper = styled(DrawerCard)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`

const CancelButton = styled(Button)`
  color: ${COLORS.white};
  background-color: ${COLORS.background.container};

  &:hover {
    background-color: ${hexToRgb(COLORS.black, 0.8)};
  }
`

const ConfirmButton = styled(Button)``

const Row = styled.div`
  display: flex;
`

const BoldColumn = styled.div`
  font-weight: 500;
  flex: 1;
`

const BoldColumnWithTooltip = styled(BoldColumn)`
  display: flex;
  align-items: center;
  gap: 5px;
`

const Column = styled.div`
  flex: 1;
  text-align: center;
`

const TotalColumn = styled(Column)`
  text-align: right;
`

const Header = styled.div``
const SubHeader = styled.div`
  font-weight: 300;
  font-size: 14px;
`

const ErrorMessageWrapper = styled(ErrorMessage)`
  width: 100%;
`

const SkeletonWrapper = styled(Skeleton)`
  border-radius: 5px;
  height: 30px;
  background-color: ${COLORS.background.container};
`
