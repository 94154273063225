import { formatUnits } from 'ethers'

import { Rune } from '@packages/interfaces'

export function formatNumber({
  numStr,
  useCompactNotation = false,
}: {
  numStr: string
  useCompactNotation?: boolean
}) {
  let integerLength = 0
  let decimalLength = 0
  if (numStr.includes('e')) {
    const parts = numStr.split('e')
    integerLength = parts[0].length - 1 + Number(parts[1])
  } else if (numStr.includes('.')) {
    const parts = numStr.split('.')
    integerLength = parts[0].length
    decimalLength = parts[1].length
  } else {
    integerLength = numStr.length
  }

  let notation: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined =
    useCompactNotation ? 'compact' : 'standard'
  let maximumFractionDigits = 0
  if (integerLength >= 20) {
    notation = 'scientific'
  } else if (integerLength >= 10) {
    notation = 'compact'
  } else if (integerLength >= 3) {
    maximumFractionDigits = 0
  } else if (integerLength >= 2) {
    maximumFractionDigits = 1
  } else if (integerLength >= 1) {
    maximumFractionDigits = 2
  } else if (integerLength === 0) {
    if (decimalLength >= 6) maximumFractionDigits = 8
  }

  return new Intl.NumberFormat('en-US', {
    notation,
    maximumFractionDigits,
  }).format(Number(numStr))
}

export function formatRunesAmount({
  rune,
  runesAmount,
  displayShortFormat = false,
  displayRuneSymbol = false,
}: {
  rune: Rune
  runesAmount: bigint
  displayShortFormat?: boolean
  displayRuneSymbol?: boolean
}): string {
  const formattedString = formatUnits(runesAmount, rune.runeDecimals)
  return `${displayRuneSymbol ? rune.runeSymbolChar ?? '¤' : ''}${
    runesAmount !== BigInt(0)
      ? formatNumber({ numStr: formattedString, useCompactNotation: displayShortFormat })
      : 0
  }`
}

export function formatRuneName(rune: {
  runeName: string
  runeNameSpacerIndices?: bigint[]
}): string {
  return `${
    rune.runeNameSpacerIndices
      ? insertSpacers(rune.runeName, rune.runeNameSpacerIndices)
      : rune.runeName
  }`
}

function insertSpacers(runeName: string, spacerIndices: bigint[]) {
  let result = runeName
  let offset = 0
  for (const bigIntIndex of spacerIndices) {
    const index = Number(bigIntIndex)
    result = result.substring(0, index + offset) + '•' + result.substring(index + offset)
    offset++
  }
  return result
}

export function normalizeRuneName(runeName: string): string {
  return runeName.toUpperCase().replace(/[^A-Z]/g, '')
}
