import { css, styled } from 'styled-components'
import { useMemo, useState } from 'react'
import Decimal from 'decimal.js-light'

import { Order, RuneDetails } from '@packages/interfaces'
import { formatUsd, satsToBtc } from '@packages/utils'

import {
  Accordion,
  Button,
  Drawer,
  DrawerCard,
  ErrorMessage,
  TieredTableCell,
  VirtualizedTable,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, useEstimatedTotalNetworkFee } from 'src/web3'
import { useWalletContext } from 'src/wallet'
import { formatRunesAmount } from 'src/runes'
import { hexToRgb } from 'src/shared/utils'
import { useBtcBalance } from 'src/wallet/hooks'
import { TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'

import { formatPriceSymbol } from '../utils'

interface Props {
  isOpen: boolean
  onClose: () => void
  runeDetails: RuneDetails
  orders: Order[]
}

export function BuyOrdersDrawer({ isOpen, onClose, orders, runeDetails }: Props) {
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [error, setError] = useState<string | null>(null)

  async function onConfirmClick() {
    try {
      setError(null)
      console.log(orders)
      // TODO: Implement buy orders
    } catch (error: any) {
      setError(error.message)
    }
  }

  const totalNetworkFee = useEstimatedTotalNetworkFee({
    satsVB: selectedNetworkFee,
    txBytes: TRX_VIRTUAL_SIZE_BYTES.buy * orders.length,
  })
  const { btcPrice } = useWalletContext()
  const { btcBalanceUsd, btcBalance } = useBtcBalance()

  const totals = useMemo(() => {
    const btcPriceUsd = btcPrice?.btcPriceUsd ?? 0
    const satPriceUsd = btcPrice?.satPriceUsd ?? 0
    const totals = orders
      .map((order) => {
        return {
          satsTotal: order.satsAmount,
          runesAmountTotal: order.runesAmount,
          marketplaceFeeAmountSatsTotal: Number(order.marketplaceFeeAmountSats ?? 0n),
        }
      })
      .reduce(
        (acc, curr) => {
          return {
            satsTotal: acc.satsTotal + curr.satsTotal,
            marketplaceFeeAmountSatsTotal:
              acc.marketplaceFeeAmountSatsTotal + curr.marketplaceFeeAmountSatsTotal,
            runesAmountTotal: acc.runesAmountTotal + curr.runesAmountTotal,
          }
        },
        {
          satsTotal: 0n,
          marketplaceFeeAmountSatsTotal: 0,
          runesAmountTotal: 0n,
        },
      )
    const ordersBtcTotal = satsToBtc(totals.satsTotal)
    const btcTotal = satsToBtc(
      totals.satsTotal +
        BigInt(totalNetworkFee.sats) +
        BigInt(totals.marketplaceFeeAmountSatsTotal),
    )

    return {
      ordersRunesAmountTotal: totals.runesAmountTotal,
      ordersSatsTotal: totals.satsTotal,
      ordersBtcTotal,
      ordersUsdTotal: new Decimal(ordersBtcTotal).mul(btcPriceUsd).toNumber(),
      btcTotal,
      usdTotal: new Decimal(btcTotal).mul(btcPriceUsd).toNumber(),
      marketplaceFeeAmountSatsTotal: totals.marketplaceFeeAmountSatsTotal,
      marketplaceFeeAmountUsdTotal: new Decimal(totals.marketplaceFeeAmountSatsTotal)
        .mul(satPriceUsd)
        .toNumber(),
    }
  }, [totalNetworkFee, btcPrice, orders])

  const notEnoughBalance = btcBalance < totals.btcTotal

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>Confirm Your Purchase</Title>
        <DrawerCardWrapper>
          <Row>
            <Column>Total Runes Amount</Column>
            <BoldColumn></BoldColumn>
            <BoldColumn>
              {formatRunesAmount({ rune: runeDetails, runesAmount: totals.ordersRunesAmountTotal })}{' '}
              {runeDetails.runeSymbolChar}
            </BoldColumn>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Orders Cost</Column>
            <BoldColumn>{totals.ordersBtcTotal} BTC</BoldColumn>
            <BoldColumn>{formatUsd(totals.ordersUsdTotal)} USD</BoldColumn>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Accordion linkText='View Orders'>
            <VirtualizedTableWrapper
              columns={[
                {
                  dataKey: 'runesAmount',
                  label: 'Quantity',
                  formatter: ({ data: order }) =>
                    formatRunesAmount({
                      rune: runeDetails,
                      runesAmount: order.runesAmount,
                      displayShortFormat: true,
                    }),
                },
                {
                  dataKey: 'priceSats',
                  label: `Price (${formatPriceSymbol({ runeDetails })})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell
                      header={order.priceSats}
                      subHeader={formatUsd(Number(order.priceUsd))}
                    />
                  ),
                },
                {
                  dataKey: 'valueSats',
                  label: 'Total (BTC)',
                  formatter: ({ data: order }) => (
                    <TieredTableCell
                      header={`${satsToBtc(order.valueSats)}`}
                      subHeader={formatUsd(Number(order.valueUsd))}
                    />
                  ),
                },
              ]}
              paginatedData={[orders]}
              fetchPage={async () => {}}
              hasNextPage={false}
              viewableRows={orders.length}
              rowHeight={30}
            />
          </Accordion>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <Column>Service Fee</Column>
            <BoldColumn>{`${totals.marketplaceFeeAmountSatsTotal}`} sats</BoldColumn>
            <BoldColumn>{formatUsd(totals.marketplaceFeeAmountUsdTotal)} USD</BoldColumn>
          </Row>
          <Row>
            <Column>Estimated Network Fee</Column>
            <BoldColumn>{`${totalNetworkFee.sats}`} sats</BoldColumn>
            <BoldColumn>{formatUsd(totalNetworkFee.usdValue)} USD</BoldColumn>
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <NetworkFeeSelector
              selectedFee={selectedNetworkFee}
              onChange={(fee) => setSelectedNetworkFee(fee)}
            />
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Total</BoldColumn>
            <BoldColumn>{totals.btcTotal} BTC</BoldColumn>
            <BoldColumn>{formatUsd(totals.usdTotal)} USD</BoldColumn>
          </Row>
          <Row>
            <Column>Available Balance</Column>
            <Column>{btcBalance} BTC</Column>
            <Column>{formatUsd(btcBalanceUsd)} USD</Column>
          </Row>
        </DrawerCardWrapper>
        {notEnoughBalance && <ErrorMessage message={'Not enough BTC'} />}
        {error && <ErrorMessage message={error} />}
        <Buttons>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <ConfirmButton disabled={notEnoughBalance} onClick={onConfirmClick}>
            Confirm Purchase
          </ConfirmButton>
        </Buttons>
      </Container>
    </Drawer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Title = styled.h2`
  font-weight: 500;
`

const DrawerCardWrapper = styled(DrawerCard)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`

const CancelButton = styled(Button)`
  color: ${COLORS.buttons.primary};
  background-color: transparent;
  border: 2px solid ${COLORS.buttons.primary};
`

const ConfirmButton = styled(Button)`
  color: ${COLORS.black};
  background-color: ${COLORS.buttons.primary};
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${hexToRgb(COLORS.buttons.primary, 0.7)};
    `}
`

const Row = styled.div`
  display: flex;
`

const BoldColumn = styled.div`
  font-weight: 500;
  flex: 1;
`

const Column = styled.div`
  flex: 1;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<Order>)`
  tfoot {
    display: none;
  }
`
