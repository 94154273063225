/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Address,
  AddressPurpose,
  BitcoinProvider,
  Params,
  Requests,
  RpcResponse,
} from '@sats-connect/core'

import * as btc from '@packages/scure-btc-signer'

import { hexToBase64 } from 'src/shared/utils'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { CustomBitcoinProvider } from './CustomBitcoinProvider'
import {
  decodeSendBitcoinRequest,
  decodeSignMessageRequest,
  decodeSignTransactionRequest,
} from '../utils'

function hexToBytes(hex: string) {
  return Buffer.from(hex, 'hex')
}

interface LeatherAddress {
  symbol: string
  type?: string
  address: string
  publicKey?: string
  derivationPath?: string
}

interface AddressesResponse {
  jsonrpc: string
  id: string
  result: {
    addresses: LeatherAddress[]
  }
}

interface RPCResponse<T> {
  id: string
  jsonrpc: string
  result: T
}

export const WALLET_TYPES = {
  p2wpkh: 'p2wpkh',
  p2tr: 'p2tr',
}

export class LeatherBitcoinProvider implements BitcoinProvider, CustomBitcoinProvider {
  private leather: any

  public isAvailable() {
    this.setLib()

    return !!this.leather
  }

  public setLib() {
    if (!this.leather && typeof (window as any).LeatherProvider !== 'undefined') {
      this.leather = (window as any).LeatherProvider
    }
  }

  public async connect(_request: string) {
    const response: AddressesResponse = await this.leather.request('getAddresses')
    const formattedAddresses: Address[] = []

    response?.result?.addresses.forEach((address) => {
      if (address.type === WALLET_TYPES.p2tr) {
        formattedAddresses.push({
          address: address.address,
          publicKey: address.publicKey ?? '',
          purpose: AddressPurpose.Ordinals,
        })
      } else if (address.type === WALLET_TYPES.p2wpkh) {
        formattedAddresses.push({
          address: address.address,
          publicKey: address.publicKey ?? '',
          purpose: AddressPurpose.Payment,
        })
      }
    })

    return { addresses: formattedAddresses }
  }

  public async disconnect() {}

  public async signMessage(request: string) {
    const response: RPCResponse<{ address: string; message: string; signature: string }> =
      await this.leather.request('signMessage', {
        message: decodeSignMessageRequest(request),
      })

    return response.result.signature
  }

  // https://leather.gitbook.io/developers/bitcoin/sign-transactions/partially-signed-bitcoin-transactions-psbts
  public async signTransaction(request: string) {
    const { psbtHex, broadcast } = decodeSignTransactionRequest(request)
    const response: RPCResponse<{ hex: string; txid?: string }> = await this.leather.request(
      'signPsbt',
      {
        hex: psbtHex,
        network: IS_TESTNET_DEPLOYMENT ? 'testnet' : 'mainnet',
        broadcast,
      },
    )

    let txId = response.result.txid
    if (!txId && broadcast) {
      // broadcast on testnet is not returning the txId - construct it if neccessary
      const bytes = hexToBytes(response.result.hex)
      const tx = btc.Transaction.fromPSBT(bytes)
      tx.finalize()
      txId = tx.id
    }

    return {
      psbtBase64: hexToBase64(response.result.hex),
      txId,
    }
  }

  public async sendBtcTransaction(request: string) {
    const { amount, address } = decodeSendBitcoinRequest(request)
    const response: RPCResponse<{ txid: string }> = this.leather.request('sendTransfer', {
      address,
      amount,
    })

    return response.result.txid
  }

  public async call(_request: string) {
    throw new Error('Not implemented')
    return {}
  }

  public async createInscription(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async createRepeatInscriptions(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async signMultipleTransactions(_request: string) {
    throw new Error('Not implemented')
    return []
  }

  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined,
  ): Promise<RpcResponse<Method>> {
    return this.leather.request(method, options, providerId)
  }
}
