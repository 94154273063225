export function estimateNetworkFee({
  satPriceUsd,
  satsVB,
  txBytes,
}: {
  satsVB: number
  satPriceUsd: number
  txBytes: number
}): NetworkFee {
  const totalSats = satsVB * txBytes
  return { sats: totalSats, usdValue: totalSats * satPriceUsd }
}

export interface NetworkFee {
  sats: number
  usdValue: number
}
