import { formatUnits, parseUnits } from 'ethers'

import { USD_DECIMALS } from '@packages/constants'

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export function formatBigIntUsd(usd: bigint) {
  return usdFormatter.format(parseFloat(formatUnits(usd, USD_DECIMALS)))
}

export function formatUsd(usd: number) {
  return usdFormatter.format(usd)
}

export function bigIntUsd(usdNum: number) {
  return parseUnits(`${usdNum}`, USD_DECIMALS)
}

export function bigIntUsdToFloat(bigInt: bigint) {
  return parseFloat(formatUnits(bigInt, USD_DECIMALS))
}
