export function calculateFeeFromBps(amount: bigint | number, bps: number) {
  return (BigInt(amount) * BigInt(bps)) / BigInt(10000)
}

export function isFeeWithinRange({
  actualFee,
  amount,
  feeBps,
  differenceBps,
  onlyGreater = false,
}: {
  actualFee: number
  amount: number
  feeBps: number
  differenceBps: number
  onlyGreater?: boolean
}) {
  const expectedFee = calculateFeeFromBps(amount, feeBps)
  const range = calculateFeeFromBps(expectedFee, differenceBps)
  console.log('fees', expectedFee, actualFee, range, feeBps, differenceBps)
  if (onlyGreater) {
    return actualFee >= expectedFee - BigInt(range)
  }
  return actualFee >= expectedFee - BigInt(range) && actualFee <= expectedFee + BigInt(range)
}
