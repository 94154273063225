import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { buildRuneDetailsUrl } from 'src/pages'
import { COLORS } from 'src/shared/constants'
import { BUTTON_HOVER_STYLES } from 'src/shared/components'

import { formatRuneName } from '../utils'

interface Props {
  rune: { runeName: string; runeSymbolChar?: string; runeNameSpacerIndices?: bigint[] }
  displayName?: string
  className?: string
}

export function RuneDetailsLink({ rune, displayName, className }: Props) {
  const runeName = formatRuneName(rune)

  return (
    <LinkWrapper to={buildRuneDetailsUrl(rune.runeName)} className={className}>
      <RuneName>
        {rune?.runeSymbolChar && <RuneSymbol>{rune?.runeSymbolChar}</RuneSymbol>}
        {displayName ?? runeName}
      </RuneName>
    </LinkWrapper>
  )
}

const LinkWrapper = styled(Link)`
  ${BUTTON_HOVER_STYLES}
`

const RuneName = styled.div`
  color: ${COLORS.link} !important;
  font-weight: 700;
  word-break: break-all;
  gap: 5px;
  display: flex;
`

const RuneSymbol = styled.span`
  color: ${COLORS.buttons.primary};
`
