import { css, styled } from 'styled-components'

import { MarketStat, MarketStats } from '@packages/interfaces'
import { formatBigIntUsd } from '@packages/utils'

import { Card } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { formatRuneName } from 'src/runes'

import { getPriceColor } from '../utils'
import { RunePriceSparklineChart } from './RunePriceSparklineChart'

interface Props {
  marketStat: MarketStat
}

export function RuneChartCard({ marketStat }: Props) {
  const intervalInfo = marketStat.oneDay
  const percentPriceChange = intervalInfo.percentPriceChange

  const IS_POSTIVE_PRICE_CHANGE = percentPriceChange > 0
  const color = getPriceColor(percentPriceChange)

  return (
    <CardWrapper>
      <Row>
        <RuneName>{formatRuneName(marketStat)}</RuneName>
        <PriceChange>
          {IS_POSTIVE_PRICE_CHANGE ? '+' : ''}
          {percentPriceChange}%
        </PriceChange>
      </Row>
      <Row>
        <PriceRangeContainer>
          <PriceRange color={color}>
            {formatBigIntUsd(intervalInfo.startingPriceUsd)} -{' '}
            {formatBigIntUsd(marketStat.priceUsd)}
          </PriceRange>
        </PriceRangeContainer>
        <RunePriceSparklineChartWrapper marketStat={marketStat} height={75} />
      </Row>
    </CardWrapper>
  )
}

const CardWrapper = styled(Card)`
  background-color: ${COLORS.background.overlay};
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RuneName = styled.div`
  font-weight: 500;
  font-size: 25px;
`

const PriceChange = styled.div`
  font-size: 15px;
`

const PriceRangeContainer = styled.div`
  flex: 2;
`

const PriceRange = styled.div<{ color: string }>`
  color: ${COLORS.black};
  width: fit-content;
  font-size: 13px;
  padding: 4px;
  border-radius: 4px;
  ${(props) => css`
    background-color: ${props.color};
  `}
`

const RunePriceSparklineChartWrapper = styled(RunePriceSparklineChart)`
  flex: 3;
`
