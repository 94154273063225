import _Tooltip from '@mui/material/Tooltip'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { COLORS, FONTS } from '../constants'

interface Props {
  content: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  children: React.ReactNode
  className?: string
}

export function Tooltip({ content, placement = 'top', children, className }: Props) {
  return (
    <>
      <TooltipStyles />
      <TooltipWrapper title={content} placement={placement} arrow className={className}>
        <>{children}</>
      </TooltipWrapper>
    </>
  )
}

const TooltipStyles = createGlobalStyle`  
  .MuiTooltip-tooltip {
    font-size: 12px;
    text-align: center;
    font-family: ${FONTS.text};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
  }
  .MuiTooltip-arrow{
    color: ${COLORS.white};
  }
`

const TooltipWrapper = styled(_Tooltip)`
  cursor: pointer !important;
`
