import { OKX_URLS } from '@packages/utils'

import { A } from 'src/shared/components'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { formatShortTransactionId, mempoolUrls } from '../utils'

interface Props {
  transactionId: string
  displayShortId?: boolean
  children?: React.ReactNode
  className?: string
}

export function TransactionExternalLink({
  transactionId,
  displayShortId = true,
  children,
  className,
}: Props) {
  return (
    <A
      href={IS_TESTNET_DEPLOYMENT ? mempoolUrls.tx(transactionId) : OKX_URLS.tx(transactionId)}
      className={className}
    >
      {children ?? (displayShortId ? formatShortTransactionId(transactionId) : transactionId)}
    </A>
  )
}
