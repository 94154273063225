import { styled } from 'styled-components'
import { useMemo, useState } from 'react'
import Decimal from 'decimal.js-light'

import { RuneBalance } from '@packages/interfaces'
import { formatUsd, satsToBtc } from '@packages/utils'

import { Button, Drawer, DrawerCard, ErrorMessage, BigIntInput } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, useEstimatedTotalNetworkFee } from 'src/web3'
import { formatRuneName } from 'src/runes'
import { useWalletContext } from 'src/wallet'
import { TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'

interface Props {
  isOpen: boolean
  onClose: () => void
  runeBalance: RuneBalance
}

export function TransferRunesDrawer({ isOpen, onClose, runeBalance }: Props) {
  const { btcPrice } = useWalletContext()
  const [error, setError] = useState<string | null>(null)

  async function onConfirmClick() {
    try {
      setError(null)
      console.log(runeBalance)
    } catch (error: any) {
      setError(error.message)
    }
  }

  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [runesAmount, setRunesAmount] = useState(0n)

  const totalNetworkFee = useEstimatedTotalNetworkFee({
    satsVB: selectedNetworkFee,
    txBytes: TRX_VIRTUAL_SIZE_BYTES.transfer,
  })

  const { btcTotal, usdTotal } = useMemo(() => {
    const satsTotal = BigInt(totalNetworkFee.sats)
    const btcTotal = satsToBtc(satsTotal)
    return {
      btcTotal,
      satsTotal,
      usdTotal: new Decimal(btcTotal).mul(btcPrice?.btcPriceUsd ?? 0).toNumber(),
    }
  }, [totalNetworkFee, btcPrice])

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>Transfer {formatRuneName(runeBalance)}</Title>

        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Transfer Amount</BoldColumn>
          </Row>
          <Row>
            <BigIntInput
              value={runesAmount}
              onChange={setRunesAmount}
              decimals={runeBalance.runeDecimals}
            />
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <NetworkFeeSelector
              selectedFee={selectedNetworkFee}
              onChange={(fee) => setSelectedNetworkFee(fee)}
            />
          </Row>
        </DrawerCardWrapper>
        <DrawerCardWrapper>
          <Row>
            <BoldColumn>Total Cost</BoldColumn>
            <BoldColumn>{btcTotal} BTC</BoldColumn>
            <BoldColumn>{formatUsd(usdTotal)} USD</BoldColumn>
          </Row>
        </DrawerCardWrapper>
        {error && <ErrorMessage message={error} />}
        <Buttons>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <ConfirmButton onClick={onConfirmClick}>Confirm Transfer</ConfirmButton>
        </Buttons>
      </Container>
    </Drawer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Title = styled.h2`
  font-weight: 500;
`

const DrawerCardWrapper = styled(DrawerCard)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`

const CancelButton = styled(Button)`
  color: ${COLORS.buttons.primary};
  background-color: transparent;
  border: 2px solid ${COLORS.buttons.primary};
`

const ConfirmButton = styled(Button)`
  color: ${COLORS.black};
  background-color: ${COLORS.buttons.primary};
`

const Row = styled.div`
  display: flex;
`

const BoldColumn = styled.div`
  font-weight: 500;
  flex: 1;
`
