import { useMemo } from 'react'

import { useWalletContext } from 'src/wallet'

import { estimateNetworkFee } from '../utils'

export function useEstimatedTotalNetworkFee({
  satsVB,
  txBytes,
}: {
  satsVB: number
  txBytes: number
}) {
  const { btcPrice } = useWalletContext()

  return useMemo(() => {
    return estimateNetworkFee({ satPriceUsd: btcPrice?.satPriceUsd ?? 0, satsVB, txBytes })
  }, [btcPrice, satsVB, txBytes])
}
