import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'

import { COLORS } from '../constants'

interface Props {
  linkText: string
  children?: React.ReactNode
  useAnimation?: boolean
}

export function Accordion({ linkText, useAnimation = true, children }: Props) {
  const [parent] = useAutoAnimate()
  const [expanded, setExpanded] = useState(false)

  function onExpand() {
    setExpanded(!expanded)
  }

  const containerProps = {
    ...(useAnimation && { ref: parent }),
  }

  return (
    <Container {...containerProps}>
      <ExpandLink onClick={onExpand}>
        <ExpandLinkText>{linkText}</ExpandLinkText>
        <ExpandLinkIcon expanded={expanded} />
      </ExpandLink>
      {expanded && <Content>{children}</Content>}
    </Container>
  )
}

const Container = styled.div<{ ref?: any }>`
  width: 100%;
`

const ExpandLink = styled.div`
  display: flex;
  color: ${COLORS.link};
`

const ExpandLinkText = styled.span``

const ExpandLinkIcon = styled(ExpandMoreIcon)<{ expanded: boolean }>`
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`

const Content = styled.div`
  margin-top: 15px;
`
