import { createGlobalStyle } from 'styled-components'

import { COLORS, FONTS } from './shared/constants'
import { NOISE_BG_CSS } from './shared/styles'

export default createGlobalStyle`
/* 1. Use a more-intuitive box-sizing model. */
*, *::before, *::after {
    box-sizing: border-box;
}
/* 2. Remove default margin */
* {
    margin: 0;
}
/* 3. Allow percentage-based heights in the application */
html, body {
    font-family: ${FONTS.text}, sans-serif;
    font-weight: 400;
    font-style: normal;
    height: 100%;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${COLORS.background.primary};
    color: ${COLORS.text};
    ${NOISE_BG_CSS}
}
/* Typographic tweaks! 4. Add accessible line-height 5. Improve text rendering */
body {
    line-height: 1.5;
}
/* 6. Improve media defaults */
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
/* 7. Remove built-in form typography styles */
input, button, textarea, select {
    font: inherit;
}
/* 8. Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
/* 9. Create a root stacking context */
#root, #__next {
    isolation: isolate;
}

a {
    cursor: pointer;
    text-decoration: none;
}

div {
    color: ${COLORS.text};
}

  
`
