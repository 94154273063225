import { API_ENDPOINTS } from '@packages/constants'
import { Order, OrderStatus } from '@packages/interfaces'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

export function useOrdersByStatusApi<T extends Order>({
  status,
  runeName,
  limit,
}: {
  status: OrderStatus
  runeName: string
  limit?: number
}) {
  const response = useUpdateOnBlockPaginationApi<T>({
    endpoint: `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byStatus, {
      runeName: runeName.toUpperCase(),
    })}?status=${status}`,
    limit,
  })
  return response
}
