import { styled } from 'styled-components'
import { Alert } from '@mui/material'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { COLORS, FONTS } from 'src/shared/constants'
import { DEFAULT_TRANSITION } from 'src/shared/styles'

import { ToastContainer } from './styles'
import { BUTTON_HOVER_STYLES } from '../buttons'

export interface LinkPageToastProps {
  key: string
  variant: 'linkPage'
  message: string
  styleVariant?: string
  buttonMessage: string
  reactRouterPathname: string
  reactRouterSearch?: string
  autoHideDuration?: number
  className?: string
}

export const LinkPageToast = forwardRef<HTMLDivElement, LinkPageToastProps>(
  (
    {
      key,
      className,
      message,
      buttonMessage,
      styleVariant = 'success',
      reactRouterPathname,
      reactRouterSearch,
    },
    ref,
  ) => {
    const { closeSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const handleDismiss = useCallback(() => {
      closeSnackbar(key)
    }, [key, closeSnackbar])

    function handleClick() {
      handleDismiss()
      navigate({ pathname: reactRouterPathname, search: reactRouterSearch })
    }

    return (
      <ToastContainer
        ref={ref}
        className={className}
        key={key}
        color={
          styleVariant === 'error'
            ? COLORS.negative
            : styleVariant === 'warning'
              ? COLORS.warning
              : COLORS.background.primary
        }
      >
        <Alert onClose={handleDismiss} icon={false}>
          <Message>{message} </Message>
          <LinkButton onClick={handleClick}>{buttonMessage}</LinkButton>
        </Alert>
      </ToastContainer>
    )
  },
)

LinkPageToast.displayName = 'LinkPageToast'

const Message = styled.div``

const LinkButton = styled.div`
  ${DEFAULT_TRANSITION}
  color: ${COLORS.link};
  font-weight: 700;
  font-family: ${FONTS.text};
  ${BUTTON_HOVER_STYLES}
`
