import * as React from 'react'
import _Drawer from '@mui/material/Drawer'
import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from '../constants'
import { hexToRgb } from '../utils'
import { NOISE_BG_CSS } from '../styles'
import { CloseButton } from './buttons'

interface Props {
  isOpen: boolean
  onClose: () => void
  canClose?: boolean
  children: React.ReactNode
  className?: string
}

export function Drawer({ isOpen, onClose, canClose = true, children, className }: Props) {
  function handleOnClose() {
    if (canClose) {
      onClose()
    }
  }
  return (
    <DrawerWrapper open={isOpen} onClose={handleOnClose} className={className} anchor={'right'}>
      <CloseButtonWrapper onClose={onClose} canClose={canClose} />
      {children}
    </DrawerWrapper>
  )
}

export const DrawerCard = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${COLORS.white};
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  padding: 20px;
  background-color: ${COLORS.background.container};
`

const DrawerWrapper = styled(_Drawer)`
  .MuiDrawer-modal {
    ${NOISE_BG_CSS}
  }
  .MuiDrawer-paper {
    border-left: 1px solid ${hexToRgb(COLORS.white, 0.6)};
    padding: 20px;
    padding-top: 45px;
    ${NOISE_BG_CSS}
    width: 35vw;

    @media (max-width: ${BREAKPOINTS.large}) {
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      width: 75%;
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      width: 95%;
    }
  }
`

const CloseButtonWrapper = styled(CloseButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`
