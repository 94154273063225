import { styled } from 'styled-components'
import { Alert } from '@mui/material'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'

import { COLORS, FONTS } from 'src/shared/constants'
import { DEFAULT_TRANSITION } from 'src/shared/styles'

import { ToastContainer } from './styles'
import { BUTTON_HOVER_STYLES } from '../buttons'

export interface RefreshPageToastProps {
  key: string
  variant: 'refreshPage'
  message?: string
  buttonMessage?: string
  onClick: () => void
  autoHideDuration?: number
  className?: string
}

export const RefreshPageToast = forwardRef<HTMLDivElement, RefreshPageToastProps>(
  ({ key, className, message, buttonMessage, onClick }, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(key)
    }, [key, closeSnackbar])

    function handleRefresh() {
      handleDismiss()
      onClick()
    }

    return (
      <ToastContainer ref={ref} className={className} key={key}>
        <Alert onClose={handleDismiss} icon={false}>
          <Message>{message ?? 'Refresh to get the latest data.'} </Message>
          <RefreshButton onClick={handleRefresh}>{buttonMessage ?? 'Refresh'}</RefreshButton>
        </Alert>
      </ToastContainer>
    )
  },
)

RefreshPageToast.displayName = 'RefreshPageToast'

const Message = styled.div``

const RefreshButton = styled.div`
  ${DEFAULT_TRANSITION}
  color: ${COLORS.link};
  font-weight: 700;
  font-family: ${FONTS.text};
  ${BUTTON_HOVER_STYLES}
`
