import styled from 'styled-components'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { useNavigate } from 'react-router-dom'

import { shortFormatBtc } from '@packages/utils'

import btcIcon from 'src/wallet/assets/bitcoin.svg'
import runeIcon from 'src/wallet/assets/rune.svg'
import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'
import { buildAccountDetailsUrl } from 'src/pages'
import { CopyText } from 'src/shared/components'
import { NOISE_BG_CSS, applyOnlyBorderGlow } from 'src/shared/styles'

import { useWalletContext } from '../WalletContext'
import { formatShortAddress } from '../utils'

interface Props {
  onClose: () => void
  anchorElement: HTMLElement | null
}

export function WalletMenu({ anchorElement, onClose }: Props) {
  const navigate = useNavigate()
  const { ordinalsAddress, paymentAddress, disconnectWallet, btcBalances } = useWalletContext()
  const isOpen = Boolean(anchorElement)

  const handleClose = () => {
    onClose()
  }

  function onDisconnect() {
    disconnectWallet()
    onClose()
  }

  function onWalletClick() {
    if (ordinalsAddress) {
      navigate({ pathname: buildAccountDetailsUrl(ordinalsAddress.addrString) })
    }
    onClose()
  }

  const singleAddress = paymentAddress?.addrString === ordinalsAddress?.addrString

  return (
    <Container>
      <MenuWrapper
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorElement}
        open={isOpen}
        onClose={handleClose}
      >
        <Addresses>
          <AddressContainer>
            <PaymentAddress>
              <AddressType>
                <AddressIcon src={btcIcon} />
                {singleAddress ? 'Payment & Runes' : 'Payment'}
              </AddressType>
              <CopyTextWrapper
                textToCopy={paymentAddress?.addrString ?? ''}
                tooltipPlacement='right'
              >
                {formatShortAddress(paymentAddress?.addrString ?? '')}
              </CopyTextWrapper>
            </PaymentAddress>

            <Balance>
              {btcBalances?.chainBalanceBtc ? shortFormatBtc(btcBalances?.chainBalanceBtc) : 0} BTC
            </Balance>
          </AddressContainer>
          {!singleAddress && (
            <AddressContainer>
              <RunesAddress>
                <AddressType>
                  <AddressIcon src={runeIcon} />
                  Runes
                </AddressType>
                <CopyTextWrapper
                  textToCopy={ordinalsAddress?.addrString ?? ''}
                  tooltipPlacement='right'
                >
                  {formatShortAddress(ordinalsAddress?.addrString ?? '')}
                </CopyTextWrapper>
              </RunesAddress>
            </AddressContainer>
          )}
        </Addresses>

        <Divider sx={{ my: 0.5 }} />

        <MenuItemWrapper onClick={onWalletClick} disableRipple>
          <NavItem>
            <AccountBalanceWalletIcon /> Account
          </NavItem>
        </MenuItemWrapper>

        <Divider sx={{ my: 0.5 }} />

        <MenuItemWrapper onClick={onDisconnect} disableRipple>
          <NavItem>
            <PowerSettingsNewIcon />
            Disconnect
          </NavItem>
        </MenuItemWrapper>
      </MenuWrapper>
    </Container>
  )
}

const Container = styled.div``

const MenuWrapper = styled(Menu)`
  .MuiPopover-paper {
    margin-top: 5px;
    border-radius: 6px;
    min-width: 250px;

    ${NOISE_BG_CSS}
    ${applyOnlyBorderGlow({})}
font-weight: 500;
    color: ${COLORS.white};

    hr {
      ${applyOnlyBorderGlow({})}
      border-color: ${hexToRgb(COLORS.white, 0.5)};
    }
  }
`

const MenuItemWrapper = styled(MenuItem)`
  &:hover {
    div {
      color: ${hexToRgb(COLORS.white, 0.6)};
    }
  }
`

const NavItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${COLORS.white};
  svg {
    height: 20px;
    width: 20px;
  }
`

const Addresses = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  font-size: 14px;
`

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${COLORS.white};
`

const AddressType = styled.div`
  color: ${COLORS.white};
  display: flex;
  gap: 5px;
`

const CopyTextWrapper = styled(CopyText)`
  color: ${hexToRgb(COLORS.white, 0.6)};
`

const AddressIcon = styled.img`
  height: 20px;
  width: 20px;
`

const PaymentAddress = styled.div`
  width: 100%;
  flex: 3;
`

const Balance = styled.div`
  width: 100%;
  text-align: right;
  color: ${COLORS.white};
  flex: 1;
`

const RunesAddress = styled.div``
