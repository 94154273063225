import Tooltip from '@mui/material/Tooltip'
import { createGlobalStyle, styled } from 'styled-components'

import helpIcon from 'src/shared/assets/help.svg'

import { COLORS, FONTS } from '../constants'

interface Props {
  content: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

export function HelpTooltip({ content, placement }: Props) {
  return (
    <>
      <TooltipStyles />
      <TooltipWrapper title={content} arrow placement={placement}>
        <img src={helpIcon} width={20} />
      </TooltipWrapper>
    </>
  )
}

const TooltipStyles = createGlobalStyle`  
  .MuiTooltip-tooltip {
    font-size: 12px;
    text-align: center;
    font-family: ${FONTS.text};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
  }
  .MuiTooltip-arrow{
    color: ${COLORS.white} !important;
  }
`

const TooltipWrapper = styled(Tooltip)`
  cursor: pointer !important;
`
