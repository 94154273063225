import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

import { BREAKPOINTS, COLORS, FONTS } from '../../constants'
import { hexToRgb } from '../../utils'
import { BUTTON_HOVER_STYLES } from './styles'

export interface ButtonGroupOptions {
  value: string
  label: string
  title?: string
  subtitle?: string
}

interface Props {
  options: ButtonGroupOptions[]
  value: string
  onChange: (value: string) => void
  exclusive?: boolean
  className?: string
  updateSearchParams?: boolean
}

export function LargeButtonGroup({
  value,
  onChange,
  options,
  exclusive = false,
  className,
  updateSearchParams,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const searchParamTab = searchParams.get('tab')
    if (searchParamTab && updateSearchParams) {
      onChange(searchParamTab)
    }
  }, [])

  useEffect(() => {
    if (updateSearchParams) {
      setSearchParams({ tab: value })
    }
  }, [value])

  const handleChange = (_event: React.MouseEvent<HTMLElement>, selectedValue: string) => {
    onChange(selectedValue)
  }

  return (
    <ToggleButtonGroupWrapper
      value={value}
      exclusive={exclusive}
      onChange={handleChange}
      className={className}
    >
      {options.map((option) => {
        return (
          <ToggleButton key={option.label} value={option.value}>
            {option.title && <Title>{option.title}</Title>}
            {option.label}
            {option.subtitle && <Subtitle>{option.subtitle}</Subtitle>}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroupWrapper>
  )
}

const ToggleButtonGroupWrapper = styled(ToggleButtonGroup)`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
    gap: 10px;
  }

  button {
    display: flex;
    flex-direction: column;
    text-transform: none;
    gap: 5px;
    height: 42px;
    background-color: ${COLORS.background.container};
    color: ${hexToRgb(COLORS.white, 0.5)};
    border-radius: 100px !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    font-family: ${FONTS.text};

    ${BUTTON_HOVER_STYLES}
    &:hover {
      background-color: ${COLORS.background.container};
      color: ${hexToRgb(COLORS.white, 0.5)};
    }
    @media (max-width: ${BREAKPOINTS.medium}) {
      flex: 1;
      max-width: 250px;
      font-size: 13px;
      height: 35px;
    }
  }

  .Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    &:hover {
      background-color: ${COLORS.white};
      color: ${COLORS.black};
    }
  }
`

const Title = styled.h3`
  color: ${COLORS.black};
  font-weight: 300;
`

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: ${COLORS.black};
`
