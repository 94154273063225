import JSONbig from '@cardanosolutions/json-bigint'

import { API_URL } from 'src/settings'

import { formatQueryParams } from './queryParams'

interface FetchOptions {
  method?: 'GET' | 'POST'
  body?: any
  disableJsonBigParsing?: boolean
}

const jsonBig = JSONbig({ useNativeBigInt: true, alwaysParseAsBig: true })

// returns an object
export async function apiFetch<T>(
  endpoint: string,
  queryParams?: { [key: string]: any },
  options?: FetchOptions,
): Promise<T> {
  try {
    const queryParamsStr = queryParams ? formatQueryParams(queryParams) : ''
    const response = await fetch(new URL(`${endpoint}${queryParamsStr}`, API_URL), {
      method: options?.method ?? 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: options?.body
        ? typeof options.body === 'object' && !options.disableJsonBigParsing
          ? jsonBig.stringify(options?.body)
          : options.body
        : undefined,
    })

    if (!response.ok) {
      throw new Error('Failed to fetch data')
    }

    const text = await response.text()

    const parsed = options?.disableJsonBigParsing ? JSON.parse(text) : jsonBig.parse(text)

    return parsed as T
  } catch (error) {
    console.error('network error', error)

    throw new Error('API Connection Issue')
  }
}
