import { SnackbarContent } from 'notistack'
import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'

export const ToastContainer = styled(SnackbarContent)<{ color?: string }>`
  justify-content: end;
  .MuiAlert-standard {
    background-color: ${({ color }) => color || COLORS.background.primary};
    border: 1px solid white;
    font-family: ${FONTS.text};
    svg {
      color: ${COLORS.white};
    }
  }
`
