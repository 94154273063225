import Snackbar from '@mui/material/Snackbar'
import { styled } from 'styled-components'
import { Alert } from '@mui/material'

import { COLORS, FONTS } from 'src/shared/constants'

interface Props {
  isOpen: boolean
  onClose: () => void
  message: string
  autoHideDurationMs?: number
  className?: string
}

export function Toast({ isOpen, onClose, message, autoHideDurationMs, className }: Props) {
  return (
    <ToastWrapper
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpen}
      onClose={onClose}
      message={message}
      className={className}
      autoHideDuration={autoHideDurationMs}
    >
      <Alert onClose={onClose} icon={false}>
        {message}
      </Alert>
    </ToastWrapper>
  )
}

const ToastWrapper = styled(Snackbar)`
  .MuiAlert-standard {
    background-color: ${COLORS.background.container};
    border: 1px solid white;
    font-family: ${FONTS.text};
  }
`
