import { styled } from 'styled-components'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { RunesHistoryRecord } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { VirtualizedTable } from 'src/shared/components'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { RuneDetailsLink, formatRunesAmount } from 'src/runes'
import { COLORS } from 'src/shared/constants'
import { usePaginationApi } from 'src/api'
import { useIsMobile } from 'src/shared/hooks'

interface Props {
  address: string
}

export function AccountHistoryTable({ address }: Props) {
  const isMobile = useIsMobile()
  const {
    paginatedData: accountHistory,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = usePaginationApi<RunesHistoryRecord>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.accounts.history, { address }),
    limit: isMobile ? 20 : 40,
  })

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'runeName',
            label: 'Token',
            width: 45,
            formatter: ({ data: historyRecord }) => <RuneDetailsLink rune={historyRecord} />,
          },
          {
            dataKey: 'recordType',
            label: 'Transfer',
            hideOnMobile: true,
            width: 8,
            formatter: ({ data: historyRecord }) => {
              const isTransferOut = historyRecord.recordType !== 'RunesTransferred'
              return isTransferOut ? <IconWrapped /> : null
            },
          },
          {
            dataKey: 'isMint',
            label: 'Mint',
            hideOnMobile: true,
            width: 7,
            formatter: ({ data: historyRecord }) => (historyRecord.isMint ? <IconWrapped /> : null),
          },
          {
            dataKey: 'isAirdrop',
            label: 'Airdrop',
            hideOnMobile: true,
            width: 7,
            formatter: ({ data: historyRecord }) =>
              historyRecord.isAirdrop ? <IconWrapped /> : null,
          },
          {
            dataKey: 'runesAmount',
            label: 'Amount',
            width: 25,
            formatter: ({ data: historyRecord }) => {
              const isPositive = historyRecord.recordType === 'RunesTransferred'
              return (
                <RecordType positive={isPositive}>
                  <Amount>
                    {isPositive ? '+' : '-'}
                    {formatRunesAmount({
                      rune: historyRecord,
                      runesAmount: historyRecord.runesAmount,
                      displayShortFormat: isMobile,
                    })}
                  </Amount>
                </RecordType>
              )
            },
          },
          {
            dataKey: 'date',
            label: 'Date',
            width: 30,
            formatter: ({ data: historyRecord }) =>
              unixTimestampToDateString(historyRecord.timestamp, true),
          },
        ]}
        paginatedData={accountHistory ?? []}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 70 : 35}
        viewableRows={20}
      />
    </Container>
  )
}

const Container = styled.div``

const RecordType = styled.div<{ positive: boolean }>`
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
  font-weight: 700;
  div {
    color: ${({ positive }) => COLORS[positive ? 'positive' : 'negative']} !important;
  }
`

const Amount = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const IconWrapped = styled(CheckCircleIcon)`
  color: ${COLORS.link} !important;
  line-height: 15px;
`
