import { Route, Routes } from 'react-router-dom'

import { ROUTES } from './routes'
import {
  HomePage,
  MarketDetailsPage,
  RuneDetailsPage,
  AccountDetailsPage,
  DemoWasmPage,
  EtchPage,
  MintPage,
  SettingsPage,
} from './pages'
import { ScrollToTop } from './components'

export function PageRoutes() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.home} element={<HomePage />} />
        <Route path={ROUTES.etch} element={<EtchPage />} />
        <Route path={ROUTES.mint} element={<MintPage />} />
        <Route path={ROUTES.marketDetails} element={<MarketDetailsPage />} />
        <Route path={ROUTES.runeDetails} element={<RuneDetailsPage />} />
        <Route path={ROUTES.accountDetails} element={<AccountDetailsPage />} />
        <Route path={ROUTES.demoWasm} element={<DemoWasmPage />} />
        <Route path={ROUTES.settings} element={<SettingsPage />} />
        <Route path='/*' element={<HomePage />} />
      </Routes>
    </>
  )
}
