import styled, { css } from 'styled-components'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import logoIcon from 'src/pages/assets/logo.svg'
import menuIcon from 'src/pages/assets/menu.svg'
import { BREAKPOINTS, COLORS, FONTS, Z_INDEX } from 'src/shared/constants'
import { ConnectButton } from 'src/wallet'
import { BUTTON_HOVER_STYLES, Drawer } from 'src/shared/components'
import { DEFAULT_TRANSITION, NOISE_BG_CSS, applyGlowFilter } from 'src/shared/styles'

import { ExternalLinks, NavLinks } from './Links'
import { ROUTES } from '../routes'

export const CONTENT_PADDING = '20px'
export const MOBILE_CONTENT_PADDING = '10px'

export const NAVBAR_HEIGHT = '90px'
export const NAVBAR_HEIGHT_MOBILE = '60px'

export function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  function openModal() {
    setIsMobileMenuOpen(true)
  }

  function closeModal() {
    setIsMobileMenuOpen(false)
  }

  const collapsed = true

  return (
    <Container $collapsed={collapsed} $isHomePage={false}>
      <Content>
        <LeftContent $collapsed={collapsed}>
          <Title $collapsed={collapsed} to={ROUTES.home}>
            <LogoImage src={logoIcon} alt='Mystic' $collapsed={collapsed} />
          </Title>
        </LeftContent>
        <RightContent $collapsed={collapsed}>
          <NavLinksContainer>
            <NavLinks onNavItemClick={closeModal} />
          </NavLinksContainer>
          <ConnectButtonContainer>
            <ConnectButton />
          </ConnectButtonContainer>
        </RightContent>
        <RightContentMobile $collapsed={collapsed}>
          <ConnectButtonContainer>
            <ConnectButton />
          </ConnectButtonContainer>
          <MenuIcon onClick={openModal} src={menuIcon} />
        </RightContentMobile>
        <Drawer isOpen={isMobileMenuOpen} onClose={closeModal}>
          <MobileContent>
            <NavLinksContainerMobile>
              <NavLinks onNavItemClick={closeModal} />
            </NavLinksContainerMobile>
            <ExternalLinksContainer>
              <ExternalLinks />
            </ExternalLinksContainer>
          </MobileContent>
        </Drawer>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ $collapsed: boolean; $isHomePage: boolean }>`
  position: fixed;
  width: 100%;
  padding: 13px 10px ${CONTENT_PADDING};
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${Z_INDEX.navBar};

  ${NOISE_BG_CSS}

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 10px ${MOBILE_CONTENT_PADDING};
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: ${BREAKPOINTS.large};
  @media (max-width: ${BREAKPOINTS.medium}) {
    align-items: flex-end;
  }
`

const LeftContent = styled.div<{ $collapsed: boolean }>`
  display: flex;
  gap: 15px;
  padding-top: 120px;
  ${(props) =>
    props.$collapsed &&
    css`
      padding-top: 0px;
    `}
`

const Title = styled(Link)<{ $collapsed: boolean }>`
  ${DEFAULT_TRANSITION}
  font-family: ${FONTS.headers};
  font-size: 45px;
  color: ${COLORS.white};
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 60px;
  cursor: pointer;
  ${BUTTON_HOVER_STYLES}
  ${(props) =>
    props.$collapsed &&
    css`
      font-size: 40px;
    `}

  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 30px;
    gap: 5px;
  }
`

const LogoImage = styled.img<{ $collapsed: boolean }>`
  width: 250px;
  margin-left: -25px;
  @media (max-width: ${BREAKPOINTS.large}) {
    margin-left: 0px;
    width: 200px;
  }
  ${applyGlowFilter({})}
`

const RightContent = styled.div<{ $collapsed: boolean }>`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 8px;
  border-radius: 100px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

const RightContentMobile = styled(RightContent)`
  display: none;
  align-items: center;
  justify-content: end;
  gap: 10px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: flex;
    padding-top: 0px;
    gap: 5px;
  }
`

const ConnectButtonContainer = styled.div`
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-left: 20px;
  }
`

const MenuIcon = styled.img`
  ${applyGlowFilter({})}
  height: 45px;
  width: 45px;
  cursor: pointer;
  ${BUTTON_HOVER_STYLES}
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 40px;
    width: 40px;
  }
`

const NavLinksContainer = styled.div`
  display: flex;
  gap: 20px;
  font-weight: 400;
  font-family: ${FONTS.headers};
  a {
    color: ${COLORS.white};
    align-self: center;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: auto;
`

const NavLinksContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  a {
    color: ${COLORS.white};
  }
`

const ExternalLinksContainer = styled.div`
  div:first-child {
    gap: 15px;
    flex-direction: column;
    div:first-child {
      flex-direction: column;
    }
  }
`
