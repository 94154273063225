import { useState } from 'react'

import { PaginationResponse, RuneDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { useDebounce } from 'src/shared/hooks'
import { apiFetch } from 'src/api'
import { normalizeRuneName } from 'src/runes'

export function useMarketSearch({
  orderBy = 'mintCount',
}: {
  orderBy?: 'mintCount' | 'holderCount'
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<RuneDetails[]>([])
  const [loading, setLoading] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)

  function handleOnChange(value: string) {
    if (searchQuery.trim().length > 0) {
      setLoading(true)
    }
    setSearchQuery(value)
  }

  useDebounce(
    () => {
      if (searchQuery.trim().length > 0) {
        apiSearch()
      } else {
        setSearchResults([])
        setLoading(false)
      }
    },
    [searchQuery],
    1000,
  )

  async function apiSearch() {
    setHasSearched(true)
    try {
      const response = await apiFetch<PaginationResponse<RuneDetails>>(
        API_ENDPOINTS.GET.runes.markets.search,
        {
          searchQuery: normalizeRuneName(searchQuery),
          limit: 50,
          orderBy,
        },
      )
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error searching markets:', error)
    }
    setLoading(false)
  }

  return { handleOnChange, loading, hasSearched, searchResults, searchQuery }
}
