import styled from 'styled-components'
import { useState } from 'react'

import { RuneBalance } from '@packages/interfaces'
import { formatBigIntUsd, satsToBtc } from '@packages/utils'
import { API_ENDPOINTS } from '@packages/constants'

import { replaceUrlParams } from 'src/shared/utils'
import { Button, VirtualizedTable } from 'src/shared/components'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { MarketDetailsLink } from 'src/markets'
import { COLORS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'

import { RuneDetailsLink } from './RuneDetailsLink'
import { TransferRunesDrawer } from './TransferRunesDrawer'
import { formatRunesAmount } from '../utils'

interface Props {
  address: string
}

export function RuneBalancesTable({ address }: Props) {
  const isMobile = useIsMobile()
  const {
    paginatedData: runeBalances,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<RuneBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byAccount, { address }),
    limit: isMobile ? 20 : 40,
  })
  const [isTransferDrawerOpen, setIsTransferDrawerOpen] = useState(false)
  const [selectedBalanceRecord, setSelectedBalanceRecord] = useState<RuneBalance>()

  function openTransferDrawer(balanceRecord: RuneBalance) {
    setIsTransferDrawerOpen(true)
    setSelectedBalanceRecord(balanceRecord)
  }

  function closeTransferDrawer() {
    setIsTransferDrawerOpen(false)
    setSelectedBalanceRecord(undefined)
  }

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'symbol',
            label: 'Token',
            width: isMobile ? 70 : 60,
            formatter: ({ data: balanceRecord }) => <RuneDetailsLink rune={balanceRecord} />,
          },
          {
            dataKey: 'runesAmount',
            label: 'Amount',
            width: isMobile ? 30 : 40,
            formatter: ({ data: balanceRecord }) =>
              formatRunesAmount({ rune: balanceRecord, runesAmount: balanceRecord.runesAmount }),
          },
          // {
          //   dataKey: 'valueUSD',
          //   label: 'USD Value',
          //   hideOnMobile: true,
          //   formatter: ({ data: balanceRecord }) => (
          //     <TableData>{formatBigIntUsd(balanceRecord.valueUsd)}</TableData>
          //   ),
          // },
          // {
          //   dataKey: 'valueSats',
          //   label: 'BTC Value',
          //   hideOnMobile: true,
          //   formatter: ({ data: balanceRecord }) => (
          //     <TableData>{satsToBtc(balanceRecord.valueSats)}</TableData>
          //   ),
          // },
          // {
          //   dataKey: 'transfer',
          //   label: '',
          //   formatter: ({ data: balanceRecord }) => (
          //     <TransferButton onClick={() => openTransferDrawer(balanceRecord)}>
          //       Transfer
          //     </TransferButton>
          //   ),
          // },
          // {
          //   dataKey: 'trade',
          //   label: '',
          //   formatter: ({ data: balanceRecord }) => (
          //     <MarketDetailsLink runeName={balanceRecord.runeName} />
          //   ),
          // },
        ]}
        paginatedData={runeBalances}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        viewableRows={20}
        rowHeight={30}
      />
      {selectedBalanceRecord && (
        <TransferRunesDrawer
          isOpen={isTransferDrawerOpen}
          onClose={closeTransferDrawer}
          runeBalance={selectedBalanceRecord}
        />
      )}
    </Container>
  )
}

const Container = styled.div``

const TableData = styled.div``

const TransferButton = styled(Button)`
  background-color: ${COLORS.buttons.accent};
`
