import { formatUnits } from 'ethers'

import {
  RuneBalance,
  RuneTransfer,
  RuneTransfers,
  MarketVolumeByPeriod,
  MarketInfo,
  MarketStats,
  MarketStatsIntervalInfo,
  MarketStat,
  RuneDetails,
  Order,
  OrderStatus,
  FilledOrder,
} from '@packages/interfaces'

function randomInt() {
  return Math.round(Math.random() * 100) + 1
}

function generateRandomMarketCapUsd() {
  return 100000000000000000000000n * BigInt(randomInt())
}

function generateRandomVolumeUsd() {
  return 1000000000000000000000n * BigInt(randomInt())
}

function generateRandomPriceUsd() {
  return 10000000000000000n * BigInt(randomInt())
}

function generateRandomPriceSats() {
  return 10000000000000n * BigInt(randomInt())
}

function generateRandomRuneName(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

function generateDummyOrder(runeDetails: RuneDetails, status: OrderStatus) {
  const now = BigInt(Date.now())
  const priceSats = (Math.random() * 100).toFixed(3)
  const runesAmount = BigInt(Math.floor(Math.random() * 1000))
  const runesAmountFloat = parseFloat(formatUnits(runesAmount, runeDetails.runeDecimals))
  const order: Order = {
    ...runeDetails,
    id: BigInt(Math.floor(Math.random() * 10000)),
    runesAmount,
    satsAmount: BigInt(Math.floor(Math.random() * 5000)),
    valueUsd: BigInt(Math.floor(Math.random() * 300)),
    valueSats: BigInt(Math.floor(runesAmountFloat * parseFloat(priceSats))),
    price: Math.random() * 100,
    priceUsd: (Math.random() * 10).toFixed(2),
    priceSats,
    isActive: status === 'Placed',
    status,
    placedByAddress: `1A2B3C4D${Math.floor(Math.random() * 10000)}`,
    placedAtTimestamp: now + BigInt(Math.floor(Math.random() * 10000)),
    marketplaceFeeAmountSats: BigInt(Math.floor(Math.random() * 50)),
    marketplaceFeeAmountUsd: BigInt(Math.floor(Math.random() * 5)),
  }

  if (status === 'Filled') {
    order.fillBroadcastAtTimestamp = now + BigInt(Math.floor(Math.random() * 10000))
    order.fillTransactionId = `0x${Math.random().toString(16).slice(2)}`
    order.filledAtBlockHeight = BigInt(Math.floor(Math.random() * 10000))
    order.filledByAddress = `1A2B3C4D${Math.floor(Math.random() * 10000)}`
    order.filledAtTimestamp = now + BigInt(Math.floor(Math.random() * 10000))
  }

  return order
}

export function generateDummyOpenOrders(runeDetails: RuneDetails, count: number): Order[] {
  const orders = []
  for (let i = 0; i < count; i++) {
    orders.push(generateDummyOrder(runeDetails, 'Placed'))
  }
  return orders
}

export function generateDummyFilledOrders(runeDetails: RuneDetails, count: number): FilledOrder[] {
  const orders = []
  for (let i = 0; i < count; i++) {
    orders.push(generateDummyOrder(runeDetails, 'Filled'))
  }
  return orders as FilledOrder[]
}

function dummyMarketIntervalData(): MarketStatsIntervalInfo {
  return {
    volumeSats: 9891234231n,
    volumeUsd: generateRandomVolumeUsd(),
    sparklineData: [Math.random(), Math.random(), Math.random(), Math.random(), Math.random()],
    percentPriceChange: Math.random() > 0.5 ? randomInt() : -randomInt(),
    startingPriceSats: '0.0001',
    startingPriceUsd: generateRandomPriceUsd(),
  }
}

function dummyMarketStats(): MarketStat {
  return {
    runeName: generateRandomRuneName(4),
    runeId: '2412',
    runeDecimals: 2n,
    // runeSymbolChar: null,
    // runeNameSpacerIndices: null,
    priceUsd: generateRandomPriceUsd(),
    priceSats: generateRandomPriceSats().toString(),
    marketCapSats: generateRandomMarketCapUsd(),
    marketCapUsd: generateRandomMarketCapUsd(),
    oneDay: dummyMarketIntervalData(),
    oneWeek: dummyMarketIntervalData(),
    threeMonths: dummyMarketIntervalData(),
    oneYear: dummyMarketIntervalData(),
  }
}

export const dummyVolumeMarketStats: MarketStats = {
  statType: 'Volume',
  marketStats: [
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
  ],
}

export const dummyMarketCapMarketStats: MarketStats = {
  statType: 'MarketCap',
  marketStats: [
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
  ],
}

export const dummyTopMoversMarketStats: MarketStats = {
  statType: 'TopMovers',
  marketStats: [
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
  ],
}

export const dummyRecentlyAddedMarketStats: MarketStats = {
  statType: 'RecentlyAdded',
  marketStats: [
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
  ],
}

export const dummyActivityMarketStats: MarketStats = {
  statType: 'Activity',
  marketStats: [
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
    dummyMarketStats(),
  ],
}

const dummyMarketVolumeByPeriodRunes: MarketVolumeByPeriod = {
  tenMinutes: 0n,
  oneHour: 0n,
  sixHours: 0n,
  twentyFourHours: 0n,
  sevenDays: 0n,
  thirtyDays: 0n,
  total: 0n,
}

const dummyMarketVolumeByPeriodSats: MarketVolumeByPeriod = {
  tenMinutes: 0n,
  oneHour: 0n,
  sixHours: 0n,
  twentyFourHours: 0n,
  sevenDays: 0n,
  thirtyDays: 0n,
  total: 0n,
}

export const dummyMarketInfo: MarketInfo = {
  mintInfo: {
    unitsMinted: 0n,
    unitsRemaining: 0n,
    isMintedOut: false,
    mintLimit: 0n,
    totalSupply: 0n,
    deadlineTime: undefined,
    deadlineBlockHeight: undefined,
  },
  volume: {
    inRunes: dummyMarketVolumeByPeriodRunes,
    inSats: dummyMarketVolumeByPeriodSats,
  },
  numberOfOrders: 0n,
  orders: [],
}

export const dummyRuneBalance: RuneBalance = {
  runeName: 'PEPE',
  // runeSymbolChar: null,
  // runeNameSpacerIndices: null,
  runeId: '12523',
  runeDecimals: 8n,
  runesAmount: 8690840n,
  valueUsd: 4353999990n,
  valueSats: 45882n,
  address: '',
}

function generateRandomTransfers(): RuneTransfer {
  return {
    transactionId: Math.random().toString(16).slice(2),
    fromAddress: '0x' + Math.random().toString(16).slice(2),
    toAddress: '0x' + Math.random().toString(16).slice(2),
    runesAmount: BigInt(randomInt()),
    timestamp: BigInt(randomInt()),
    blockHeight: BigInt(randomInt()),
  }
}

export const dummyRuneTransfers: RuneTransfers = {
  runeId: '821342',
  runeName: 'ONGO',
  runeSymbolChar: '$',
  runeDecimals: 6n,
  // runeNameSpacerIndices: null,
  transfers: [generateRandomTransfers(), generateRandomTransfers(), generateRandomTransfers()],
}
