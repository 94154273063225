import { styled } from 'styled-components'
import { Alert } from '@mui/material'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'

import { COLORS } from 'src/shared/constants'

import { ToastContainer } from './styles'

export interface WarningToastProps {
  key: string
  variant: 'warning'
  warningMessage: string
  autoHideDuration?: number
  className?: string
}

export const WarningToast = forwardRef<HTMLDivElement, WarningToastProps>(
  ({ key, className, warningMessage }, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(key)
    }, [key, closeSnackbar])

    return (
      <ToastWrapper ref={ref} className={className} key={key}>
        <Alert onClose={handleDismiss} icon={false} severity='error'>
          {warningMessage}
        </Alert>
      </ToastWrapper>
    )
  },
)

WarningToast.displayName = 'WarningToast'

const ToastWrapper = styled(ToastContainer)`
  .MuiAlert-standard {
    background-color: ${COLORS.warning};
    font-weight: 500;
  }
`
