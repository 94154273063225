import { css } from 'styled-components'

import { DEFAULT_TRANSITION } from 'src/shared/styles'

export const BUTTON_HOVER_STYLES = css`
  ${DEFAULT_TRANSITION}
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`
