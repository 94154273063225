import { styled } from 'styled-components'
import { useMemo } from 'react'

import { OrderStatus, RuneDetails, FilledOrder } from '@packages/interfaces'
import { formatUsd, satsToBtc } from '@packages/utils'

import { TieredTableCell, VirtualizedTable } from 'src/shared/components'
import { formatRunesAmount } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { generateDummyFilledOrders } from 'src/testData'
import { TransactionExternalLink } from 'src/web3'
import { unixTimestampToDateString } from 'src/shared/utils'
import { AccountDetailsLink } from 'src/account'

import { formatPriceSymbol } from '../utils'
import { useOrdersByStatusApi } from '../hooks'

interface Props {
  runeDetails: RuneDetails
}

const FILLED_ORDER_STATUS: OrderStatus = 'Filled'

export function OrderHistoryTable({ runeDetails }: Props) {
  const isMobile = useIsMobile()

  const {
    // paginatedData: filledOrders,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useOrdersByStatusApi<FilledOrder>({
    status: FILLED_ORDER_STATUS,
    runeName: runeDetails.runeName,
    limit: isMobile ? 25 : 40,
  })

  const dummyFilledOrders = useMemo(() => {
    return generateDummyFilledOrders(runeDetails, 100)
  }, [runeDetails])

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'runesAmount',
            label: 'Quantity',
            formatter: ({ data: order }) =>
              formatRunesAmount({
                rune: runeDetails,
                runesAmount: order.runesAmount,
                displayShortFormat: isMobile,
              }),
          },
          {
            dataKey: 'priceSats',
            label: `Price (${formatPriceSymbol({ runeDetails })})`,
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={order.priceSats}
                subHeader={formatUsd(Number(order.priceUsd))}
              />
            ),
          },
          {
            dataKey: 'valueSats',
            label: 'Total (BTC)',
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={`${satsToBtc(order.valueSats)}`}
                subHeader={formatUsd(Number(order.valueUsd))}
              />
            ),
          },
          {
            dataKey: 'fillTransactionId',
            label: 'Date',
            formatter: ({ data: order }) => (
              <TransactionExternalLink transactionId={order.fillTransactionId}>
                {unixTimestampToDateString(BigInt(order.filledAtTimestamp), true)}
              </TransactionExternalLink>
            ),
          },
          {
            dataKey: 'filledByAddress',
            label: 'Filled By',
            formatter: ({ data: order }) => <AccountDetailsLink address={order.filledByAddress} />,
          },
        ]}
        paginatedData={[dummyFilledOrders]}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 30 : 45}
        viewableRows={25}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable)`
  div {
    td {
      padding: 0 10px;
    }
  }
` as typeof VirtualizedTable // generate typings for styled component weren't working
