import { styled } from 'styled-components'
import { useMemo, useState } from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

import { Order, OrderStatus, RuneDetails } from '@packages/interfaces'
import { formatUsd, satsToBtc } from '@packages/utils'

import {
  BUTTON_HOVER_STYLES,
  Button,
  TieredTableCell,
  VirtualizedTable,
} from 'src/shared/components'
import { formatRunesAmount } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { generateDummyOpenOrders } from 'src/testData'

import { useOrdersByStatusApi } from '../hooks'
import { BuyOrdersDrawer } from './BuyOrdersDrawer'
import { formatPriceSymbol } from '../utils'

interface Props {
  runeDetails: RuneDetails
}

const OPEN_ORDER_STATUS: OrderStatus = 'Placed'

export function OpenSellOrdersTable({ runeDetails }: Props) {
  const isMobile = useIsMobile()
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([])
  const [isBuyDrawerOpen, setIsBuyDrawerOpen] = useState(false)

  function openDrawer() {
    setIsBuyDrawerOpen(true)
  }

  function closeDrawer() {
    setIsBuyDrawerOpen(false)
  }

  const {
    // paginatedData: openOrders,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useOrdersByStatusApi({
    status: OPEN_ORDER_STATUS,
    runeName: runeDetails.runeName,
    limit: isMobile ? 25 : 40,
  })

  const dummyOpenOrders = useMemo(() => {
    return generateDummyOpenOrders(runeDetails, 100)
  }, [runeDetails])

  function removeOrder(order: Order) {
    setSelectedOrders((prev) => prev.filter((selected) => selected.id !== order.id))
  }

  function addOrder(order: Order) {
    setSelectedOrders((prev) => [...prev, order])
  }

  return (
    <Container>
      <Button onClick={openDrawer}>Buy</Button>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'buyButton',
            label: 'Buy',
            width: 15,
            formatter: ({ data: order }) => (
              <BuyButton>
                {selectedOrders.find((selected) => selected.id === order.id) ? (
                  <AddCircleRoundedIcon onClick={() => removeOrder(order)} />
                ) : (
                  <AddCircleOutlineRoundedIcon onClick={() => addOrder(order)} />
                )}
              </BuyButton>
            ),
          },
          {
            dataKey: 'runesAmount',
            label: 'Quantity',
            formatter: ({ data: order }) =>
              formatRunesAmount({
                rune: runeDetails,
                runesAmount: order.runesAmount,
                displayShortFormat: isMobile,
              }),
          },
          {
            dataKey: 'priceSats',
            label: `Price (${formatPriceSymbol({ runeDetails })})`,
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={order.priceSats}
                subHeader={formatUsd(Number(order.priceUsd))}
              />
            ),
          },
          {
            dataKey: 'valueSats',
            label: 'Total (BTC)',
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={`${satsToBtc(order.valueSats)}`}
                subHeader={formatUsd(Number(order.valueUsd))}
              />
            ),
          },
        ]}
        paginatedData={[dummyOpenOrders]}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 30 : 45}
        viewableRows={25}
      />
      <BuyOrdersDrawer
        isOpen={isBuyDrawerOpen}
        onClose={closeDrawer}
        orders={selectedOrders}
        runeDetails={runeDetails}
      />
    </Container>
  )
}

const Container = styled.div``

const VirtualizedTableWrapper = styled(VirtualizedTable)`
  div {
    td {
      padding: 0 10px;
    }
  }
` as typeof VirtualizedTable // generate typings for styled component weren't working

const BuyButton = styled.div`
  ${BUTTON_HOVER_STYLES}
  width: fit-content;
  height: fit-content;
  svg {
    height: 28px;
    width: 28px;
  }
`
