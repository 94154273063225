import { styled } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { WalletStandardProvider } from '@wallet-standard/react'
import { SnackbarProvider } from 'notistack'
import { AppBar } from '@mui/material'

import { WalletProvider } from './wallet'
import {
  PageRoutes,
  NavBar,
  NAVBAR_HEIGHT,
  Footer,
  CONTENT_PADDING,
  MOBILE_CONTENT_PADDING,
} from './pages'
import { SuccessfulTransactionToast } from './web3'
import { ErrorToast, LinkPageToast, RefreshPageToast, WarningToast } from './shared/components'
import { BREAKPOINTS, COLORS, FONTS } from './shared/constants'
import { BANNER_MESSAGE } from './settings'

declare module 'notistack' {
  interface VariantOverrides {
    successfulTransaction: true
    refreshPage: true
    error: true
    warning: true
    linkPage: true
  }
}

export function App() {
  return (
    <WalletStandardProvider>
      <WalletProvider>
        <BrowserRouter>
          <SnackbarProvider
            Components={{
              successfulTransaction: SuccessfulTransactionToast,
              refreshPage: RefreshPageToast,
              error: ErrorToast,
              warning: WarningToast,
              linkPage: LinkPageToast,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            maxSnack={6}
            classes={{
              containerRoot: 'snackbar-container-root',
            }}
            preventDuplicate
          >
            {BANNER_MESSAGE && <InfoBanner position='sticky'>{BANNER_MESSAGE}</InfoBanner>}
            <NavBar />
            <Container>
              <PageRoutes />
            </Container>
            <Footer />
          </SnackbarProvider>
        </BrowserRouter>
      </WalletProvider>
    </WalletStandardProvider>
  )
}

const InfoBanner = styled(AppBar)`
  height: 50px;
  text-align: center;
  padding: 10px;
  font-family: ${FONTS.text};
  font-weight: 500;
  font-size: 18px;
  background-color: ${COLORS.warning};
`

const Container = styled.div`
  padding-top: ${NAVBAR_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: ${CONTENT_PADDING};
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${MOBILE_CONTENT_PADDING};
  }
`
