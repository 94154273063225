import { hex } from '@scure/base'
import * as ordinals from 'micro-ordinals'
import { secp256k1 } from '@noble/curves/secp256k1'

import * as btc from '@packages/scure-btc-signer'
import * as wasm from '@packages/rune-wasm'
import { EtchingParams, SpacedRune, Terms, U128 } from '@packages/rune-wasm'
import * as payment from '@packages/scure-btc-signer/payment'
import * as psbt from '@packages/scure-btc-signer/psbt'
import { WIF } from '@packages/scure-btc-signer'
import { calculateFeeFromBps } from '@packages/utils'

import {
  SATOSHI_DUST_THRESHOLD,
  SERVICE_FEE_ADDRESS,
  SERVICE_FEE_BPS,
  SERVICE_FEE_MIN_SATS,
  TRX_VIRTUAL_SIZE_BYTES,
} from 'src/settings'

import { AddressDetails, BtcSignerNetwork, BtcSignerOutput, RuneUtxoOutpoint } from '../interfaces'
import { getCleanUtxos, utxoToInput } from './cleanUtxos'

export async function buildTransferTx({
  payAddress,
  runeUtxos,
  fromAddress,
  toAddress,
  runeId,
  amount,
  network,
  fee,
}: {
  runeUtxos: RuneUtxoOutpoint[]
  payAddress: AddressDetails
  fromAddress: AddressDetails
  toAddress: string
  runeId: string
  amount: bigint
  network: BtcSignerNetwork
  fee: bigint
}) {
  if (!payAddress || !fromAddress || !toAddress || !amount) {
    console.log('payAddress', payAddress)
    console.log('fromAddress', fromAddress)
    console.log('toAddress', toAddress)
    console.log('amount', amount)
    console.log('bad params')
    return
  }

  const runeSelection = selectRuneUtxos(runeUtxos, amount, network)
  const runeInputs: psbt.TransactionInputUpdate[] = runeSelection.map((runeUtxo) =>
    utxoToInput(fromAddress.p2, runeUtxo.utxo),
  )

  const toIndex = 0
  const changeIndex = 1

  const transferRunestone = wasm.encode_simple_transfer(runeId, amount, toIndex, changeIndex)

  const utxos = await getCleanUtxos(payAddress)
  const outputs: BtcSignerOutput[] = [
    { address: toAddress, amount: 546n },
    { address: fromAddress.addrString, amount: 546n },
    { script: transferRunestone, amount: 0n },
  ]
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: payAddress.addrString,
    network: network,
    feePerByte: fee,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient utxo')
  }

  // ToDo check if all runeInputs are included in `selected` else force add
  const runeInputsNotIncluded = runeInputs.filter(
    (runeInput) =>
      !selected.inputs.some(
        (input) => input.txid === runeInput.txid && input.index === runeInput.index,
      ),
  )
  selected.inputs.push(...runeInputsNotIncluded)

  const tx = selected.tx

  if (tx === undefined) {
    throw new Error('Unable to generate tx')
  }

  console.log('tx', tx)
  return tx
}

export async function buildMintTx({
  paymentAddress,
  recipientAddress,
  runeId,
  network,
  fee,
}: {
  paymentAddress: AddressDetails
  recipientAddress: string
  runeId: string
  network: BtcSignerNetwork
  fee: bigint
}): Promise<btc.Transaction> {
  if (!paymentAddress || !recipientAddress || !runeId) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('runeId', runeId)
    throw new Error('bad params')
  }
  const mintRunestone = wasm.encode_mint(runeId, 0)

  const utxos = await getCleanUtxos(paymentAddress)
  const outputs: BtcSignerOutput[] = [
    { address: recipientAddress, amount: SATOSHI_DUST_THRESHOLD },
    { script: mintRunestone, amount: 0n },
  ]
  if (SERVICE_FEE_MIN_SATS.mint !== 0n) {
    outputs.push({ address: SERVICE_FEE_ADDRESS, amount: SERVICE_FEE_MIN_SATS.mint })
  }
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: paymentAddress.addrString,
    network: network,
    feePerByte: fee,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  const tx = selected.tx

  if (tx === undefined) {
    throw new Error('Unable to generate transaction')
  }

  console.log('tx', tx)
  return tx
}

export interface BuildBulkMintResult {
  parentTx: btc.Transaction
  childTxs: btc.Transaction[]
  tempKey: Uint8Array
  tempKeyAddress: string
}

export interface BuildBulkMintSerializedResult {
  parentTxSignedBase64: string
  childTxsBase64: string[]
  tempKey: Uint8Array
  tempKeyAddress: string
}

export interface BuildBulkMintParams {
  paymentAddress: AddressDetails
  recipientAddress: string
  runeId: string
  network: BtcSignerNetwork
  fee: bigint
  estimatedTotalNetworkFee: bigint
  childEstimatedVsize?: bigint
  quantity: bigint
}

export async function buildBulkMintTxs({
  paymentAddress,
  recipientAddress,
  runeId,
  network,
  fee,
  estimatedTotalNetworkFee,
  childEstimatedVsize,
  quantity,
}: BuildBulkMintParams): Promise<BuildBulkMintResult> {
  if (!paymentAddress || !recipientAddress || !runeId) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('runeId', runeId)
    throw new Error('Bad params')
  }

  const MINT_RECOVERY_KEY = `${network === btc.NETWORK ? 'mainnet' : 'testnet'}-mint-recovery`
  const pkFromStorage = localStorage.getItem(MINT_RECOVERY_KEY)
  const childPrivKey =
    pkFromStorage !== null ? WIF(network).decode(pkFromStorage) : secp256k1.utils.randomPrivateKey()
  const wif = WIF(network).encode(childPrivKey)
  localStorage.setItem(MINT_RECOVERY_KEY, wif)

  const childPubKey = btc.utils.pubSchnorr(childPrivKey)
  const childP2 = btc.p2tr(childPubKey, undefined, network)
  const childMintCost =
    fee * (childEstimatedVsize ?? BigInt(TRX_VIRTUAL_SIZE_BYTES.mint)) + SATOSHI_DUST_THRESHOLD

  const mintRunestone = wasm.encode_mint(runeId, 0)

  const utxos = await getCleanUtxos(paymentAddress)
  const outputs: BtcSignerOutput[] = []
  for (let i = 0n; i < quantity; i++) {
    outputs.push({ script: childP2.script, amount: childMintCost })
  }

  const serviceFee =
    SERVICE_FEE_BPS.mint !== 0
      ? calculateFeeFromBps(estimatedTotalNetworkFee, SERVICE_FEE_BPS.mint)
      : 0n
  if (serviceFee !== 0n) {
    // TODO: consider moving the service fee output to the child mints instead of the parent
    outputs.push({
      address: SERVICE_FEE_ADDRESS,
      amount: serviceFee > SERVICE_FEE_MIN_SATS.mint ? serviceFee : SERVICE_FEE_MIN_SATS.mint,
    })
  }
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: paymentAddress.addrString,
    network: network,
    feePerByte: fee,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  const parentTx = selected.tx

  if (parentTx === undefined) {
    throw new Error('Unable to generate transaction')
  }

  const childTxs: btc.Transaction[] = []
  for (let i = 0; i < Number(quantity); i++) {
    const childTx = new btc.Transaction({ allowUnknownOutputs: true })
    childTx.addInput({
      ...childP2,
      txid: '0000000000000000000000000000000000000000000000000000000000000000',
      index: i,
      sequence: 0xfffffff0, // allow RBF
      witnessUtxo: {
        script: childP2.script,
        amount: BigInt(childMintCost),
      },
    })
    childTx.addOutputAddress(recipientAddress, SATOSHI_DUST_THRESHOLD, network)
    childTx.addOutput({ script: mintRunestone, amount: 0n })
    childTxs.push(childTx)
  }
  if (!childP2.address) {
    throw new Error('Unable to generate child address')
  }

  return {
    parentTx: parentTx,
    childTxs: childTxs,
    tempKey: childPrivKey,
    tempKeyAddress: childP2.address,
  }
}

export interface SignBulkMintResult {
  parentTx: btc.Transaction
  childTxs: btc.Transaction[]
  tempKey: Uint8Array
}

export interface SignBulkMintSerializedResult {
  parentTxBase64: string
  childTxsBase64: string[]
  tempKey: Uint8Array
}

export async function signBulkMintTxs(bulk: BuildBulkMintResult): Promise<SignBulkMintResult> {
  bulk.parentTx.finalize()
  const res: SignBulkMintResult = { parentTx: bulk.parentTx, childTxs: [], tempKey: bulk.tempKey }
  for (let i = 0; i < bulk.childTxs.length; i++) {
    const tx = bulk.childTxs[i]
    const input = tx.getInput(0)
    tx.updateInput(0, { ...input, txid: bulk.parentTx.id })
    tx.signIdx(bulk.tempKey, 0, [btc.SigHash.DEFAULT], new Uint8Array(32))
    tx.finalize()
    res.childTxs.push(tx)
  }
  return res
}

export async function buildSellPsbt({
  runeAddress,
  proceedsAddress,
  proceedsAmount,
  runeId,
  runeUtxo, // boxed output, selling entire utxo
}: {
  runeAddress: AddressDetails
  proceedsAddress: AddressDetails
  proceedsAmount: bigint
  runeUtxo: RuneUtxoOutpoint
  runeId: string
}) {
  if (!runeAddress || !proceedsAddress || !runeId) {
    console.log('runeAddress', runeAddress)
    console.log('proceedsAddress', proceedsAddress)
    console.log('runeId', runeId)
    console.log('bad params')
    return
  }

  const tx = new btc.Transaction()

  const runeInput = utxoToInput(runeAddress.p2, runeUtxo.utxo)
  runeInput.sighashType = btc.SigHash.SINGLE_ANYONECANPAY

  const proceedsOutput: psbt.TransactionOutputUpdate = {
    script: proceedsAddress.p2.script,
    amount: proceedsAmount,
  }
  tx.addInput(runeInput)
  tx.addOutput(proceedsOutput)

  return tx
}

function selectRuneUtxos(runeUtxos: RuneUtxoOutpoint[], amount: bigint, network: BtcSignerNetwork) {
  const dummyPk1 = hex.decode('0101010101010101010101010101010101010101010101010101010101010101')
  const dummyPk2 = hex.decode('0202020202020202020202020202020202020202020202020202020202020202')
  const dummyAddress1 = btc.p2tr(dummyPk1, undefined, network)
  const dummyAddress2 = btc.p2tr(dummyPk2, undefined, network)

  const dummyOutput = [{ script: new Uint8Array(), amount }]

  const dummyUtxos: psbt.TransactionInputUpdate[] = runeUtxos.map((runeUtxo) =>
    utxoToInput(dummyAddress1, runeUtxo.utxo),
  )

  const selected = btc.selectUTXO(dummyUtxos, dummyOutput, 'default', {
    bip69: false,
    changeAddress: dummyAddress2.address!,
    network: network,
    alwaysChange: true,
    feePerByte: 0n,
  })

  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  console.log('selection target', selected.outputs[0].amount)
  if (selected.change) {
    if (selected.outputs.length != 2) {
      throw new Error('Unexpected output length')
    }
    console.log('selection change', selected.outputs[1].amount)
  }

  console.log('utilized inputs', selected.inputs)

  const ret: RuneUtxoOutpoint[] = []

  for (const input of selected.inputs) {
    const runeUtxo = runeUtxos.find(
      (utxo) => utxo.utxo.txid === input.txid && utxo.utxo.vout === input.index,
    )
    if (runeUtxo) {
      ret.push(runeUtxo)
    }
  }

  return ret
}

export interface EtchingCommit {
  revealAmount: bigint
  txid: string | undefined
  commitTx: btc.Transaction
  revealScript: payment.P2TROut
  revealKey: Uint8Array
}

export interface EtchingCommitOpts {
  paymentAddress: AddressDetails
  recipientAddress: AddressDetails
  network: BtcSignerNetwork
  fee: bigint
  params: JsEtchingParams
}

export async function buildEtchingCommit(opts: EtchingCommitOpts): Promise<EtchingCommit> {
  if (!opts.paymentAddress || !opts.recipientAddress) {
    throw new Error('Invalid transaction parameters')
  }
  const { paymentAddress, recipientAddress, network, fee: feeRate } = opts

  if (!paymentAddress || !recipientAddress || !opts.params.rune) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('opts.params.rune', opts.params.rune)
    console.log('bad params')
    throw new Error('Wallet not connected')
  }

  const revealPrivKey = secp256k1.utils.randomPrivateKey()
  // private key: ${red}${WIF(net).encode(res[name])}${reset}
  const revealPubKey = btc.utils.pubSchnorr(revealPrivKey)
  const customScripts = [ordinals.OutOrdinalReveal]

  const rune = wasm.SpacedRune.from_string(opts.params.rune)
  const runeVal = rune.to_bigint()
  rune.free()

  const inscription: ordinals.Inscription = {
    tags: {
      rune: runeVal,
    },
    body: new Uint8Array(),
  }

  const revealPayment = btc.p2tr(
    undefined,
    ordinals.p2tr_ord_reveal(revealPubKey, [inscription]),
    network,
    false,
    customScripts,
  )

  if (revealPayment.address === undefined) {
    throw new Error('Unable to create reveal address')
  }

  // Value for reveal fee; could more intelligently calculate a precise fee by estimating reveal cost
  const commitAmount = 300n * feeRate

  const utxo = await getCleanUtxos(paymentAddress)
  const commitOutputs: BtcSignerOutput[] = [
    { address: revealPayment.address, amount: commitAmount },
  ]
  const _commitSelector = _createCommitTx(
    utxo,
    commitOutputs,
    paymentAddress.addrString,
    network,
    feeRate,
  )
  if (!_commitSelector.tx) {
    throw new Error('Unable to create commit transaction')
  }
  const _commitTx = _commitSelector.tx

  return <EtchingCommit>{
    commitTx: _commitTx,
    revealScript: revealPayment,
    revealAmount: commitAmount,
    revealKey: revealPrivKey,
    txid: undefined,
  }
}

export interface EtchingReveal {
  revealTx: btc.Transaction
}

export interface EtchingRevealOpts {
  paymentAddress: AddressDetails
  recipientAddress: AddressDetails
  network: BtcSignerNetwork
  fee: bigint
  params: JsEtchingParams
  etchingCommit: EtchingCommit
}

export async function buildEtchingReveal(opts: EtchingRevealOpts): Promise<EtchingReveal> {
  if (!opts.paymentAddress || !opts.recipientAddress) {
    throw new Error('Invalid transaction parameters')
  }
  const { paymentAddress, recipientAddress, network, fee: feeRate } = opts

  if (!paymentAddress || !recipientAddress || !opts.params.rune) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('opts.params.rune', opts.params.rune)
    console.log('bad params')
    throw new Error('Wallet not connected')
  }

  const etchingParams = _etchingParamsToRust(opts.params)
  const etchingRunestone = wasm.encode_etching(etchingParams)
  // etchingParams.free()

  const outputs: BtcSignerOutput[] = [
    { address: recipientAddress.addrString, amount: 546n },
    { script: etchingRunestone, amount: 0n },
  ]

  const inputs: psbt.TransactionInputUpdate[] = [
    {
      ...opts.etchingCommit.revealScript,
      txid: opts.etchingCommit.txid,
      index: 0,
      witnessUtxo: {
        script: opts.etchingCommit.revealScript.script,
        amount: opts.etchingCommit.revealAmount,
      },
    },
  ]

  const _revealSelector = _createRevealTx(
    inputs,
    outputs,
    paymentAddress.addrString,
    network,
    feeRate,
  )
  // const revealFee = _revealTx.fee
  if (!_revealSelector || !_revealSelector.tx) {
    throw new Error('Unable to create reveal')
  }

  const _revealTx = _revealSelector.tx
  _revealTx.signIdx(opts.etchingCommit.revealKey, 0)
  _revealTx.finalizeIdx(0)

  return <EtchingReveal>{
    revealTx: _revealTx,
  }
}

function _createCommitTx(
  utxos: psbt.TransactionInputUpdate[],
  outputs: BtcSignerOutput[],
  changeAddress: string,
  network: BtcSignerNetwork,
  feeRate: bigint,
) {
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: changeAddress,
    network: network,
    feePerByte: feeRate,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  return selected
}

function _createRevealTx(
  utxos: psbt.TransactionInputUpdate[],
  outputs: BtcSignerOutput[],
  changeAddress: string,
  network: BtcSignerNetwork,
  feeRate: bigint,
) {
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: changeAddress,
    network: network,
    feePerByte: feeRate,
    allowUnknownOutputs: true, // op_return
    customScripts: [ordinals.OutOrdinalReveal],
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  return selected
}

export interface JsEtchingParams {
  divisibility: number // u8
  premine: bigint // u128
  premine_vout: number | undefined // u32
  rune: string | undefined
  symbol: string | undefined
  turbo: boolean

  has_terms: boolean
  terms_cap: bigint | undefined // u128
  terms_amount: bigint | undefined // u128
  terms_absolute_open_height: bigint | undefined // u64
  terms_absolute_close_height: bigint | undefined // u64
  terms_relative_open_height: bigint | undefined // u64
  terms_relative_close_height: bigint | undefined // u64
}

function _etchingParamsToRust(params: JsEtchingParams): EtchingParams {
  const etchingParams = new EtchingParams()
  etchingParams.divisibility = params.divisibility
  etchingParams.premine = U128.from_bigint(params.premine)
  etchingParams.premine_vout = params.premine_vout
  etchingParams.rune = params.rune ? SpacedRune.from_string(params.rune) : undefined
  etchingParams.symbol = params.symbol
  etchingParams.turbo = params.turbo

  if (params.has_terms) {
    const terms = new Terms()
    terms.cap = params.terms_cap ? U128.from_bigint(params.terms_cap) : undefined
    terms.amount = params.terms_amount ? U128.from_bigint(params.terms_amount) : undefined
    terms.absolute_open_height = params.terms_absolute_open_height
    terms.absolute_close_height = params.terms_absolute_close_height
    terms.relative_open_height = params.terms_relative_open_height
    terms.relative_close_height = params.terms_relative_close_height
    etchingParams.terms = terms
  }

  return etchingParams
}
