import { styled } from 'styled-components'

import { OrderStatus, RuneDetails } from '@packages/interfaces'

import { OrderCard } from './OrderCard'
import { useOrdersByStatusApi } from '../hooks'

interface Props {
  runeDetails: RuneDetails
}

export function OpenOrderCards({ runeDetails }: Props) {
  const status: OrderStatus = 'Placed'
  const { paginatedData } = useOrdersByStatusApi({ status, runeName: runeDetails.runeName })

  const openOrders = paginatedData.length > 0 ? paginatedData[0] : []

  return (
    <Container>
      <OrdersContainer>
        {openOrders.map((order) => {
          return <OrderCard order={order} runeDetails={runeDetails} />
        })}
      </OrdersContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const OrdersContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`
