import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import _Select, { SelectChangeEvent } from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { styled } from 'styled-components'

import { COLORS } from '../../constants'

interface Props {
  label: string
  options: { value: string; label: string }[]
  value: string
  onChange: (value: string) => void
}

export function Select({ onChange, label, options, value }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <SelectWrapper
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={value}
        label={label}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options.map((option) => {
          return <MenuItem value={option.value}>{option.label}</MenuItem>
        })}
      </SelectWrapper>
    </FormControl>
  )
}

const SelectWrapper = styled(_Select<string>)`
  background-color: ${COLORS.buttons.primary};
  border-radius: 8px;
  .MuiSelect-select {
    padding: 10px 14px;
  }
`
