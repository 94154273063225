export const API_ENDPOINTS = {
  healthCheck: '/healthz',
  GET: {
    runes: {
      mints: {
        popular: '/v1/mints/popular',
        batchByAddress: '/v1/account/:address/batch-mints',
      },
      etchings: {
        latest: '/v1/etchings/latest',
      },
      markets: {
        search: '/v1/markets/search',
      },
      orders: {
        recentlyPlaced: '/v1/rune/:runeName/orders/recently-placed',
        byStatus: '/v1/rune/:runeName/orders/status',
        byAccount: '/v1/account/:address/orders',
      },
      details: '/v1/rune/:runeName/details/',
      balances: {
        byRune: '/v1/rune/:runeId/balances',
        byAccount: '/v1/account/:address/balances',
        runeBalanceForAccount: '/v1/account/:address/balance/:runeId',
      },
      outpoints: {
        runesOutpointsForAccount: '/v1/outpoints/runes/:runeId/:address',
        cleanUtxosForAccount: '/v1/outpoints/clean/:address',
        dirtyOutpointsForAccount: '/v1/outpoints/dirty/:address',
      },
      name: {
        isValidRuneName: '/v1/rune/name/is-valid',
      },
    },
    accounts: {
      history: '/v1/account/:address/history',
    },
    chain: {
      recommendedFees: '/v1/chain/recommended-fees',
      blockTip: '/v1/chain/block-tip',
      utxos: '/v1/chain/utxos/:address',
    },
    bitcoind: {
      chainInfo: '/v1/bitcoind/chain-info',
    },
    btc: {
      price: '/v1/btc/price',
    },
  },
  POST: {
    runes: {
      orders: {
        sell: '/v1/rune/:runeName/orders/sell',
        cancel: '/v1/rune/:runeName/orders/:orderId/cancel',
        requestBuy: '/v1/rune/:runeName/orders/:orderId/request-buy',
        submitBuy: '/v1/rune/:runeName/orders/:orderId/submit-buy',
      },
      mints: {
        submitMints: '/v1/rune/:runeName/mints/submit-mints',
      },
    },
    chain: {
      sendRawTransactions: '/v1/chain/send-raw-transactions',
    },
  },
}
