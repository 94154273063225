import { styled } from 'styled-components'
import { useEffect, useState } from 'react'

import {
  ButtonGroupOptions,
  LargeButtonGroup,
  SliderWithInput,
  WarningMessage,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'

function formatFee(fee: number) {
  return `${fee} sats/vB`
}

const CUSTOM_VALUE = 'CUSTOM'

const DEFAULTS = {
  fast: 50,
  slow: 20,
}

const MULTIPLIERS = {
  fast: 1.025,
  faster: 1.05,
  fastest: 1.1,
}

function calculateMultipliedFee({ multiplier, fee }: { multiplier: number; fee?: number }) {
  return fee ? Math.ceil(fee * multiplier) : fee ?? DEFAULTS.fast
}

interface Props {
  selectedFee: number
  onChange: (fee: number) => void
  onlyAllowFastestFee?: boolean
  addFastestFeeBuffer?: boolean
  className?: string
}

export function NetworkFeeSelector({
  selectedFee,
  onChange,
  onlyAllowFastestFee = false,
  addFastestFeeBuffer = false,
  className,
}: Props) {
  const [feeStr, setFeeStr] = useState('')
  const [isCustomFee, setIsCustomFee] = useState(false)
  const { recommendedNetworkFees } = useWalletContext()

  const defaultFastestFee = calculateMultipliedFee({
    multiplier: addFastestFeeBuffer ? MULTIPLIERS.fast : 1,
    fee: recommendedNetworkFees?.fastestFee,
  })

  useEffect(() => {
    if (
      ((feeStr === '' || selectedFee === 0) && recommendedNetworkFees?.fastestFee) ||
      (onlyAllowFastestFee && defaultFastestFee > selectedFee)
    ) {
      onChange(defaultFastestFee)
    }
  }, [recommendedNetworkFees, addFastestFeeBuffer])

  useEffect(() => {
    setFeeStr(`${selectedFee}`)
  }, [selectedFee])

  let options: ButtonGroupOptions[] = []

  if (recommendedNetworkFees) {
    if (onlyAllowFastestFee) {
      options.push({
        value: `${defaultFastestFee}`,
        label: formatFee(defaultFastestFee),
        title: 'Fast',
      })
      const fasterFee = calculateMultipliedFee({
        multiplier: addFastestFeeBuffer ? MULTIPLIERS.faster : MULTIPLIERS.fast,
        fee: recommendedNetworkFees.fastestFee,
      })
      options.push({
        value: `${fasterFee}`,
        label: formatFee(fasterFee),
        title: 'Faster',
      })
      const fastestFee = calculateMultipliedFee({
        multiplier: addFastestFeeBuffer ? MULTIPLIERS.fastest : MULTIPLIERS.faster,
        fee: recommendedNetworkFees.fastestFee,
      })
      options.push({
        value: `${fastestFee}`,
        label: formatFee(fastestFee),
        title: 'Fastest',
      })

      if (
        selectedFee !== defaultFastestFee &&
        selectedFee !== fasterFee &&
        selectedFee !== fastestFee
      ) {
        options.push({
          value: `${selectedFee}`,
          label: formatFee(selectedFee),
          title: 'Custom',
        })
      }
    } else {
      options.push({
        value: `${recommendedNetworkFees.fastestFee}`,
        label: formatFee(recommendedNetworkFees.fastestFee),
        title: 'Fast',
        subtitle: 'About 10 min',
      })
      options.push({
        value: `${recommendedNetworkFees.halfHourFee}`,
        label: formatFee(recommendedNetworkFees.halfHourFee),
        title: 'Normal',
        subtitle: 'About 30 min',
      })
      options.push({
        value: `${recommendedNetworkFees.hourFee}`,
        label: formatFee(recommendedNetworkFees.hourFee),
        title: 'Slow',
        subtitle: 'About 1 hr',
      })

      if (
        selectedFee !== recommendedNetworkFees.fastestFee &&
        selectedFee !== recommendedNetworkFees.halfHourFee &&
        selectedFee !== recommendedNetworkFees.hourFee
      ) {
        options.push({
          value: `${selectedFee}`,
          label: formatFee(selectedFee),
          title: 'Custom',
        })
      }
    }

    // options.push({
    //   value: `${recommendedNetworkFees.minimumFee}`,
    //   label: formatFee(recommendedNetworkFees.minimumFee),
    //   title: 'Minimum',
    //   subtitle: 'Several hours',
    // })
    options.push({
      value: CUSTOM_VALUE,
      label: '',
      title: 'Custom',
    })
  }

  // remove any fees that are the same
  options = options.filter(
    (function () {
      const seenSats = new Set<number>()
      return function (fee): boolean {
        const feeValue = parseInt(fee.value)
        if (seenSats.has(feeValue)) {
          return false
        } else {
          seenSats.add(feeValue)
          return true
        }
      }
    })()
  )

  function handleOnChange(value: string) {
    // cannot unselect to null - one needs to be chosen
    if (value == null) {
      return
    }
    if (value !== CUSTOM_VALUE) {
      onChange(parseInt(value))
      setIsCustomFee(false)
    } else {
      setIsCustomFee(true)
    }
  }

  function handleSliderOnChange(value: number) {
    onChange(value)
  }

  return (
    <Container className={className}>
      <Title>Network Fee Rate</Title>

      <NetworkFeeButtons
        options={options}
        exclusive
        value={isCustomFee ? CUSTOM_VALUE : feeStr}
        onChange={handleOnChange}
      />
      {isCustomFee && (
        <SliderWithInputContainer
          spacing={1}
          value={selectedFee}
          onChange={handleSliderOnChange}
          min={
            onlyAllowFastestFee
              ? recommendedNetworkFees?.fastestFee ?? DEFAULTS.fast
              : recommendedNetworkFees?.minimumFee ?? DEFAULTS.slow
          }
          max={
            (onlyAllowFastestFee
              ? recommendedNetworkFees?.fastestFee ?? DEFAULTS.fast
              : defaultFastestFee) * 2
          }
        />
      )}
      {onlyAllowFastestFee &&
        recommendedNetworkFees &&
        selectedFee < recommendedNetworkFees.fastestFee && (
          <WarningMessage message='This transaction may get stuck as pending. We recommend using a higher fee' />
        )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const NetworkFeeButtons = styled(LargeButtonGroup)`
  button {
    height: 75px;
    font-size: 13px;
    border-radius: 10px !important;
    border: 1px solid ${hexToRgb(COLORS.white, 0.5)} !important;
    color: ${COLORS.white};
    div {
      color: ${COLORS.white};
    }
    h3 {
      color: ${COLORS.white};
    }
  }

  .Mui-selected {
    color: ${COLORS.black} !important;
    div {
      color: ${COLORS.black} !important;
    }
    h3 {
      color: ${COLORS.black} !important;
    }
  }
`

const Title = styled.h3`
  display: flex;
  gap: 10px;
  font-size: 17px;
  font-weight: 500;
`

const SliderWithInputContainer = styled(SliderWithInput)`
  padding-top: 5px;
`
